import firebase from "firebase/app";
import 'firebase/firestore';
// Add the Firebase products that you want to use
import "firebase/auth";
import environmentConfig from './config/config.json';

const {
  API_KEY: apiKey,
  AUTH_DOMAIN: authDomain,
  PROJECT_ID: projectId
} = environmentConfig;

firebase.initializeApp({ apiKey, authDomain, projectId });
const provider = new firebase.auth.GoogleAuthProvider();
provider.addScope('email');
provider.setCustomParameters({
  hd: 'playgroundxyz.com'
});
const auth = firebase.auth();

export {
  firebase,
  provider,
  auth
};
