import { DesktopUrlErrorState } from "../../core/interfaces";

export const errorReducer = (
  state: DesktopUrlErrorState,
  action: any
): DesktopUrlErrorState => {
  switch (action.type) {
    case "REMOVE_ERROR":
      return {
        ...state,
        errors: {
          ...state.errors,
          ...{
            [action.error.id]: {
              error: false,
              helperText: action.error.helperText,
            },
          },
        },
      };
    case "ADD_ERROR":
      return {
        ...state,
        errors: {
          ...state.errors,
          ...{
            [action.error.id]: {
              error: true,
              helperText: action.error.helperText,
            },
          },
        },
      };
    default:
      return state;
  }
};
