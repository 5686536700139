import {
  DynamicFeed,
  Settings,
  Tune,
  MultilineChart,
  Group,
  Assignment,
  NoSim,
  BubbleChart,
} from '@material-ui/icons';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import {MenuItem, Feature} from './interfaces';
import {AdCampaigns} from '../controllers/adCampaigns/adCampaigns';
import {DesktopUrlGenerator} from '../controllers/desktop/desktopUrlGenerator.react';
import {ReportingContainer} from '../controllers/reporting/reportingContainer.react';
import {StudyGroups} from '../controllers/studyGroups/studyGroups.react';
import {Studies} from '../controllers/studies/studies.react';
import {Heatmapping} from '../controllers/heatmapping/heatmapping.react';
import {Feeds} from '../controllers/feeds/feeds.react';
import {FeedConfigs} from '../controllers/feedConfigs/feedConfigs.react';
import {StudiesVideo} from '../controllers/studiesVideo/studiesVideo.react';

export const routes: MenuItem[] = [
  {
    name: 'Study Groups',
    icon: Group,
    root: '/study-groups',
    path: '/study-groups/:id?',
    component: StudyGroups,
  },
  {
    name: 'Studies',
    icon: Assignment,
    root: '/studies',
    path: '/studies/:id?',
    component: Studies,
  },
  {
    name: 'Feed Configs',
    icon: Tune,
    root: '/feed-configs',
    path: '/feed-configs/:id?',
    component: FeedConfigs,
  },
  {
    name: 'Custom Feeds',
    icon: Settings,
    root: '/feeds',
    path: '/feeds/:id?',
    component: Feeds,
  },
  {
    name: 'Reporting',
    icon: MultilineChart,
    root: '/reporting',
    path: '/reporting/:id?',
    component: ReportingContainer,
  },
  {
    name: 'Heatmapping',
    icon: BubbleChart,
    root: '/heat-mapping',
    path: '/heat-mapping',
    component: Heatmapping,
  },
  {
    name: 'Ad Campaigns',
    icon: DynamicFeed,
    root: '/adCampaigns',
    path: '/adCampaigns/:id?',
    component: AdCampaigns,
  },
  {
    name: 'Desktop',
    icon: DesktopWindowsIcon,
    root: '/desktop',
    path: '/desktop',
    component: DesktopUrlGenerator,
  },
  {
    name: 'Studies - Video',
    icon: Assignment,
    root: '/studiesVideo',
    path: '/studiesVideo',
    component: StudiesVideo,
  },
];
