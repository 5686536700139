import { getIdToken } from "../../utils/idToken";
import VpApi from "./vpApi";

export const vpApiInstance = async (): Promise<VpApi | null> => {
  const idToken = await getIdToken();
  if (!idToken) {
    return null;
  }
  return new VpApi(idToken);
};
