import { firebase, provider, auth } from "../../firebaseConfig";

class Auth {
  async login(): Promise<void> {
    return auth.signInWithRedirect(provider);
  }

  async getLoginResult(): Promise<firebase.auth.UserCredential> {
    return auth.getRedirectResult();
  }

  async logout(): Promise<void> {
    return auth.signOut();
  }

  isAuthenticated(): Promise<firebase.User | null> {
    return new Promise((resolve) => {
      firebase.auth().onAuthStateChanged((user: firebase.User | null): void => {
        if (user) {
          resolve(user);
        }
        resolve(null);
      });
    });
  }
}

export default Auth;
