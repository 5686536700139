import * as React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import "regenerator-runtime/runtime";
// had to import this as parcel was tree shaking required functionalityo ut on build
import "react-sliding-pane/dist/react-sliding-pane.css";
import "react-table/react-table.css";
import {
  Route,
  Redirect,
  Switch,
  BrowserRouter as Router,
} from "react-router-dom";
import { Loader } from "./components/loader/loader.react";
import { Sidebar } from "./components/sidebar/sidebar.react";
import { Stage } from "./components/stage/stage.react";
import { routes } from "./core/routes";
import styles from "./styles.scss";
import { FirebaseUser, MenuItem } from "./core/interfaces";
import Auth from "./utils/auth";

interface iState {
  loading: boolean;
  user: FirebaseUser | null;
}

Modal.setAppElement("*");
const menuItems: MenuItem[] = routes;
const auth = new Auth();

class VisionProject extends React.Component<{}, iState> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      user: null,
    };

    this.logout = this.logout.bind(this);
  }

  componentDidMount(): void {
    this.login();
  }

  componentDidUpdate(prevProps: {}, prevState: iState): void {
    if (prevState.user && !this.state.user) {
      this.login();
    }
  }

  login(): void {
    this.setState({
      loading: true,
    });
    auth.isAuthenticated().then((user) => {
      if (user) {
        this.setState({
          loading: false,
          user,
        });
        return;
      }
      auth.login();
    });
  }

  logout(): void {
    auth.isAuthenticated().then(() => {
      auth.logout().then(() => {
        this.setState({
          user: null,
        });
      });
    });
  }

  /**
   * @function
   * @name render
   * @description - standard react render method
   * @returns {React.ReactNode} - returns jsx markup
   */
  render(): React.ReactNode {
    if (this.state.loading) {
      return <Loader />;
    }

    if (!this.state.user) {
      return null;
    }

    return (
      <React.Fragment>
        <Router>
          <Sidebar menuItems={menuItems} logout={this.logout} />
          <Switch>
            {(menuItems || []).map((menuItem) => (
              <Route
                exact={true}
                path={menuItem.path}
                key={menuItem.name}
                render={(routeProps): React.ReactNode => (
                  <Stage menuItem={menuItem} routeProps={routeProps}></Stage>
                )}
              />
            ))}
            <Redirect from="/" exact to="/studies" />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}
const app = document.getElementById("app");
(app as HTMLElement).setAttribute("id", styles.app);
ReactDOM.render(<VisionProject />, app);
