import { StudyGroup, StudyOrStudyGroupOrFeedConfig } from "../core/interfaces";

export const isStudyGroup = (
  toBeDetermined: StudyOrStudyGroupOrFeedConfig
): toBeDetermined is StudyGroup => {
  const studyGroupObj = toBeDetermined as StudyGroup;
  const hasCampaignId = Object.prototype.hasOwnProperty.call(
    studyGroupObj,
    "pxyzCampaignId"
  );
  const hasBrand = Object.prototype.hasOwnProperty.call(studyGroupObj, "brand");
  return hasCampaignId && hasBrand;
};
