import React, { Fragment } from "react";
import ReactTable, { Column } from "react-table";
import { Grid } from "@material-ui/core";

interface ReportingTableProps {
  title: string;
  data: any;
  columns: Column[];
}

export const ReportingTable: React.FunctionComponent<ReportingTableProps> = ({
  title,
  data,
  columns,
}) => {
  return (
    <Fragment>
      <h3>{title}</h3>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ReactTable
            filterable={false}
            sortable={false}
            showPagination={false}
            showPageSizeOptions={false}
            minRows={0}
            loading={false}
            data={data}
            columns={columns}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};
