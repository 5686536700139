import {desktopWebsiteUrl} from '../../config';
import {
  AdFormat,
  AdFormatSize,
  DekstopUrlConstant,
  DurationConstant,
} from './interfaces';

export const AdFormatSizeMap: {
  [key in AdFormat]: AdFormatSize;
} = {
  [AdFormat.PXYZ_SUPER_SKIN_V2]: {width: 320, height: 50},
  [AdFormat.PXYZ_HANG_TIME]: {width: 300, height: 250},
  [AdFormat.PXYZ_HOVER_BOARD]: {width: 300, height: 250},
  [AdFormat.PXYZ_HANG_TIME_DESKTOP_V2]: {width: 728, height: 90},
  [AdFormat.PXYZ_VIDEO_SWITCH]: {width: 300, height: 250},
  [AdFormat.PXYZ_HANG_TIME_JNR]: {width: 300, height: 250},
  [AdFormat.GG_IN_SCREEN]: {width: 1, height: 1},
  [AdFormat.GG_IN_SCREEN_FRAME]: {width: 1, height: 1},
  [AdFormat.GG_IN_SCREEN_EXPANDABLE]: {width: 1, height: 1},
  [AdFormat.GG_IN_SCREEN_EXPANDABLE_VIDEO]: {width: 1, height: 1},
  [AdFormat.GG_IN_SCREEN_EXPANDABLE_CORNER]: {width: 1, height: 1},
  [AdFormat.GG_IN_IMAGE]: {width: 1, height: 1},
  [AdFormat.GG_IN_IMAGE_EXPANDABLE]: {width: 1, height: 1},
  [AdFormat.GG_IN_IMAGE_CANVAS]: {width: 1, height: 1},
  [AdFormat.GG_OUTSTREAM_IN_ARTICLE_VIDEO]: {width: 300, height: 250},
  [AdFormat.JP_MOBILE_SCROLLER]: {width: 300, height: 250},
  [AdFormat.JP_MOBILE_SKIN]: {width: 1, height: 1},
  [AdFormat.JP_DESKTOP_SKIN]: {width: 1, height: 1},
  [AdFormat.STANDARD]: {width: null, height: null},
};

export const PXYZ_PREFIX = 'pxyz-';

export const ADFORMAT_KEY = 'adFormat';

export const TAG_KEY = 'tag';
export const TAG_HELPERTEXT = 'Please paste a creative tag above';

export const SLOTID_KEY = 'Slot ID';
export const SLOTID_HELPERTEXT =
  'please paste the slot Id associated with the format';

// these values are also hardcoded in the vision-project webiste.
export const DURATION_CODES: DurationConstant[] = [
  {name: '1 minute', description: '156-43', value: 1},
  {name: '5 minutes', description: '782-15', value: 5},
  {name: '10 minutes', description: '156-430', value: 10},
  {name: '15 minutes', description: '234-645', value: 15},
  {name: '20 minutes', description: '312-860', value: 20},
  {name: '25 minutes', description: '391-075', value: 25},
  {name: '30 minutes', description: '469-290', value: 30},
];

export const PLATFORM_IDS: DekstopUrlConstant[] = [
  {
    name: 'MTurk',
    value: 'mturk',
  },
  {
    name: 'Clickworker',
    value: 'clickworker',
  },
  {
    name: 'Playground XYZ',
    value: 'pxyz',
  },
  {
    name: 'Cint',
    value: 'cint',
  },
];

export const F_TYPES: DekstopUrlConstant[] = [
  {value: 'g', name: 'Gaming Feed'},
  {
    value: '',
    name: 'Default',
  },
];

export enum STUDY_TYPES {
  DATA_COLLECTION = 'Data Collection',
  BRAND_STUDY = 'Brand Study',
}

export const ENVIRONMENTS: DekstopUrlConstant[] = [
  {
    value: 'http://localhost:1234/',
    name: 'local',
  },
  {
    value: desktopWebsiteUrl,
    name: 'staging',
  },
  {
    value: desktopWebsiteUrl,
    name: 'production',
  },
];

export const FEED_ITEM_CATEGORIES = [
  'Entertainment',
  'Food',
  'Lifestyle',
  'News',
  'Sports',
].sort();
