import * as React from "react";
import {
  FormControl,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { Sync, SyncProblem } from "@material-ui/icons";
import {
  FieldElement,
  DataManagerMethods,
  StudyGroup,
} from "../../core/interfaces";

const { Fragment } = React;

export function CampaignInput(props: {
  field: FieldElement;
  clone: StudyGroup;
  methods: DataManagerMethods;
}): React.ReactElement {
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const { field, clone, methods } = props;
  const { isFieldValid, handleChange, getCampaign } = methods;

  /**
   * helper function to handle click on the inputAdornment
   *
   * @returns {(((event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void) | undefined)}
   */
  function onClickHandler():
    | ((event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void)
    | undefined {
    return async (): Promise<void> => {
      setLoading(true);
      const successResult: boolean = await getCampaign(clone.pxyzCampaignId);
      setSuccess(successResult);
      setLoading(false);
    };
  }
  return (
    <Fragment key={field.key}>
      <FormControl key={field.key}>
        <TextField
          required={field.required}
          key={`textfield-${field.key}`}
          error={!isFieldValid(field.key)}
          InputLabelProps={{ shrink: true }}
          multiline={field.multiline === true}
          label={field.label}
          helperText={field.helperText}
          value={clone[field.key] || ""}
          variant="outlined"
          onChange={handleChange(field.key)}
          onBlur={handleChange(field.key)}
          margin="normal"
          InputProps={{
            name: field.key,
            id: `item-${field.key}`,
            readOnly: field.readOnly || false,
            endAdornment: (
              <InputAdornment position="end">
                {
                  // waiting for response
                  loading ? (
                    <CircularProgress color="secondary" size={30} />
                  ) : // when request is not sent yet, or it is successful
                  success === null || success === true ? (
                    <Sync onClick={onClickHandler()} />
                  ) : (
                    // when request returned an error
                    <SyncProblem color="secondary" onClick={onClickHandler()} />
                  )
                }
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </Fragment>
  );
}
