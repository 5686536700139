const template = `
<meta content="width=device-width,initial-scale=1" name="viewport" />
<section class="container">
<div class="row">
<div class="col-xs-12 col-md-12">
<p><span style="font-family:arial,helvetica,sans-serif;"><span style="background-color: transparent; font-size: 11pt; color: rgb(0, 0, 0); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">This task </span><span style="background-color: transparent; font-size: 11pt; color: rgb(0, 0, 0); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; text-decoration-line: underline; text-decoration-skip-ink: none; vertical-align: baseline; white-space: pre-wrap;">can only be completed</span><span style="background-color: transparent; font-size: 11pt; color: rgb(0, 0, 0); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;"> if you have access to one of the below iPhone models, on Operating System iOS 12 or above:</span></span></p>

<ul style="margin-bottom: 0px;">
	<li dir="ltr" style="list-style-type: disc; font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline;">
	<p dir="ltr" role="presentation" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family:arial,helvetica,sans-serif;"><span id="docs-internal-guid-c8f04fa4-7fff-0e36-2005-f1fdbce951ca"><span style="font-size: 11pt; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">iPhone 7</span></span></span></p>
	</li>
	<li dir="ltr" style="list-style-type: disc; font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline;">
	<p dir="ltr" role="presentation" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family:arial,helvetica,sans-serif;"><span id="docs-internal-guid-c8f04fa4-7fff-0e36-2005-f1fdbce951ca"><span style="font-size: 11pt; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">iPhone 7+</span></span></span></p>
	</li>
	<li dir="ltr" style="list-style-type: disc; font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline;">
	<p dir="ltr" role="presentation" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family:arial,helvetica,sans-serif;"><span id="docs-internal-guid-c8f04fa4-7fff-0e36-2005-f1fdbce951ca"><span style="font-size: 11pt; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">iPhone 8</span></span></span></p>
	</li>
	<li dir="ltr" style="list-style-type: disc; font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline;">
	<p dir="ltr" role="presentation" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family:arial,helvetica,sans-serif;"><span id="docs-internal-guid-c8f04fa4-7fff-0e36-2005-f1fdbce951ca"><span style="font-size: 11pt; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">iPhone 8+</span></span></span></p>
	</li>
	<li dir="ltr" style="list-style-type: disc; font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline;">
	<p dir="ltr" role="presentation" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family:arial,helvetica,sans-serif;"><span id="docs-internal-guid-c8f04fa4-7fff-0e36-2005-f1fdbce951ca"><span style="font-size: 11pt; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">iPhone X</span></span></span></p>
	</li>
	<li dir="ltr" style="list-style-type: disc; font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline;">
	<p dir="ltr" role="presentation" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family:arial,helvetica,sans-serif;"><span id="docs-internal-guid-c8f04fa4-7fff-0e36-2005-f1fdbce951ca"><span style="font-size: 11pt; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">iPhone XS</span></span></span></p>
	</li>
	<li dir="ltr" style="list-style-type: disc; font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline;">
	<p dir="ltr" role="presentation" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family:arial,helvetica,sans-serif;"><span id="docs-internal-guid-c8f04fa4-7fff-0e36-2005-f1fdbce951ca"><span style="font-size: 11pt; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">iPhone XS Max</span></span></span></p>
	</li>
	<li dir="ltr" style="list-style-type: disc; font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline;">
	<p dir="ltr" role="presentation" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family:arial,helvetica,sans-serif;"><span id="docs-internal-guid-c8f04fa4-7fff-0e36-2005-f1fdbce951ca"><span style="font-size: 11pt; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">iPhone XR</span></span></span></p>
	</li>
	<li dir="ltr" style="list-style-type: disc; font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline;">
	<p dir="ltr" role="presentation" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family:arial,helvetica,sans-serif;"><span id="docs-internal-guid-c8f04fa4-7fff-0e36-2005-f1fdbce951ca"><span style="font-size: 11pt; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">iPhone 11</span></span></span></p>
	</li>
	<li dir="ltr" style="list-style-type: disc; font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline;">
	<p dir="ltr" role="presentation" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family:arial,helvetica,sans-serif;"><span id="docs-internal-guid-c8f04fa4-7fff-0e36-2005-f1fdbce951ca"><span style="font-size: 11pt; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">iPhone 11 Pro</span></span></span></p>
	</li>
	<li dir="ltr" style="list-style-type: disc; font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline;">
	<p dir="ltr" role="presentation" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family:arial,helvetica,sans-serif;"><span id="docs-internal-guid-c8f04fa4-7fff-0e36-2005-f1fdbce951ca"><span style="font-size: 11pt; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">iPhone 11 Pro Max</span></span></span></p>
	</li>
</ul>

<p>&nbsp;</p>

<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-family:arial,helvetica,sans-serif;"><span id="docs-internal-guid-c8f04fa4-7fff-0e36-2005-f1fdbce951ca"><span style="font-size: 11pt; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; text-decoration-line: underline; text-decoration-skip-ink: none; vertical-align: baseline; white-space: pre-wrap;">Note</span><span style="font-size: 11pt; color: rgb(0, 0, 0); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">: any users who try to complete this task with devices outside of those listed above will not be provided a code and will not be able to finish the task.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-family:arial,helvetica,sans-serif;"><span style="background-color: transparent; font-size: 11pt; color: rgb(0, 0, 0); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; text-decoration-line: underline; text-decoration-skip-ink: none; vertical-align: baseline; white-space: pre-wrap;">If you have completed this task before</span><span style="background-color: transparent; font-size: 11pt; color: rgb(0, 0, 0); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">: please ensure you update to the latest version of the app or you will not be able to finish the task.</span></span></p>

<p><span style="font-family:arial,helvetica,sans-serif;"><span style="background-color: transparent; color: rgb(0, 0, 0); font-size: 11pt; white-space: pre-wrap;">Vision Project is a market research app that completes eye tracking research whilst you read articles. Our T&amp;Cs and privacy policy can be found below.</span></span></p>

<ul>
	<li><span style="font-family:arial,helvetica,sans-serif;">​<span id="docs-internal-guid-c8f04fa4-7fff-0e36-2005-f1fdbce951ca"><a href="https://www.vision-project.co/terms"><span style="font-size: 11pt; color: rgb(17, 85, 204); background-color: transparent; font-weight: 400; font-variant-numeric: normal; font-variant-east-asian: normal; text-decoration-line: underline; text-decoration-skip-ink: none; vertical-align: baseline; white-space: pre-wrap;">https://www.vision-project.co/terms​</span></a></span></span></li>
	<li><span style="font-family:arial,helvetica,sans-serif;"><a href="https://playground.xyz/privacy/"><span style="font-size: 11pt; color: rgb(17, 85, 204); background-color: transparent; font-weight: 400; font-variant-numeric: normal; font-variant-east-asian: normal; text-decoration-line: underline; text-decoration-skip-ink: none; vertical-align: baseline; white-space: pre-wrap;">https://playground.xyz/privacy/</span></a></span></li>
</ul>

<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-family:arial,helvetica,sans-serif;"><span id="docs-internal-guid-c8f04fa4-7fff-0e36-2005-f1fdbce951ca"><span style="font-size: 11pt; color: rgb(0, 0, 0); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">&nbsp;</span></span></span></p>

<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-family:arial,helvetica,sans-serif;"><span style="font-size: 11pt; color: rgb(0, 0, 0); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">​</span></span></p>

<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-family:arial,helvetica,sans-serif;"><span id="docs-internal-guid-c8f04fa4-7fff-0e36-2005-f1fdbce951ca"><span style="font-size: 11pt; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">What&#39;s Involved?</span></span></span></p>

<ul dir="ltr">
	<li><span style="font-family:arial,helvetica,sans-serif;"><span id="docs-internal-guid-c8f04fa4-7fff-0e36-2005-f1fdbce951ca"><span style="font-size: 11pt; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">During this task you will be required to use our app to read the news for around 30 minutes.&nbsp;</span></span></span></li>
	<li role="presentation" style="list-style-type: disc; font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre; line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family:arial,helvetica,sans-serif;"><span id="docs-internal-guid-c8f04fa4-7fff-0e36-2005-f1fdbce951ca"><span style="font-size: 11pt; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">In between reading news articles, you will be required to play a calibration game.&nbsp;</span></span></span></li>
	<li role="presentation" style="list-style-type: disc; font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre; line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family:arial,helvetica,sans-serif;"><span id="docs-internal-guid-c8f04fa4-7fff-0e36-2005-f1fdbce951ca"><span style="font-size: 11pt; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">You will also be asked to complete a short survey at the end of the task.&nbsp;</span></span></span></li>
	<li role="presentation" style="list-style-type: disc; font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre; line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family:arial,helvetica,sans-serif;"><span id="docs-internal-guid-c8f04fa4-7fff-0e36-2005-f1fdbce951ca"><span style="font-size: 11pt; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">We will be completing eye tracking research while you read the articles.&nbsp;</span></span></span></li>
	<li role="presentation" style="list-style-type: disc; font-size: 11pt; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre; line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family:arial,helvetica,sans-serif;"><span id="docs-internal-guid-c8f04fa4-7fff-0e36-2005-f1fdbce951ca"><span style="font-size: 11pt; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">You must complete this testing in good lighting conditions.</span></span></span></li>
</ul>

<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">&nbsp;</p>

<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-family:arial,helvetica,sans-serif;"><span id="docs-internal-guid-c8f04fa4-7fff-0e36-2005-f1fdbce951ca"><span style="font-size: 11pt; color: rgb(0, 0, 0); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">&nbsp;</span></span></span></p>

<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-family:arial,helvetica,sans-serif;"><span id="docs-internal-guid-c8f04fa4-7fff-0e36-2005-f1fdbce951ca"><span style="font-size: 11pt; color: rgb(0, 0, 0); background-color: transparent; font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">Duration</span></span></span></p>

<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-family:arial,helvetica,sans-serif;"><span id="docs-internal-guid-c8f04fa4-7fff-0e36-2005-f1fdbce951ca"><span style="font-size: 11pt; color: rgb(0, 0, 0); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">We expect this task will take you around 30 minutes to complete.</span></span></span></p>

<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">&nbsp;</p>

<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">&nbsp;</p>

<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-family:arial,helvetica,sans-serif;"><strong><span id="docs-internal-guid-c8f04fa4-7fff-0e36-2005-f1fdbce951ca"><span style="font-size: 11pt; color: rgb(0, 0, 0); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">How to Complete</span></span></strong></span></p>

<ol dir="ltr" style="margin-bottom: 15px;">
	<li style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family:arial,helvetica,sans-serif;"><span style="background-color: transparent; color: rgb(0, 0, 0); font-size: 11pt; white-space: pre-wrap;">Click on the button below to generate your unique access URL. Open this link and follow the instructions to access the app.​</span></span></li>
</ol>

<p style="text-align: center;"><a href="#" id="xyz-invite-url" target="_blank" >INVITE URL</a></p>

<ol dir="ltr" start="2" style="margin-top: 5px;">
	<li style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="background-color: transparent; color: rgb(0, 0, 0); font-size: 11pt; white-space: pre-wrap; font-family: arial, helvetica, sans-serif;">Once in the app you will be required to complete a calibration game several times (for any issues with calibration, see the tips further below). In between each game you will be required to read articles. Once you have completed this part of the test, you will be taken to a survey. Please complete the survey and make sure to note down the unique code which is displayed at the end.​</span></li>
	<li style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family:arial,helvetica,sans-serif;"><span style="background-color: transparent; color: rgb(0, 0, 0); font-size: 11pt; white-space: pre-wrap;"><span style="font-size: 11pt; color: rgb(0, 0, 0); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;"><span style="background-color: transparent; color: rgb(0, 0, 0); font-size: 11pt; white-space: pre-wrap;">Once you&#39;ve completed the task and received your unique code, please return to this page and submit the code in the box below.</span></span></span></span></li>
</ol>

<div class="form-group"><span style="font-family:arial,helvetica,sans-serif;"><input class="form-control" id="surveycode" name="surveycode" placeholder="e.g. 123456" required="" type="text" /></span></div>

<div class="form-group">&nbsp;</div>

<div class="form-group">
<p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span style="font-family:arial,helvetica,sans-serif;"><strong><span id="docs-internal-guid-c8f04fa4-7fff-0e36-2005-f1fdbce951ca"><span style="font-size: 11pt; color: rgb(0, 0, 0); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">Having trouble completing the task or with the calibration game?&nbsp;</span></span></strong></span></p>

<p><span style="font-family:arial,helvetica,sans-serif;"><span id="docs-internal-guid-c8f04fa4-7fff-0e36-2005-f1fdbce951ca"><span style="font-size: 11pt; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">Please refer to the FAQs on your unique Landing Page URL.</span></span></span></p>
</div>
</div>
</div>
</section>
<link crossorigin="anonymous" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.0.3/css/bootstrap.min.css" integrity="sha384-IS73LIqjtYesmURkDE9MXKbXqYA8rvKEp/ghicjem7Vc3mGRdQRptJSz60tvrB6+" rel="stylesheet" /><!-- Open internal style sheet --><script type="text/javascript">
(function() {
  var STUDY = '{{STUDY-ID}}'
  var assignmentId = turkGetParam('assignmentId', '');
  if (assignmentId != '' && assignmentId != 'ASSIGNMENT_ID_NOT_AVAILABLE') {
   var inviteAttr = document.getElementById('xyz-invite-url'); 
   var workerId = turkGetParam('workerId', '');
   var url = 'https://www.vision-project.co/landing.html?user_id='+workerId+'&study_id='+STUDY
   inviteAttr.href = url;
  }
 })();
</script>
<style type="text/css">h2 {
    font-size: 18pt;
    line-height: 24pt;
  }

  body {
    font-size: 11pt;
    line-height: 16pt;
  }
  #invite-url-container{
    display:inline;
  }
  p {
    margin-bottom: 20px
  }

  a {
    font-weight: bold;
  }

  #usercode {
    font-size: 130%;
  }

  ol {
    margin-bottom: 50px;
  }
</style>
<!-- Close internal javascript -->
`;

export default template;
