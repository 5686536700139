import { auth } from "../../firebaseConfig";

/**
 * @async
 * @name getIdToken
 * @description - Will attempt to generate a new token for the user.
 * @returns {Promise<null|string>} if null, user couldn't be found, else, token
 */
export const getIdToken = async (): Promise<null | string> => {
  const user = auth.currentUser;
  if (!user) {
    return null;
  }
  return await user.getIdToken(true);
};
