import {vpApiUrl} from '../../../config';
import {
  Entity,
  GetEntityResponse,
  StudyGroupReport,
  StudyReport,
} from '../../core/interfaces';

class VpApi {
  headers: Headers;

  constructor(idToken: string) {
    this.headers = new Headers({
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    });
  }

  async getStudyGroupReport(studyIds: string): Promise<StudyGroupReport> {
    const response = await fetch(
      `${vpApiUrl}/report/studygroup?studyIds=${studyIds}`,
      {
        method: 'get',
        headers: this.headers,
      },
    );
    if (response.status !== 200) {
      return {
        success: false,
      };
    }
    const data = await response.json();
    return {
      success: true,
      data,
    };
  }

  async getHeatmapReport(body: any): Promise<any> {
    const response = await fetch(`${vpApiUrl}/report/heatmap`, {
      method: 'post',
      headers: this.headers,
      body: JSON.stringify(body),
    });
    if (response.status !== 200) {
      return {
        success: false,
      };
    }
    const data = await response.json();
    return {
      success: true,
      data,
    };
  }

  async getStudyReport({
    studyId,
    creativeIds,
    campaignId,
  }: {
    studyId: string;
    creativeIds: string;
    campaignId: string;
  }): Promise<StudyReport> {
    const response = await fetch(
      `${vpApiUrl}/report/study?studyId=${studyId}&creativeIds=${creativeIds}&campaignId=${campaignId}`,
      {
        method: 'get',
        headers: this.headers,
      },
    );
    if (response.status !== 200) {
      const errorMsg = await response.text();
      return {
        success: false,
        error: {
          message: errorMsg || null,
        },
      };
    }
    const data = await response.json();
    return {
      success: true,
      data,
    };
  }

  async getBenchamrkReport(adFormats: string): Promise<any> {
    const response = await fetch(
      `${vpApiUrl}/report/benchmarks?formats=${adFormats}`,
      {
        method: 'get',
        headers: this.headers,
      },
    );
    if (response.status !== 200) {
      const errorMsg = await response.text();
      return {
        success: false,
        error: {
          message: errorMsg || null,
        },
      };
    }
    const data = await response.json();
    return {
      success: true,
      data,
    };
  }

  async getSMSurvey(collectorId: string): Promise<any> {
    const response = await fetch(
      `${vpApiUrl}/surveyMonkey/getSurvey/${collectorId}`,
      {
        method: 'get',
        headers: this.headers,
      },
    );
    if (response.status !== 200) {
      const errorMsg = await response.text();
      return {
        success: false,
        error: {
          message: errorMsg || null,
        },
      };
    }
    const data = await response.json();
    return {
      success: true,
      data,
    };
  }

  async getEntity<T extends Entity>(
    path: string,
    params: {showArchived: boolean},
  ): Promise<GetEntityResponse<T>> {
    const {showArchived} = params;

    try {
      const response = await fetch(
        `${vpApiUrl}/${path}?showArchived=${showArchived || false}`,
        {
          method: 'get',
          headers: this.headers,
        },
      );
      // TODO: add error handler handling the new type of errors that the new api endpoints sends back
      const data = await response.json();
      if (response.status === 200) {
        return {
          success: true,
          data: data.data,
        };
      }
      return {
        success: false,
        errors: data.errors || [{message: 'unrecognized error'}], // this should be a default error
      };
    } catch (error) {
      return {
        success: false,
        errors: [{message: `Failed to fetch ${path}`}],
      };
    }
  }

  async saveEntity(path: string, body: any, id?: string) {
    const method = id ? 'put' : 'post';
    const response = await fetch(`${vpApiUrl}/${path}/${id || ''}`, {
      method,
      headers: this.headers,
      body: JSON.stringify(body),
    });
    const data = await response.json();

    if (response.status === 200) {
      return {
        success: true,
        errors: [],
      };
    }
    return {
      success: false,
      errors: data.errors || [{message: 'unrecognized error'}], // this should be a default error
    };
  }

  async saveStudy(path: string, body: any) {
    const method = 'post';
    const response = await fetch(`${vpApiUrl}/study`, {
      method,
      headers: this.headers,
      body: JSON.stringify(body),
    });
    const data = await response.json();
    if (response.status === 200) {
      return {
        id: data.data.id,
        success: true,
        errors: [],
      };
    }
    return {
      success: false,
      errors: data.errors || [{message: 'unrecognized error'}], // this should be a default error
    };
  }
}

export default VpApi;
