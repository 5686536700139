import * as React from "react";
import { RouteComponentProps } from "react-router";
import styles from "./stage.scss";
import { MenuItem } from "../../core/interfaces";

interface iProps {
  menuItem: MenuItem;
  routeProps: RouteComponentProps;
}

export class Stage extends React.Component<iProps, {}> {
  /**
   * @function
   * @name render
   * @description - standard react render method
   * @returns {React.ReactNode} - returns jsx markup
   */
  render(): React.ReactNode {
    const { component: Component } = this.props.menuItem;
    return (
      <div className={styles.stage}>
        <Component {...this.props.routeProps} />
      </div>
    );
  }
}
