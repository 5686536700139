import * as React from "react";
import { TransitionProps } from "@material-ui/core/transitions";
import { Theme, cloudFunctionBase } from "../../../config";
import { FeedConfig } from "../../core/interfaces";
import {
  Box,
  Grid,
  Slide,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { Loader } from "../../components/loader/loader.react";
import styles from "./feedConfigs.scss";

const { Fragment } = React;
const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);
const BAIL_REFRESH_AFTER_MS = 540000;

interface iState {
  error: boolean;
  isOpen: boolean;
  msg: string;
  isLoading: boolean;
}

interface iProps {
  type: string;
  isOpen: boolean;
  config?: FeedConfig;
  onClose?(state: boolean): void;
}

const DEFAULT_STATE: iState = {
  isLoading: false,
  error: false,
  isOpen: false,
  msg: ``,
};

export class RefreshFeedDialog extends React.Component<iProps, iState> {
  constructor(props: iProps) {
    super(props);

    this.state = {
      ...DEFAULT_STATE,
    };
  }

  /**
   * @function
   * @name resetState
   * @description Helper method to update the state back to it's original form.
   * @returns { void } - no return value
   */
  resetState(): void {
    this.setState({
      ...DEFAULT_STATE,
    });
  }

  /**
   * @function
   * @name onClose
   * @description When this dialog closes, this function will be called.
   * @returns { void } - no return value
   */
  onClose(): void {
    this.resetState();
    if (typeof this.props.onClose === "function") {
      this.props.onClose(true);
    }
  }

  /**
   * @function
   * @name componentDidMount
   * @description lifecycle hook to update the state once we've loaded it on the page.
   * @returns { void } - no return value
   */
  componentDidMount(): void {
    this.setState({
      isOpen: this.props.isOpen,
    });
  }

  /**
   * @static
   * @note This method is a static method and cannot access the current class context
   * @function
   * @name getDerivedStateFromProps - lifecycle hook to map props to state
   * Here we make sure that the props isOpen value is not equal to the current state, if it isn't
   * we return the object to mutate state.
   * @param {iProps} props.isOpen - the isOpen value from the props attribute
   * @param {iState} currentState - the current state of this component
   * @returns {null|iProps} - null or an object to update state.
   */
  static getDerivedStateFromProps(
    { isOpen = false, type = "automatic" },
    currentState: iState
  ): null | iProps {
    if (currentState.isOpen !== isOpen) {
      return {
        isOpen,
        type,
      };
    }
    return null;
  }

  /**
   * @function
   * @name onRefresh
   * @description called when the user presses on the refresh button in this dialog, it will make a request to the
   * refresh endpoint on cloud functions, wait for a response, then allow the user to close the dialog.
   * It will display the success or fail msg in the dialog box, changing the "refresh" button to an "okay" button which
   * resets the state.
   * @returns {void} no return value.
   */
  onRefresh(): void {
    if (this.state.msg && !this.state.error) {
      this.resetState();
      return;
    }
    const { id } = this.props.config;
    function bailAfter(ms: number, promise: Promise<Response>): Promise<any> {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error(`timeout`));
        }, ms);
        promise.then(resolve, reject);
      });
    }

    this.setState({
      isLoading: true,
    });

    bailAfter(
      BAIL_REFRESH_AFTER_MS,
      fetch(`${cloudFunctionBase}/article-feed-cache-refresh?id=${id}`, {
        keepalive: true,
        method: "GET",
        mode: "cors",
      })
    )
      .then(() => {
        // process response
        this.setState({
          isLoading: false,
          error: false,
          msg: `Successfully updated config feed data.`,
        });
      })
      .catch((error) => {
        // might be a timeout error
        // process response
        this.setState({
          isLoading: false,
          error: true,
          msg: `Error: ${error}`,
        });
      });
  }

  /**
   * @function
   * @name render
   * @description - standard react render method
   * @returns {React.ReactNode} - returns jsx markup
   */
  render(): React.ReactNode {
    if (!this.props.config) {
      return <Fragment></Fragment>;
    }
    return (
      <Fragment>
        <Dialog
          className={styles.refreshDialog}
          maxWidth="lg"
          fullWidth={true}
          TransitionComponent={Transition}
          open={this.state.isOpen}
        >
          <Theme>
            <AppBar position="static">
              <Toolbar className="toolbar">
                <div>
                  <Typography variant="h6">
                    FeedConfig: {this.props.config.name}
                  </Typography>
                </div>
              </Toolbar>
            </AppBar>
          </Theme>
          <DialogContent dividers={true}>
            <Box py={5}>
              <Grid
                container
                spacing={2}
                className="layout-row layout-align-space-between-center layout-fill"
              >
                <Grid
                  item
                  xs={12}
                  className="layout-column layout-align-start-center layout-fill children-fill-width"
                >
                  {this.state.msg === "" && this.props.type === "automatic" && (
                    <Fragment>
                      <Typography>{`We've detected a change in this config that requires you to refresh the data in the feed, would you like to do this now?`}</Typography>
                      <Typography>{`This task can take anywhere up to 9 minutes so please don't close your browser.`}</Typography>
                    </Fragment>
                  )}
                  {this.state.msg === "" && this.props.type === "manual" && (
                    <Fragment>
                      <Typography>{`You're about to trigger a refresh on the data for this feed config.`}</Typography>
                      <Typography>{`This task can take anywhere up to 9 minutes so please don't close your browser.`}</Typography>
                    </Fragment>
                  )}
                  <br />
                  {this.state.isLoading && <Loader />}
                  {this.state.msg !== "" && (
                    <Typography
                      className={this.state.error ? `is-error` : `is-success`}
                    >
                      {this.state.msg}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Theme>
              <Button
                disabled={this.state.isLoading}
                color="primary"
                autoFocus
                onClick={this.onClose.bind(this)}
              >
                Cancel
              </Button>
              <Button
                disabled={this.state.isLoading}
                color="primary"
                onClick={
                  this.state.msg && !this.state.error
                    ? this.onClose.bind(this)
                    : this.onRefresh.bind(this)
                }
              >
                {this.state.msg && !this.state.error ? `Done` : `Refresh`}
              </Button>
            </Theme>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}
