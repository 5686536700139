import React, { Fragment, useState } from "react";
import DateFormat from "date-format";
import moment from "moment";
import _ from "lodash";
import { Chip, Typography, Tooltip, Divider } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import { FeedConfig } from "../../core/interfaces";

interface FeedConfigMethods {
  createSchedule(clone: FeedConfig): Promise<boolean>;
  refreshList(): Promise<void>;
}

interface FeedConfigCloudReportProps {
  feedConfig: FeedConfig;
  methods: FeedConfigMethods;
}

interface LastRefreshedData {
  show: boolean;
  msg: string | array;
  key?: string;
  style?: boolean;
  asErrorIf?: boolean;
}

const ScheduleCodes = {
  0: "SUCCESS",
  1: "FAILED CANCELLED",
  2: "UNKNOWN",
  3: "FAILED INVALID_ARGUMENT",
  4: "FAILED DEADLINE_EXCEEDED",
  5: "FAILED NOT_FOUND",
  6: "FAILED ALREADY_EXISTS",
  7: "FAILED PERMISSION_DENIED",
  8: "FAILED RESOURCE_EXHAUSTED",
  9: "FAILED_PRECONDITION",
  10: "FAILED ABORTED",
  11: "FAILED OUT_OF_RANGE",
  12: "FAILED UNIMPLEMENTED",
  13: "FAILED INTERNAL",
  14: "FAILED UNAVAILABLE",
  15: "FAILED DATA_LOSS",
  16: "FAILED UNAUTHENTICATED",
};

const formatServerTimestamp = (seconds: number): string => {
  if (seconds && seconds > 0) {
    const miliseconds: number = parseInt(`${seconds}`, 10) * 1000;
    return DateFormat.asString("dd/MM/yy hh:mm:ss", new Date(miliseconds));
  }
  return "N/A";
};

/**
 * @name FeedConfigCloudReport
 * @description The component should accept a feed config, and some methods from the feed controller
 * and return a simple report around the cloud status.
 * @returns {React.ReactNode} the jsx element.
 */
export const FeedConfigCloudReport: React.FunctionComponent<
  FeedConfigCloudReportProps
> = ({ feedConfig, methods }) => {
  // if archived, don't bother with all the rest of this data.
  if (feedConfig.archived) {
    return (
      <Fragment>
        <div className="refreshScheduleButton layout-column layout-align-center-center layout-fill">
          <Typography variant="caption">Not available when archived</Typography>
        </div>
      </Fragment>
    );
  }

  const [isLoading, setIsLoading] = useState(false);
  const cloudStatus = _.get(
    feedConfig,
    "cloudFunctionStatus.articleFeedCacheRefresh",
    false
  );
  const startedAt = _.get(cloudStatus, "startedAt.seconds", -1);
  const finishedAt = _.get(cloudStatus, "finishedAt.seconds", -1);
  const isFinished =
    cloudStatus &&
    finishedAt &&
    startedAt &&
    cloudStatus.status === true &&
    finishedAt > startedAt;
  const schedule = _.get(feedConfig, "schedule", false);
  const scheduleTime = _.get(schedule, "scheduleTime.seconds", "");
  const scheduleLastRun = !schedule
    ? ""
    : schedule.lastAttemptTime === null
    ? `Not run yet`
    : _.get(schedule, "lastAttemptTime.seconds", "");
  const scheduleStatus = !schedule
    ? ""
    : schedule.status
    ? ScheduleCodes[schedule.status.code]
    : "";

  let shouldHaveFailed = false;
  let elapsed = null;
  // if both times have a value > 0, and the start date after the finished date, check how long it's been running for.
  if (finishedAt === -1 && startedAt > 0 && startedAt > finishedAt) {
    const started = moment(startedAt * 1000);
    const now = moment();
    const elapsedSeconds = now.diff(started, "seconds");
    elapsed = started.fromNow();
    // cloud functions have a timeout of 540 seconds, so if it's breached 600, something's gone wrong.
    shouldHaveFailed = elapsedSeconds > 600;
  }

  const processMessages = [<span key="msg">{cloudStatus.message}</span>];
  if (shouldHaveFailed) {
    processMessages.push(
      <Divider key="div" light />,
      <span key="info">{`Didn't finish in time (${elapsed}), try again.`}</span>
    );
  }

  const messages: LastRefreshedData[] = [
    {
      show: !isFinished && cloudStatus,
      key: "Process",
      style:
        shouldHaveFailed ||
        (cloudStatus && cloudStatus.detail) ||
        (cloudStatus && cloudStatus.message.toLowerCase().includes("failed")),
      asErrorIf: cloudStatus && !cloudStatus.status,
      msg: processMessages,
    },
    {
      show: startedAt && finishedAt && finishedAt > startedAt && isFinished,
      msg: cloudStatus && cloudStatus.message,
      style: true,
      asErrorIf: cloudStatus && !cloudStatus.status,
    },
  ];

  // this will show up when you hover over the main text.
  const detailedMessages: LastRefreshedData[] = [
    {
      show: scheduleTime,
      key: "Next Schedule Update",
      msg: formatServerTimestamp(scheduleTime),
    },
    {
      show: scheduleLastRun,
      key: "Schedule last run",
      msg: formatServerTimestamp(scheduleLastRun),
    },
    {
      show: scheduleStatus,
      key: "Schedule Status",
      msg:
        !isFinished &&
        cloudStatus &&
        scheduleStatus &&
        _.get(schedule, "status.code", -1) === 0
          ? "Currently running..."
          : scheduleStatus,
    },
    {
      show: !cloudStatus,
      key: "Warning",
      msg: "Missing cloud data",
    },
    {
      show: startedAt,
      key: "Started at",
      msg: formatServerTimestamp(startedAt),
    },
    {
      show: isFinished,
      key: "Finished at",
      msg: formatServerTimestamp(finishedAt),
    },
    {
      show: cloudStatus.status === false && cloudStatus.detail,
      key: "Error",
      msg: cloudStatus.detail,
    },
  ];

  const getMessages = (dataToMap: LastRefreshedData[]): React.ReactNode[] => {
    return dataToMap.map((message, i) => {
      return (
        message.show && (
          <Fragment key={i}>
            <Typography
              color={
                message.style
                  ? message.asErrorIf
                    ? "error"
                    : "primary"
                  : "initial"
              }
              variant="caption"
            >
              {message.key ? <b>{message.key}: </b> : ""}
              {message.msg}
            </Typography>
            <br />
          </Fragment>
        )
      );
    });
  };
  // if archived, don't bother with all the rest of this data.
  return (
    <Fragment>
      <div className="refreshScheduleButton layout-column layout-align-center-center layout-fill">
        {feedConfig.schedule === null && (
          <Fragment>
            <Typography color="error" variant="caption">
              Missing Schedule
            </Typography>
            <Chip
              icon={<Refresh className={isLoading ? "is-loading" : ""} />}
              variant="outlined"
              label={isLoading ? `Loading...` : `Create Schedule`}
              disabled={isLoading}
              color={"secondary"}
              onClick={async (): Promise<void> => {
                // tell the button we're loading, disable in this case.
                setIsLoading(true);
                // create the schedule, parent handles catch cases
                await methods.createSchedule(feedConfig);
                // update the parent list
                await methods.refreshList();
                // update state.
                setIsLoading(false);
              }}
            />
          </Fragment>
        )}
        <Tooltip title={<Fragment>{getMessages(detailedMessages)}</Fragment>}>
          <div>{getMessages(messages)}</div>
        </Tooltip>
      </div>
    </Fragment>
  );
};
