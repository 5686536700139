import {Grid, LinearProgress} from '@material-ui/core';
import React, {useEffect, useRef, useState} from 'react';
import {
  checkForErrors,
  getHelper,
  hasError,
  removeError,
} from './errorHandlers';

export const VpForm: React.FunctionComponent<any> = props => {
  const formRef = useRef(null);
  const [errors, setErrors] = useState<any[]>([]);

  useEffect(() => {
    if (props.submitTracker > 0) {
      const hasErrors = checkForErrors(props.item, formRef, setErrors);
      props.onSubmit(hasErrors);
    }
  }, [props.submitTracker]);

  return (
    <form id={props.id} ref={formRef}>
      {props.submitTracker > 0 && errors.length === 0 ? (
        <React.Fragment>
          <p>Submitting Form...</p>
          <LinearProgress />
        </React.Fragment>
      ) : null}
      <Grid
        container
        spacing={2}
        className="layout-row layout-align-space-between-center"
        justifyContent="center"
        alignItems="center"
      >
        {props.content.map((column: any, index: number) => {
          return (
            <Grid
              key={index}
              item
              xs={6}
              className="layout-column layout-align-start-center  children-fill-width"
            >
              {column.map((item: any) => {
                return React.cloneElement(item, {
                  onBlur: (e: any) => {
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    if (item.props.required) {
                      setErrors(removeError(errors, e.target.name));
                      setErrors(removeError(errors, 'custom'));
                    }
                    item.props.onBlur?.(e);
                  },
                  errors,
                  error: hasError(errors, item.props.name),
                  helperText: getHelper(errors, item.props.label),
                });
              })}
            </Grid>
          );
        })}
      </Grid>
    </form>
  );
};
