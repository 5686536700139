import * as React from "react";
import { NavLink } from "react-router-dom";
import { ExitToApp } from "@material-ui/icons";
import styles from "./sidebar.scss";
import logo from "./vision-project-logo.png";
import { MenuItem } from "../../core/interfaces";

interface iProps {
  menuItems: MenuItem[];
  logout(): void;
}

export class Sidebar extends React.Component<iProps, {}> {
  /**
   * @function
   * @name render
   * @description - standard react render method
   * @returns {React.ReactNode} - returns jsx markup
   */
  render(): React.ReactNode {
    return (
      <div id={styles.sidebar}>
        <div id={styles.children}>
          <div id={styles.header}>
            <img src={logo} />
          </div>
          <div id={styles.body}>
            <nav>
              {this.props.menuItems.map((menuItem) => {
                const { icon: Icon } = menuItem;
                return (
                  <NavLink
                    exact
                    key={menuItem.name}
                    activeClassName={styles.isActive}
                    to={menuItem.root}
                    className={styles.menuItem}
                  >
                    <span className="layout-row layout-align-start-center">
                      <i className="layout-row layout-align-center-center">
                        <Icon />
                      </i>
                      <span>{menuItem.name}</span>
                    </span>
                  </NavLink>
                );
              })}
              {this.props.logout ? (
                <NavLink
                  onClick={(): void => this.props.logout()}
                  key={"Logout"}
                  to={"/"}
                  className={styles.menuItem}
                >
                  <span className="layout-row layout-align-start-center">
                    <i className="layout-row layout-align-center-center">
                      <ExitToApp />
                    </i>
                    <span>{"Logout"}</span>
                  </span>
                </NavLink>
              ) : null}
            </nav>
          </div>
        </div>
      </div>
    );
  }
}
