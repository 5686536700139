import React, { Fragment, useState, useEffect } from "react";
import moment from "moment-timezone";
import { Paper, Grid, Button } from "@material-ui/core";
import { Theme, dateFormats } from "../../../config";
import { StudyGroup } from "../../core/interfaces";
import { getIdToken } from "../../utils/idToken";
import { Firestore } from "../dataManager/firestore";
import { studyFilters } from "../../controllers/heatmapping/studyFilters.react";
import { DateFilters } from "./dateFilters.react";
import { AttentionTimeFilters } from "./attentionTimeFilters.react";

const firestore = new Firestore();

interface ReportingFiltersProps {
  filters: string[];
  className: string;
  button: {
    text: string;
    onClick: any;
    validateInputs: any;
  };
  clearSnackBar: any;
}

interface SelectedAttentionTimes {
  from: number | null;
  to: number | null;
}

const formatOptions = (arr: any[]): { value: string; label: string }[] => {
  return arr.map((i: any) => ({ value: i.id, label: i.name }));
};

export const ReportingFilters: React.FunctionComponent<
  ReportingFiltersProps
> = ({ filters, className, button, clearSnackBar }) => {
  // state hooks
  const [idToken, setIdToken] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [studyGroups, setStudyGroups] = useState([]);
  const [studies, setStudies] = useState([]);
  const [creatives, setCreatives] = useState([]);
  const [selectedStudyGroup, setSelectedStudyGroup] = useState(null);
  const [selectedStudy, setSelectedStudy] = useState(null);
  const [selectedCreative, setSelectedCreative] = useState(null);
  const [selectedDates, setSelectedDates] = useState({
    start: moment().format(dateFormats.display),
    end: moment().format(dateFormats.display),
  });
  const [selectedAttentionTimes, setSelectedAttentionTimes] =
    useState<SelectedAttentionTimes>({
      from: null,
      to: null,
    });
  const [errors, setErrors] = useState({
    study: {
      error: false,
      helperText: "",
    },
    dates: {
      error: false,
      helperText: "",
    },
    attentionTimes: {
      error: false,
      helperText: "",
    },
  });

  const unsetChildern = (type: string): void => {
    switch (type) {
      case "studyGroup":
        setStudies([]);
        setSelectedStudy(null);
        setCreatives([]);
        setSelectedCreative(null);
        break;
      case "study":
        setCreatives([]);
        setSelectedCreative(null);
        break;
      default:
        break;
    }
  };

  const fetchStudyGroups = async (): Promise<StudyGroup[] | null> => {
    const response = await (firestore.getList(
      "studyGroups",
      true
    ) as unknown as Promise<{
      success: boolean;
      data: any[];
    }>);
    return response.success ? response.data : null;
  };

  const studiesByStudyGroup = (studyGroup: StudyGroup): void => {
    if (studyGroup && studyGroup.studies && studyGroup.studies.length) {
      // filter out the archived ones
      const studiesByGroup = studyGroup.studies.filter((s: any) => !s.archived);
      if (studiesByGroup && studiesByGroup.length) {
        setStudies(studiesByGroup);
      }
    }
  };

  const creativesByStudy = (study: any): void => {
    if (study && study.creatives && study.creatives.length) {
      setCreatives(study.creatives);
    }
  };

  const setInputError = (
    input: string,
    error: boolean,
    helperText: string
  ): void => {
    setErrors({
      ...errors,
      ...{ [input]: { error, helperText } },
    });
  };

  useEffect(() => {
    Promise.all([getIdToken(), fetchStudyGroups()])
      .then(([idTokenResult, data]) => {
        setIdToken(idTokenResult);
        setStudyGroups(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []); // this is called only after first render, a bit like componentDidMount
  return (
    <Fragment>
      <h2>Filters</h2>
      <Paper className="paper">
        <Grid container spacing={1} className={className}>
          {filters.includes("studyGroup")
            ? studyFilters({
                id: "studyGroups",
                label: "Study Group",
                options: formatOptions(studyGroups),
                name: "studyGroup",
                value: selectedStudyGroup ? selectedStudyGroup.id : "",
                error: errors.study.error,
                helperText: errors.study.helperText,
                onChange: (e: any) => {
                  setInputError("study", false, "");
                  clearSnackBar();
                  const studyGroupId = e.target.value || null;
                  const newSelectedStudyGroup = studyGroups.find(
                    (sg) => sg.id === studyGroupId
                  );
                  if (newSelectedStudyGroup) {
                    unsetChildern(e.target.name);
                    setSelectedStudyGroup(newSelectedStudyGroup);
                    studiesByStudyGroup(newSelectedStudyGroup);
                  }
                },
              })
            : null}
          {filters.includes("study")
            ? studyFilters({
                id: "study",
                label: "Study",
                options: formatOptions(studies),
                name: "study",
                value: selectedStudy ? selectedStudy.id : "",
                error: errors.study.error,
                helperText: errors.study.helperText,
                onChange: (e: any) => {
                  setInputError("study", false, "");
                  clearSnackBar();
                  const studyId = e.target.value || null;
                  const newSelectedStudy = studies.find(
                    (s) => s.id === studyId
                  );
                  if (newSelectedStudy) {
                    unsetChildern(e.target.name);
                    setSelectedStudy(newSelectedStudy);
                    creativesByStudy(newSelectedStudy);
                  }
                },
              })
            : null}
          {filters.includes("creative")
            ? studyFilters({
                id: "creative",
                label: "Creative Build ID",
                options: formatOptions(creatives),
                value: selectedCreative ? selectedCreative.id : "",
                error: errors.study.error,
                helperText: errors.study.helperText,
                onChange: (e: any) => {
                  setInputError("study", false, "");
                  clearSnackBar();
                  const creativeId = e.target.value || null;
                  const newSelectedCreative = creatives.find(
                    (s) => s.id === creativeId
                  );
                  if (newSelectedCreative) {
                    setSelectedCreative(newSelectedCreative);
                  }
                },
              })
            : null}
        </Grid>
        {filters.includes("dates") || filters.includes("attentionTimes") ? (
          <Grid container spacing={4} className="layout-row layout-row-spaced">
            {filters.includes("dates") ? (
              <DateFilters
                selectedDates={selectedDates}
                error={errors.dates.error}
                helperText={errors.dates.helperText}
                onChangeStart={(e) => {
                  setInputError("dates", false, "");
                  clearSnackBar();

                  const selectedStart = moment(e.target.value).format(
                    dateFormats.display
                  );
                  if (
                    selectedDates.end &&
                    moment(selectedStart).isAfter(selectedDates.end)
                  ) {
                    setInputError("dates", true, "Invalid Date Range");
                    return;
                  }
                  setSelectedDates({
                    start: selectedStart,
                    end: selectedDates.end,
                  });
                }}
                onChangeEnd={(e) => {
                  setInputError("dates", false, "");
                  clearSnackBar();

                  const selectedEnd = moment(e.target.value).format(
                    dateFormats.display
                  );
                  if (
                    selectedDates.start &&
                    moment(selectedEnd).isBefore(selectedDates.start)
                  ) {
                    setInputError("dates", true, "Invalid Date Range");
                    return;
                  }
                  setSelectedDates({
                    start: selectedDates.start,
                    end: selectedEnd,
                  });
                }}
              />
            ) : null}
            {filters.includes("attentionTimes") ? (
              <AttentionTimeFilters
                selectedAttentionTimes={selectedAttentionTimes}
                error={errors.attentionTimes.error}
                helperText={errors.attentionTimes.helperText}
                onChangeFrom={(e) => {
                  setInputError("attentionTimes", false, "");
                  clearSnackBar();

                  const from = parseFloat(e.target.value);
                  if (
                    selectedAttentionTimes.to &&
                    from > selectedAttentionTimes.to
                  ) {
                    setInputError(
                      "attentionTimes",
                      true,
                      "Invalid Attention Time Range"
                    );
                    return;
                  }
                  setSelectedAttentionTimes({
                    from,
                    to: selectedAttentionTimes.to,
                  });
                }}
                onChangeEnd={(e) => {
                  setInputError("attentionTimes", false, "");
                  clearSnackBar();

                  const to = parseFloat(e.target.value);
                  if (
                    selectedAttentionTimes.from &&
                    to < selectedAttentionTimes.from
                  ) {
                    setInputError(
                      "attentionTimes",
                      true,
                      "Invalid Attention Time Range"
                    );
                    return;
                  }
                  setSelectedAttentionTimes({
                    from: selectedAttentionTimes.from,
                    to,
                  });
                }}
              />
            ) : null}
          </Grid>
        ) : null}
        <Grid
          container
          spacing={1}
          className="layout-row layout-align-space-between-center"
        >
          <Grid
            item
            xs={2}
            className="layout-column layout-align-start-center layout-fill children-fill-width"
          >
            <Theme>
              <Button
                disabled={isDownloading}
                color="primary"
                onClick={async () => {
                  setIsDownloading(true);
                  const error = button.validateInputs({
                    studyGroup: selectedStudyGroup,
                    study: selectedStudy,
                    creative: selectedCreative,
                    dates: selectedDates,
                    attentionTimes: selectedAttentionTimes,
                  });

                  if (error) {
                    setInputError(error.type, true, error.helperText);
                    setIsDownloading(false);
                    return;
                  }

                  try {
                    await button.onClick({
                      idToken,
                      studyGroup: selectedStudyGroup,
                      study: selectedStudy,
                      creative: selectedCreative,
                      dates: selectedDates,
                      attentionTimes: selectedAttentionTimes,
                    });
                  } catch (err) {
                    console.error(err);
                    throw err;
                  }
                  setIsDownloading(false);
                }}
              >
                {button.text}
              </Button>
            </Theme>
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
};
