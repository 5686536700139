import React, {useEffect, useState} from 'react';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {TransitionProps} from '@material-ui/core/transitions';
import {VpForm} from '../form/vpForm';
import {FormColumn, FormValidation, AdFormat} from '../../core/interfaces';
import {
  AdFormatSizeMap,
  PXYZ_PREFIX,
  SLOTID_KEY,
  TAG_KEY,
} from '../../core/constants';

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props: any, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  },
);

interface FormDialogProps {
  dialog: {
    isDialogOpen: boolean;
    setIsDialogOpen: (isOpen: boolean) => void;
    title: string;
    subtitle: string;
    validation?: FormValidation;
  };
  original: {
    originalEntity: any;
    setOriginalEntity: (item: any) => void;
    listKey: string; // the key for the list
  };
  form: {
    columns: any[];
    id: string;
  };
}

export const FormDialog: React.FunctionComponent<FormDialogProps> = ({
  dialog,
  original,
  form,
}) => {
  const {isDialogOpen, setIsDialogOpen, title, subtitle, validation} = dialog;
  const {originalEntity, setOriginalEntity} = original;
  const {columns, id: formId} = form;

  const [entity, setEntity] = useState<any>(originalEntity);

  const [isSlotIdField, setSlotIdField] = useState<boolean>(false);

  // these two states, needToRunValidation and validationResults are used only when the parent
  // component is passing down the 'validationFunctions' property
  const [needToRunValidation, setNeedToRunValidation] = useState<boolean>(
    // addressing strict-boolean-expression check
    // previously !!validation
    !(validation == null),
  );

  const [selectedDropDownOption, setSelectedDropDownOption] =
    useState<string>('');

  // use pattern illustrated here https://stackoverflow.com/questions/37949981/call-child-method-from-parent
  // to call a child method (in vpForm) in the parent (this file)
  const [submitTracker, setSubmitTracker] = useState<number>(0);

  const resetValidation = (): void => {
    setNeedToRunValidation(!(validation == null));
  };

  useEffect(() => {
    setOriginalEntity(originalEntity);
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    setEntity(originalEntity || undefined);
  }, [originalEntity]);

  const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>): void => {
    let value: string | number = e.target.value;
    if (e.target.type === 'number') {
      value = parseInt(value);
    }
    resetValidation();
    setEntity({
      ...entity,
      [e.target.name]: value,
    });
  };

  /**
   * @returns a valid Adformat string
   */
  const getValidAdFormat = (str: string): string => {
    if (str === '') {
      return '';
    }
    return Object.values(AdFormat).includes(str as unknown as AdFormat)
      ? str
      : PXYZ_PREFIX + str;
  };

  /**
   * This useEffect observes on the value of selectedDropDownOption and autofills width/height values
   */
  useEffect(() => {
    if (Object.values(AdFormat).includes(selectedDropDownOption as AdFormat)) {
      // below is special code for adFormats. Selecting a specific ad format will autofill the width/height fields
      setEntity((prev: any) => ({
        ...prev,
        ...AdFormatSizeMap[selectedDropDownOption],
      }));

      // handle case for formats with slotID tag fields
      switch (selectedDropDownOption) {
        case AdFormat.GG_OUTSTREAM_IN_ARTICLE_VIDEO:
        case AdFormat.JP_MOBILE_SCROLLER:
          setSlotIdField(true);
          break;
        default:
          setSlotIdField(false);
          break;
      }
    }
  }, [selectedDropDownOption]);

  /**
   * This useEffect ensures the adFormat returned by updateAdFormatFromTag() is propogated
   */
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (entity?.adFormat) {
      setSelectedDropDownOption(getValidAdFormat(entity.adFormat));
    } else if (entity?.category) {
      setSelectedDropDownOption(entity.category);
    } else {
      setSelectedDropDownOption('');
    }
  }, [entity]);

  const handleAdFormatChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const name = e.target.name;
    const value = e.target.value;
    setEntity((prev: any) => ({
      ...prev,
      [name]: value,
    }));
    setSelectedDropDownOption(value);
  };

  const getDropdownModal = (input: FormColumn): JSX.Element => {
    const {key} = input.inputProps;
    return (
      <TextField
        variant="outlined"
        margin="normal"
        onBlur={onBlurHandler}
        name={key}
        value={selectedDropDownOption}
        select
        onChange={handleAdFormatChange}
        {...input.inputProps}
      >
        {input.dropdownOptions
          ?.sort()
          .map((option: string): React.ReactNode => {
            return (
              <MenuItem
                key={option}
                value={option}
                {...(option.startsWith(PXYZ_PREFIX)
                  ? {style: {display: 'none'}}
                  : null)}
              >
                {`${option}`}
              </MenuItem>
            );
          })}
      </TextField>
    );
  };

  const handleGenericInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const name = e.target.name;
    const value = e.target.value;
    setEntity((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getGenericFormModal = (input: FormColumn): JSX.Element => {
    const {key} = input.inputProps;
    if (key === 'tag') {
      input.inputProps.label = isSlotIdField ? SLOTID_KEY : TAG_KEY;
    }
    return (
      <TextField
        variant="outlined"
        margin="normal"
        onBlur={onBlurHandler}
        name={key}
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        value={entity?.[key] || originalEntity?.[key] || ''}
        onChange={handleGenericInput}
        {...input.inputProps}
      />
    );
  };

  const formContent = columns.map((col: any[]) => {
    return col.map((input: FormColumn) => {
      const isDropDown = input.inputProps.type === 'dropdown';
      if (!isDropDown) {
        return getGenericFormModal(input);
      }
      return getDropdownModal(input);
    });
  });

  return (
    <Dialog
      open={isDialogOpen}
      maxWidth="md"
      fullWidth
      TransitionComponent={Transition}
      TransitionProps={{
        onExited: () => {
          setSubmitTracker(0);
          setEntity(undefined);
          setOriginalEntity(undefined);
          setSelectedDropDownOption('');
          resetValidation();
        },
      }}
    >
      <AppBar position="static">
        <Toolbar className="toolbar">
          <div>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="subtitle1" gutterBottom>
              {subtitle}
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <VpForm
          id={formId}
          item={entity}
          submitTracker={submitTracker}
          onSubmit={(hasErrors: boolean): void => {
            const item = {...originalEntity, ...entity};
            if (hasErrors) {
              return;
            }
            setOriginalEntity(item);
            setIsDialogOpen(false);
            resetValidation();
          }}
          content={formContent}
        />
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={(): void => setIsDialogOpen(false)}>
          Cancel
        </Button>
        {needToRunValidation && !(validation == null) ? (
          <Button
            color="primary"
            onClick={(): void => {
              // run the validation functions
              if (validation.functions?.length > 0) {
                validation.functions.forEach(validationFunction => {
                  validationFunction(entity, {
                    setEntity,
                  });
                });
              }
              setNeedToRunValidation(false);
            }}
          >
            {validation.button.text}
          </Button>
        ) : (
          <Button
            color="primary"
            onClick={(): void => {
              resetValidation();
              setSubmitTracker(submitTracker + 1);
            }}
          >
            Add
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
