import * as React from 'react';
import {
  Fab,
  Chip,
  Checkbox,
  FormControlLabel,
  FormControl,
  TextField,
  MenuItem,
} from '@material-ui/core';
import {
  FeedConfig,
  Study,
  FieldElement,
  DataManagerMethods,
  DropdownOption,
  StudyGroup,
} from '../../core/interfaces';
import {sort} from '../../utils/sort';
import {CreativeBuildSelector} from '../../controllers/studies/creativeBuildSelector.react';
import {CampaignInput} from './campaignInput.react';
import {isStudyGroup} from '../../utils/determineType';

const {Fragment} = React;

const ANON = (): void => {};

export const builder = (
  fields: FieldElement[],
  clone: Study | FeedConfig | StudyGroup,
  methods: DataManagerMethods,
): React.ReactNode => {
  const {
    isFieldValid,
    handleChange,
    addToArray,
    deleteArray,
    handleDeleteChip,
  } = methods;
  return fields.map((field: FieldElement): React.ReactNode => {
    const refMethod = field.ref || ANON;
    switch (field.type) {
      default:
      case 'none':
        return <FormControl key={field.key}></FormControl>;
      case 'creativeSelector':
        return (
          <CreativeBuildSelector
            study={clone as Study}
            campaignId={field.campaignId}
            creatives={field.creatives}
            methods={methods}
            isEditing={field.isEditing}
          />
        );
      case 'pxyz-campaignId':
        // this input should work only for studyGroups
        if (isStudyGroup(clone)) {
          return (
            <CampaignInput
              field={field}
              clone={clone as StudyGroup}
              methods={methods}
            />
          );
        }
        return null;
      case 'text':
        // HACK ALERT!!!:
        // We have an issue with the study and group study components
        // as they are really big and complex each time that an onChange event is fired
        // the all page gets re-render (input form + table). This give as a lot of preformance issues.
        // The good way to fix this issue is to break our main components into small ones (but as usuall we do not have
        // time to do it.)
        // So, we did this quick Hack to fix the issue https://stackoverflow.com/a/33587618/2030999
        // ** Bonus HACK:
        //    as we are using the defaultValue in the input, when we update the value using props
        //    the value does not get updated. So in order to force the update we wrap the input in a react.fragment
        //    with the value of the field as a key. Agggg!
        return (
          <FormControl key={field.key}>
            <React.Fragment key={clone[field.key]}>
              <TextField
                key={`text-field-${field.key}`}
                required={field.required}
                error={!isFieldValid(field.key)}
                InputLabelProps={{shrink: true}}
                inputProps={{
                  name: field.key,
                  id: `item-${field.key}`,
                }}
                multiline={field.multiline === true}
                disabled={field.readOnly || false}
                label={field.label}
                helperText={field.helperText}
                defaultValue={clone[field.key]}
                variant="outlined"
                onBlur={handleChange(field.key)}
                margin="normal"
              />
            </React.Fragment>
          </FormControl>
        );
      case 'checkbox':
        return (
          <FormControlLabel
            key={field.key}
            control={
              <Checkbox
                inputProps={{
                  name: field.key,
                  id: `item-${field.key}`,
                }}
                checked={clone[field.key] === true}
                onChange={handleChange(field.key)}
                onBlur={handleChange(field.key)}
                value={clone[field.key]}
                color="primary"
              />
            }
            label={field.label}
          />
        );
      case 'chip':
        return (
          <Fragment key={field.key}>
            <div className="layout-row layout-align-start-center">
              <FormControl>
                <TextField
                  required={field.required}
                  error={!isFieldValid(field.key)}
                  InputLabelProps={{shrink: true}}
                  inputProps={{
                    name: field.key,
                    id: `item-${field.key}`,
                  }}
                  multiline={field.multiline === true}
                  label={field.label}
                  helperText={field.helperText}
                  variant="outlined"
                  margin="normal"
                  inputRef={(ref: React.RefObject<HTMLInputElement>): void => {
                    refMethod(ref);
                    field.refElement = ref;
                  }}
                />
              </FormControl>
              <Fab
                style={{
                  flexGrow: 0,
                  flexShrink: 0,
                  marginTop: -10,
                  marginLeft: 20,
                }}
                size="small"
                color="primary"
                onClick={(): void => {
                  addToArray(field.key, field.refElement);
                }}
              >
                +
              </Fab>
            </div>
            <div className="chipWrapper">
              {sort(clone[field.key]).map(item => {
                return (
                  <Chip
                    key={item}
                    label={item}
                    variant="outlined"
                    onDelete={(): void => {
                      handleDeleteChip(field.key, item);
                    }}
                  />
                );
              })}
              {clone[field.key].length > 0 && (
                <Chip
                  style={{
                    backgroundColor: 'transparent',
                    color: 'red',
                    marginTop: '4px',
                    borderColor: 'red',
                  }}
                  key={field.key}
                  label="CLEAR ALL"
                  variant="outlined"
                  color="secondary"
                  clickable={true}
                  onClick={(): void => {
                    deleteArray(field.key);
                  }}
                />
              )}
            </div>
          </Fragment>
        );
      case 'select':
        return (
          <FormControl key={field.key}>
            <TextField
              select
              key={`select-${field.key}`}
              disabled={field.isDisabled ? field.isDisabled(clone) : false}
              required={field.required}
              error={!isFieldValid(field.key)}
              InputLabelProps={{shrink: true}}
              inputProps={{
                name: field.key,
                id: `item-${field.key}`,
              }}
              label={field.label}
              helperText={field.helperText}
              value={clone[field.key] || ''}
              variant="outlined"
              onChange={handleChange(field.key, field.onChangeEvent)}
              onBlur={handleChange(field.key)}
              margin="normal"
            >
              {(field.options || []).map(
                (option: DropdownOption): React.ReactNode => {
                  return (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  );
                },
              )}
            </TextField>
          </FormControl>
        );
      case 'number':
        return (
          <FormControl key={field.key}>
            <TextField
              type="number"
              required={field.required}
              error={!isFieldValid(field.key)}
              InputLabelProps={{shrink: true}}
              inputProps={{
                name: field.key,
                id: `item-${field.key}`,
                min: field.min || 0,
                max: field.max || Infinity,
                step: field.step || 1,
              }}
              label={field.label}
              helperText={field.helperText}
              defaultValue={String(clone[field.key]) || ''}
              variant="outlined"
              onBlur={handleChange(field.key)}
              margin="normal"
            />
          </FormControl>
        );
    }
  });
};
