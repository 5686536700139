/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useReducer,
  useState,
} from 'react';
import {Grid, TextField, Button, Paper, MenuItem, Box} from '@material-ui/core';
import {Theme, desktopWebsiteUrl} from '../../../config';
import {errorReducer} from './errorReducer';
import {
  DesktopUrlErrorState,
  DeviceType,
  DifficultyType,
  Platform,
  SnackBarStateParams,
  SnackbarType,
  Status,
  StudyGroupType,
  StudyType,
} from '../../core/interfaces';
import {DURATION_CODES, F_TYPES} from '../../core/constants';
import {Firestore} from '../../components/dataManager/firestore';
import {vpApiInstance} from '../../components/dataManager/vpApiInstance';
import {SnackBarAlert} from '../../components/alerts/snackBarAlert';
import {generateCompletionCode} from '../../utils/completionCode';
import {validateFrom} from './validateForm';

interface ButtonText {
  original: string;
  actioned: string;
}
const firestore = new Firestore();
const STUDY_APP_TYPE = 'desktop';

const getStudyUrl = (id: string): string => {
  return `${desktopWebsiteUrl}?studyId=${id}`;
};

export const DesktopUrlGenerator: React.FunctionComponent<{}> = () => {
  const initialErrorsState: DesktopUrlErrorState = {
    errors: {
      name: {
        error: false,
        helperText: '',
      },
      platformId: {
        error: false,
        helperText: '',
      },
      d: {
        error: false,
        helperText: '',
      },
      feedId: {
        error: false,
        helperText: '',
      },
      surveyUrl: {
        error: false,
        helperText: '',
      },
      adCampaignId: {
        error: false,
        helperText: '',
      },
    },
  };

  const [studyId, setStudyId] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [platformId, setPlatformId] = useState<string | null>(null);
  const [d, setD] = useState<number | null>(null);
  const [feedId, setFeedId] = useState<string | null>(null);
  const [surveyUrl, setSurveyUrl] = useState<string | null>(null);
  const [fcType, setFcType] = useState<string | null>(null);
  const [adCampaignId, setAdCampaignId] = useState<string | null>(null);
  const [adCampaigns, setAdCampaigns] = useState<any[]>([]);
  const [feeds, setFeeds] = useState<any[]>([]);
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [completionCode, setCompletionCode] = useState<string | null>(null);
  const [snackBarState, setSnackBarState] = useState<SnackBarStateParams>({
    isSnackBarOpen: false,
    snackBarContent: '',
    snackBarType: SnackbarType.success,
  });

  const [errorsState, dispatchError] = useReducer(
    errorReducer,
    initialErrorsState,
  );

  const getPlatforms = async (): Promise<void> => {
    try {
      const platformResponse = await firestore.getList('platforms');
      if (!platformResponse.success) {
        throw new Error(platformResponse.error);
      }

      const result = platformResponse.data.sort((a: Platform, b: Platform) =>
        a.name.localeCompare(b.name),
      );
      setPlatforms(result);
    } catch (e) {
      console.error(`Error retrieving platform list: ${e}`);
    }
  };

  useEffect(() => {
    void getPlatforms();
    firestore
      .getList('adCampaigns', false)
      .then((res: any) => {
        const data = (res.data || []).reduce((acc: any, d: any) => {
          if (!d.archived && d.campaignType === STUDY_APP_TYPE) {
            acc.push({
              id: d.id,
              name: d.name,
            });
          }
          return acc;
        }, []);
        data.unshift({id: '', name: 'None'});
        setAdCampaigns(data);
      })
      .catch(err => {
        console.error(err);
      });

    firestore
      .getList('feeds', false)
      .then((res: any) => {
        const data = (res.data || []).reduce((acc: any, d: any) => {
          if (!d.archived && d.feedType === STUDY_APP_TYPE) {
            acc.push({
              id: d.id,
              name: d.name,
            });
          }
          return acc;
        }, []);
        setFeeds(data);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  const text: ButtonText = {
    original: 'Copy Url',
    actioned: 'URL Copied',
  };
  const [title, setTitle] = useState<string>(text.original);

  const copyUrlButton = (url: string): JSX.Element => {
    return (
      <Button
        color="primary"
        autoFocus
        onClick={(): void => {
          void navigator.clipboard.writeText(url);
          // update button text
          setTitle(text.actioned);
          setTimeout(() => {
            setTitle(text.original);
          }, 3000);
        }}
      >
        {title}
      </Button>
    );
  };

  const removeError = (error: {id: string}): void => {
    dispatchError({type: 'REMOVE_ERROR', error});
  };

  const addError = (error: {id: string; helperText: string}): void => {
    dispatchError({type: 'ADD_ERROR', error});
  };

  const onChangeHandler = (
    state: DesktopUrlErrorState,
    setStateAction?: Dispatch<SetStateAction<string | number | null>>,
  ): ((e: any) => void) => {
    return (e: any): void => {
      if (setStateAction != null) {
        setStateAction(e.target.value);
      }
      if (state.errors[e.target.name].error) {
        removeError({id: e.target.name});
      }
    };
  };

  const createStudy = async (): Promise<void> => {
    let hasErrors = false;
    hasErrors = validateFrom(name, platformId, d, surveyUrl, feedId, addError);
    if (hasErrors) {
      return;
    }

    const vpApi = await vpApiInstance();
    if (!vpApi) {
      setSnackBarState({
        isSnackBarOpen: true,
        snackBarContent: 'Unable to connect to vp-api',
        snackBarType: SnackbarType.error,
      });
      return;
    }

    // create the study obejct that will be sent to the api
    const studyParams: any = {
      name,
      feedId,
      platformId,
      duration: d,
      surveyUrl,
      studyType: StudyType.DISPLAY,
      status: Status.LIVE,
      difficulty: DifficultyType.HARD,
      brand: StudyGroupType.DATA_COLLECTION,
      deviceType: DeviceType.DESKTOP,
      conversionUrl: '', // TODO: this at the moment is empty, we will add it in a separ
    };

    if (adCampaignId) {
      studyParams.adCampaignId = adCampaignId;
    }
    if (fcType) {
      studyParams.fcType = fcType;
    }

    try {
      // save the study in the db
      const response = await vpApi.saveStudy('study', studyParams);

      if (response.success) {
        setStudyId(response.id);
        // generate the completion code here
        generateCompletionCode(
          {
            feedId: feedId ?? '',
            duration: d ?? 0,
          },
          setCompletionCode,
        );
        return;
      }

      setSnackBarState({
        isSnackBarOpen: true,
        snackBarContent: response.errors
          .map((item: any) => {
            return item.message || 'An error occurred';
          })
          .join(','),
        snackBarType: SnackbarType.error,
      });
    } catch (error) {
      console.error('error saving study', error);
      setSnackBarState({
        isSnackBarOpen: true,
        snackBarContent: 'An error occurred',
        snackBarType: SnackbarType.error,
      });
    }
  };

  return (
    <React.Fragment>
      <Theme>
        <div>
          <div className="layout-row layout-align-space-between-center">
            <h1>Desktop URL Generator</h1>
          </div>
          <Paper className="paper">
            <p>
              Each field represents one parameter. Between square brackets is
              the name of the parameter used in the query string.
            </p>
            <Grid container spacing={2}>
              <Grid
                key={'fields'}
                item
                xs={6}
                className="layout-column layout-align-start-center layout-fill children-fill-width"
              >
                <h3>Mandatory </h3>
                <TextField
                  label={'name'}
                  variant="outlined"
                  margin="normal"
                  required
                  value={name ?? ''}
                  error={errorsState.errors.name.error}
                  helperText={errorsState.errors.name.helperText}
                  onChange={onChangeHandler(errorsState, setName)}
                  name="name"
                />
                <TextField
                  select
                  label={'feed id'}
                  variant="outlined"
                  margin="normal"
                  required
                  value={feedId ?? ''}
                  error={errorsState.errors.feedId.error}
                  helperText={errorsState.errors.feedId.helperText || ''}
                  onChange={onChangeHandler(errorsState, setFeedId)}
                  name="feedId"
                >
                  {feeds.map((option: any): React.ReactNode => {
                    return (
                      <MenuItem key={option.id} value={option.id}>
                        {`${option.name}`}
                      </MenuItem>
                    );
                  })}
                </TextField>
                <TextField
                  select
                  label={'platform id'}
                  variant="outlined"
                  margin="normal"
                  required
                  value={platformId ?? ''}
                  error={errorsState.errors.platformId.error}
                  helperText={errorsState.errors.platformId.helperText}
                  onChange={(e: any) => {
                    setPlatformId(e.target.value);
                    if (errorsState.errors[e.target.name].error) {
                      removeError({id: e.target.name});
                    }
                  }}
                  name="platformId"
                >
                  {platforms.map((option: Platform): React.ReactNode => {
                    return (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
                <TextField
                  select
                  label={'duration'}
                  variant="outlined"
                  margin="normal"
                  required
                  value={d ?? ''}
                  error={errorsState.errors.d.error}
                  helperText={errorsState.errors.d.helperText || ''}
                  onChange={onChangeHandler(errorsState, setD)}
                  name="d"
                >
                  {DURATION_CODES.map((option: any): React.ReactNode => {
                    return (
                      <MenuItem key={option.description} value={option.value}>
                        {`${option.description} (${option.name})`}
                      </MenuItem>
                    );
                  })}
                </TextField>
                <TextField
                  label={'survey url'}
                  variant="outlined"
                  margin="normal"
                  defaultValue=""
                  error={errorsState.errors.surveyUrl.error}
                  helperText={errorsState.errors.surveyUrl.helperText || ''}
                  onBlur={(e: any): void => {
                    setSurveyUrl(e.target.value || '');
                  }}
                  onChange={onChangeHandler(errorsState)}
                  name="surveyUrl"
                ></TextField>
              </Grid>
              <Grid
                key={'fields-opt'}
                item
                xs={6}
                className="layout-column layout-align-start-center layout-fill children-fill-width"
              >
                <h3>Optionals</h3>
                <TextField
                  select
                  label={'Ad Campaign id [ad_camp]'}
                  variant="outlined"
                  margin="normal"
                  value={adCampaignId ?? ''}
                  defaultValue=""
                  onChange={e => {
                    setAdCampaignId(e.target.value);
                  }}
                  name="adCampaignId"
                >
                  {adCampaigns.map((option: any): React.ReactNode => {
                    return (
                      <MenuItem key={option.id} value={option.id}>
                        {`${option.name}`}
                      </MenuItem>
                    );
                  })}
                </TextField>
                <TextField
                  select
                  label={'feed type [f_type]'}
                  variant="outlined"
                  margin="normal"
                  value={fcType ?? ''}
                  name="fType"
                  onChange={e => {
                    setFcType(e.target.value);
                  }}
                >
                  {F_TYPES.map((option: any): React.ReactNode => {
                    return (
                      <MenuItem key={option.value} value={option.value}>
                        {`${option.value} (${option.name})`}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid
                key={'fields-opt'}
                item
                xs={12}
                className="layout-column layout-align-start-center layout-fill children-fill-width"
              >
                <Button
                  color="primary"
                  variant="text"
                  onClick={(): void => {
                    void createStudy();
                  }}
                >
                  Generate Desktop Url
                </Button>
              </Grid>
            </Grid>
          </Paper>
          {studyId ? (
            <Box marginTop={4}>
              <Paper className="paper">
                <Grid container spacing={2}>
                  <Grid
                    key={'url'}
                    item
                    xs={12}
                    className="layout-column layout-align-start-center layout-fill children-fill-width"
                  >
                    <TextField
                      InputLabelProps={{shrink: true}}
                      label="Desktop Study URL"
                      helperText="This field isn't editable"
                      value={getStudyUrl(studyId)}
                      variant="outlined"
                      disabled
                      margin="normal"
                      inputProps={{style: {textAlign: 'center'}}}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid
                    key={'copy-url'}
                    item
                    xs={12}
                    className="layout-column layout-align-start-center layout-fill children-fill-width"
                  >
                    {copyUrlButton(getStudyUrl(studyId))}
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          ) : null}
          {completionCode ? (
            <Box marginTop={4}>
              <Paper className="paper">
                <Grid container spacing={2}>
                  <Grid
                    key={'url'}
                    item
                    xs={12}
                    className="layout-column layout-align-start-center layout-fill children-fill-width"
                  >
                    <TextField
                      InputLabelProps={{shrink: true}}
                      label="Unique Code"
                      value={completionCode}
                      variant="outlined"
                      disabled
                      margin="normal"
                      inputProps={{style: {textAlign: 'center'}}}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          ) : null}
        </div>
        <SnackBarAlert
          onClose={(): void =>
            setSnackBarState({...snackBarState, isSnackBarOpen: false})
          }
          open={snackBarState.isSnackBarOpen}
          contentProps={{
            content: snackBarState.snackBarContent,
            className: snackBarState.snackBarType,
          }}
        />
      </Theme>
    </React.Fragment>
  );
};
