import React from "react";
import { RowInfo, Column } from "react-table";

const formatCell = (value: string | number, type: string): string => {
  switch (type) {
    case "number":
      return value ? `${value}` : `0`;
    case "percentage":
      return value ? `${value}%` : "--";
    case "string":
    default:
      return value ? `${value}` : `--`;
  }
};

export const studyColumns: Column[] = [
  {
    Header: "Study Completion (App)",
    accessor: "completion",
    Cell: ({ original }: RowInfo): string =>
      formatCell(original.completion, "number"),
  },
  {
    Header: "Study Completion (SM)",
    accessor: "smCompletion",
    style: { whiteSpace: "unset" },
    Cell: ({ original }: RowInfo): string =>
      formatCell(original.smCompletion, "number"),
  },
  {
    Header: (): JSX.Element => (
      <span title="Total Creative Impressions (excludes fallback)">
        Total Imps
      </span>
    ),
    style: { whiteSpace: "unset" },
    accessor: "totalImps",
    Cell: ({ original }: RowInfo): string =>
      formatCell(original.totalImps, "number"),
  },
  {
    Header: (): JSX.Element => (
      <span title="Unique Creative Impressions (excludes fallback but includes completes AND non-completes)">
        Unique Creative Imps
      </span>
    ),
    style: { whiteSpace: "unset" },
    accessor: "uniqueImpsPerStudy",
    Cell: ({ original }: RowInfo): string =>
      formatCell(original.uniqueImpsPerStudy, "number"),
  },
  {
    Header: (): JSX.Element => (
      <span title="Unique Creative Impressions (for Survey Monkey Completes)">
        Unique SM Creative Imps
      </span>
    ),
    accessor: "uniqueSmCompletedImpsPerStudy",
    Cell: ({ original }: RowInfo): string =>
      formatCell(original.uniqueSmCompletedImpsPerStudy, "number"),
  },
  {
    Header: "Median MAE - Overall",
    accessor: "meanMae",
    Cell: ({ original }: RowInfo): string =>
      formatCell(original.meanMae, "string"),
  },
  {
    Header: "Median MAE - Pass Rate",
    accessor: "meanPass",
    Cell: ({ original }: RowInfo): string =>
      formatCell(original.meanPass, "string"),
  },
  {
    Header: "Median MAE - Fail Rate",
    accessor: "meanFail",
    Cell: ({ original }: RowInfo): string =>
      formatCell(original.meanFail, "string"),
  },
  {
    Header: "Pass Rate",
    accessor: "ratePass",
    Cell: ({ original }: RowInfo): string =>
      formatCell(original.ratePass, "percentage"),
  },
  {
    Header: "Fail Rate",
    accessor: "rateFail",
    Cell: ({ original }: RowInfo): string =>
      formatCell(original.rateFail, "percentage"),
  },
];

export const creativesColumns: Column[] = [
  {
    Header: "Creative",
    accessor: "name",
  },
  {
    Header: "Ad Format",
    accessor: "format",
  },
  {
    Header: (): JSX.Element => (
      <span title="Creative Imps (excludes fallback)">Creative Imps</span>
    ),
    accessor: "totalImps",
    Cell: ({ original }: RowInfo): string =>
      formatCell(original.totalImps, "number"),
  },
  {
    Header: (): JSX.Element => (
      <span title="Unique Creative Impressions (excludes fallback but includes completes AND non-completes)">
        Unique Creative Imps
      </span>
    ),
    accessor: "uniqueImpsPerCreative",
    Cell: ({ original }: RowInfo): string =>
      formatCell(original.uniqueImpsPerCreative, "number"),
  },
  {
    Header: (): JSX.Element => (
      <span title="Unique Creative Impressions (mapped back to SM Complete)">
        Unique SM Creative Imps
      </span>
    ),
    accessor: "uniqueSmCompletedImpsPerCreative",
    Cell: ({ original }: RowInfo): string =>
      formatCell(original.uniqueSmCompletedImpsPerCreative, "number"),
  },
  {
    Header: "App Attention Time",
    accessor: "totalFixation",
    Cell: ({ original }: RowInfo): string => {
      // this value is in millisecond, we need to convert it in seconds
      const totalFixationSec = original.totalFixation
        ? Math.round(original.totalFixation / 1000).toFixed(2)
        : 0;
      return formatCell(totalFixationSec, "string");
    },
  },
  {
    Header: "Modelling Attention",
    accessor: "modelledAttention",
    Cell: ({ original }: RowInfo): string =>
      formatCell(original.modelledAttention, "string"),
  },
  {
    Header: "Benchmark",
    accessor: "time",
    Cell: ({ original }: RowInfo): string =>
      formatCell(original.time, "string"),
  },
  {
    Header: "Variance",
    Cell: ({ original }: RowInfo): string => {
      // Modelled AT/Benchmark
      let varianceString = "";
      if (original && original.modelledAttention && original.time) {
        const variance: number = original.modelledAttention / original.time;
        varianceString = `${(Math.round(variance * 100) / 100).toFixed(2)}`;
      }
      return formatCell(varianceString, "percentage");
    },
  },
];
