import * as React from 'react';
import {Grid, Typography, Tooltip} from '@material-ui/core';
import {Column, CellInfo} from 'react-table';
import {Language, Assignment, CheckCircle} from '@material-ui/icons';
import {DataManager} from '../../components/dataManager/dataManager.react';
import {Loader} from '../../components/loader/loader.react';
import {builder} from '../../components/formBuilder/formBuilder.react';
import styles from './studyGroups.scss';
import {
  Difficulty,
  Status,
  RouteProps,
  StudyGroup,
  DataManagerMethods,
  iValidity,
  StudyGroupType,
  StudyGroupReportItem,
  StudyGroupDashboardItem,
  CreativeStudyDashBoard,
  CustomFeedState,
  DropdownOption,
  FeedConfig,
} from '../../core/interfaces';
import {getIdToken} from '../../utils/idToken';
import {studyGroupDataTransformer} from '../../utils/studyGroupTransformer';
import {extractStudyIds} from '../../utils/extractStudyIds';
import VpApi from '../../components/dataManager/vpApi';
import {Firestore} from '../../components/dataManager/firestore';

interface iState {
  isLoading: boolean;
  idToken: string | null;
}
const {Fragment} = React;
const firestore = new Firestore();

const tableTitles = {
  totalImps: 'Unique Survey Monkey Completed Imps / Total Imps',
};

function caseInsensitiveSearch(value: string, target: string): boolean {
  return value && target
    ? target.toLowerCase().includes(value.toLowerCase())
    : false;
}

function caseInsensitiveSort(a: string, b: string): number {
  // push the deleted ones at the bottom,
  // this is a bit hacky but not sure why the 'name' (such as feedConfigName or platformName) is set to DELETED
  // and I don't really want to change that logic.
  // On the UI a red crossed circle is displayed for this type of items
  if (a === 'DELETED') {
    return 1;
  }
  if (b === 'DELETED') {
    return -1;
  }

  return a.toLowerCase().localeCompare(b.toLowerCase());
}
export class StudyGroups extends React.Component<RouteProps, iState> {
  private columns: Column[];
  private feeds: CustomFeedState[] | FeedConfig[];
  private customFeeds: CustomFeedState[];
  private feedConfigs: FeedConfig[];

  constructor(props: any) {
    super(props);
    this.feeds = [];
    this.customFeeds = [];
    this.state = {
      isLoading: true,
      idToken: null,
    };
    // columns for table, action column added automatically
    // the Archived and Actions columns are added in data-manager
    this.columns = [
      {
        Header: 'Brand',
        id: 'brand',
        accessor: 'brand',
      },
      {
        Header: 'Study Group',
        id: 'name',
        accessor: 'name',
      },
      {
        Header: 'Study',
        id: 'studyName',
        accessor: 'studyName',
      },
      {
        Header: 'Status',
        accessor: 'status', // Required because our accessor is not a string
        Cell: (row: CellInfo): React.ReactNode => {
          let icon = <Fragment></Fragment>;
          switch (row.original.status) {
            default:
            case 'demo':
              icon = (
                <Fragment>
                  Demo
                  <Language />
                </Fragment>
              );
              break;
            case 'draft':
              icon = (
                <Fragment>
                  Draft
                  <Assignment />
                </Fragment>
              );
              break;
            case 'live':
              icon = (
                <Fragment>
                  Live
                  <Language />
                </Fragment>
              );
              break;
            case 'finished':
              icon = (
                <Fragment>
                  Finished
                  <CheckCircle />
                </Fragment>
              );
              break;
          }
          return (
            <Typography
              className={`layout-row layout-align-center-center layout-fill is-${row.original.status} ${styles.status}`}
            >
              {icon}
            </Typography>
          );
        },
        id: 'status',
        filterMethod: (
          filter: {value: any; id: React.ReactText},
          row: {[x: string]: any},
        ): boolean => {
          switch (filter.value) {
            default:
            case 'all':
              return true;
            case Status.DEMO:
            case Status.DRAFT:
            case Status.LIVE:
            case Status.FINISHED:
              return row[filter.id] === filter.value;
          }
        },
        Filter: ({filter, onChange}): JSX.Element => (
          <select
            onChange={(event: React.ChangeEvent<HTMLSelectElement>): void =>
              onChange(event.target.value)
            }
            style={{width: '100%'}}
            value={filter ? filter.value : 'all'}
          >
            <option value="all">Show All</option>
            <option value={Status.DEMO}>Demo</option>
            <option value={Status.DRAFT}>Draft</option>
            <option value={Status.LIVE}>Live</option>
            <option value={Status.FINISHED}>Finished</option>
          </select>
        ),
      },
      {
        Header: 'Ad Formats',
        id: 'adFormats',
        accessor: 'adFormats',
        filterable: false,
        sortable: false,
        Cell: (row: CellInfo): React.ReactNode => {
          const renderFormatIcon = (item: CreativeStudyDashBoard) => {
            const thumbnail = item.thumbnail ? (
              <img
                className="creative-thumbnail-tooltip"
                src={item.thumbnail}
              />
            ) : (
              ''
            );
            return (
              <Tooltip
                key={`tooltip-${item.id}`}
                title={thumbnail}
                placement={'bottom-end'}
                enterDelay={300}
              >
                <div
                  className={`format-icon format-icon-vertical-align ${item.format}`}
                ></div>
              </Tooltip>
            );
          };

          if (row.original.creatives && row.original.creatives.length) {
            // extract the creatives we need (one per format, max 3 overall)
            let creatives: CreativeStudyDashBoard[] = [];
            row.original.creatives.forEach(
              (currentCreative: any, i: number) => {
                if (i === 0) {
                  creatives.push(currentCreative);
                } else {
                  const hasFormat = creatives.some(
                    (item: any) => item.format === currentCreative.format,
                  );
                  if (!hasFormat) {
                    creatives.push(currentCreative);
                  }
                }
              },
            );
            creatives = creatives.slice(0, 3);

            return (
              <Fragment>
                {creatives.map(item => {
                  return renderFormatIcon(item);
                })}
              </Fragment>
            );
          }
          return <div></div>;
        },
      },
      // TODO: Update for getStudyGroupReportapi is updated
      // {
      //   Header: "App Completes",
      //   id: "completes",
      //   accessor: "studyCompletion",
      //   filterable: false,
      //   sortable: false,
      // },
      // {
      //   Header: "SM Completes",
      //   id: "smCompletes",
      //   accessor: "smStudyCompletion",
      //   filterable: false,
      //   sortable: false,
      // },
      // {
      //   Header: () => {
      //     return <span title={tableTitles.totalImps}>Creative Imps</span>;
      //   },
      //   id: "creativeImps",
      //   accessor: "totalImps",
      //   filterable: false,
      //   sortable: false,
      //   Cell: (item) => {
      //     return <span title={tableTitles.totalImps}>{item.value}</span>;
      //   },
      // },
    ];
  }

  async componentDidMount(): Promise<void> {
    // get the token
    const idToken = await getIdToken();
    this.setState({
      isLoading: false,
      idToken,
    });

    try {
      const feedResponse = await firestore.getList('feeds');
      if (!feedResponse.success) {
        throw new Error(feedResponse.error);
      }
      this.customFeeds = feedResponse.data;
    } catch (e) {
      throw new Error(`Error retrieving feeds`);
    }

    try {
      const feedConfigsResponse = await firestore.getList('feedConfigs');
      if (!feedConfigsResponse.success) {
        throw new Error(feedConfigsResponse.error);
      }
      this.feedConfigs = feedConfigsResponse.data;
    } catch (e) {
      throw new Error(`Error retrieving feed config`);
    }
  }

  /**
   * @function
   * @name checkValidity
   * @description - Updates the UI to show the field in an error state when the field is invalid
   * Note: This function will only run if the default html5 validation passes
   * @param { iValidity } - validatorObject - Contains all information relating to the field, and how we can validate it
   * @returns { boolean } - if the field is valid or not
   */
  checkValidity(validatorObject: iValidity): boolean {
    const {value, field} = validatorObject;
    let isValid = true;
    switch (field) {
      case 'name':
        isValid = String(value).length > 0;
        break;
      case 'duration':
        isValid =
          !isNaN(parseFloat(String(value))) &&
          typeof parseFloat(String(value)) === 'number';
        break;
      default:
        isValid = true;
        break;
    }
    return isValid;
  }

  setFeed(type: string | undefined): void {
    switch (type) {
      case StudyGroupType.BRAND_STUDY:
        this.feeds = this.feedConfigs;
        break;

      case StudyGroupType.DATA_COLLECTION:
        this.feeds = this.customFeeds;
        break;
      default:
        this.feeds = [];
        break;
    }
  }

  /**
   * @function
   * @name getPanelContent
   * @description - The jsx template to render in the sidebar panel
   * @param { StudyGroup } - clone - the current field clone object
   * @param { DataManagerMethods } - methods - the methods available to use on the fields
   * @returns { React.ReactNode }
   */
  getPanelContent(
    clone: StudyGroup,
    methods: DataManagerMethods,
  ): React.ReactNode {
    this.setFeed(clone.type);

    return (
      <Grid
        container
        spacing={2}
        className="layout-row layout-align-space-between-center"
      >
        <Grid
          item
          xs={6}
          className="layout-column layout-align-start-center layout-fill children-fill-width"
        >
          {builder(
            [
              {
                required: true,
                key: 'type',
                label: 'Study Group Type',
                isDisabled: methods.isEditing,
                helperText: 'The mode for the application calibration.',
                onChangeEvent: event => {
                  return {
                    studyGroup: {
                      pxyzCampaignId: '',
                      brand: '',
                      feedId: '',
                    },
                    studyGroupTypeEvent: true,
                  };
                },
                type: 'select',
                options: [
                  {
                    value: StudyGroupType.BRAND_STUDY,
                    label: StudyGroupType.BRAND_STUDY,
                  },
                  {
                    value: StudyGroupType.DATA_COLLECTION,
                    label: StudyGroupType.DATA_COLLECTION,
                  },
                ],
              },
              {
                required: true,
                key: 'name',
                label: 'Name',
                helperText: 'The name of this study group.',
                type: 'text',
              },
              {
                required: true,
                key: 'pxyzCampaignId',
                label: 'PXYZ Campaign Id',
                helperText:
                  'The ID of the corresponding campaign in The Playground',
                type:
                  clone.type === StudyGroupType.BRAND_STUDY
                    ? 'pxyz-campaignId'
                    : 'none',
              },
              {
                required: true,
                readOnly: true,
                key: 'brand',
                label: 'Brand',
                helperText: 'The brand for this study.',
                type:
                  clone.type === StudyGroupType.BRAND_STUDY ? 'text' : 'none',
              },
              {
                required: true,
                key: 'feedId',
                label: 'Feed',
                helperText: 'Select the feed from the dropdown menu.',
                type: 'select',
                options: this.feeds.map(
                  (feed: CustomFeedState | FeedConfig): DropdownOption => ({
                    value: feed.id || '',
                    label: feed.name || '',
                  }),
                ),
              },
            ],
            clone,
            methods,
          )}
        </Grid>
        <Grid
          item
          xs={6}
          className="layout-column layout-align-start-center layout-fill children-fill-width"
        >
          {builder(
            [
              {
                required: true,
                key: 'difficulty',
                label: 'Difficulty',
                helperText: 'The mode for the application calibration.',
                type: 'select',
                options: [
                  {
                    value: Difficulty.easy,
                    label: Difficulty.easy,
                  },
                  {
                    value: Difficulty.hard,
                    label: Difficulty.hard,
                  },
                ],
              },
              {
                required: true,
                key: 'duration',
                label: 'Task Duration',
                helperText:
                  'This duration represents how long the task is in the app.',
                type: 'number',
                min: 1,
              },
            ],
            clone,
            methods,
          )}
        </Grid>
      </Grid>
    );
  }

  /**
   * @function
   * @name render
   * @description - standard react render method
   * @returns {React.ReactNode} - returns jsx markup
   */
  render(): React.ReactNode {
    const defaultItem: StudyGroup = {
      pxyzCampaignId: '',
      brand: '',
      name: '',
      difficulty: 'hard',
      duration: 10,
      studies: [],
    };
    const props = {
      module: 'study-groups',
      listName: 'studyGroups',
      name: 'Study Group',
      plural: `Study Groups`,
      idToken: this.state.idToken,
      match: this.props.match,
      history: this.props.history,
      columns: this.columns,
      defaultFilterMethod: caseInsensitiveSearch,
      defaultSortMethod: caseInsensitiveSort,
      checkValidity: this.checkValidity.bind(this),
      defaultItem,
      getPanelContent: this.getPanelContent.bind(this),
      getExtraData: null,
      // TODO: Update api with POST request
      // getExtraData: async (data: any): Promise<any> => {
      //   if (data && data.length && this.state.idToken) {
      //     const vpApi = new VpApi(this.state.idToken);
      //     const studyIds = extractStudyIds(data);
      //     if (studyIds) {
      //       const extra = await vpApi.getStudyGroupReport(studyIds);
      //       return extra;
      //     }
      //     // no studies
      //     return {
      //       success: true,
      //       data,
      //     };
      //   }
      //   return null;
      // },
      transfromDataBeforeRender: (
        data: any,
        showArchived: boolean,
        extra?: StudyGroupReportItem[],
      ): StudyGroupDashboardItem[] => {
        const newKeys = {
          id: 'studyId',
          name: 'studyName',
          archived: 'studyGroupArchived',
        };
        return studyGroupDataTransformer(
          data,
          newKeys,
          {
            showArchived,
            caller: 'studyGroup',
          },
          extra,
        );
      },
      attachRowActionButton: (clone: StudyGroup): React.ReactNode => {
        return <div className="empty-action"></div>;
      },
    };

    return this.state.isLoading ? (
      <Loader />
    ) : (
      <DataManager id={styles.studies} {...props}></DataManager>
    );
  }
}
