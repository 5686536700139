import React from "react";
import SlidingPane from "react-sliding-pane";
import { CustomAction } from "../../core/interfaces";
import { SlidingPanelNavigation } from "./slidingPanelNavigation";

interface SlidingPanelProps {
  isOpen: boolean;
  onRequestClose: () => void;
  content: React.ReactNode;
  navigation: {
    onSave: () => Promise<void>;
    onArchive: () => Promise<void>;
    isArchived: boolean;
    customActions?: CustomAction[];
  };
}

export const SlidingPanel: React.FunctionComponent<SlidingPanelProps> = ({
  isOpen,
  onRequestClose,
  content,
  navigation,
}: SlidingPanelProps) => {
  return (
    <SlidingPane isOpen={isOpen} onRequestClose={onRequestClose}>
      {content}
      <SlidingPanelNavigation
        onSave={navigation.onSave}
        onArchive={navigation.onArchive}
        isArchived={navigation.isArchived}
        customActions={navigation.customActions}
      />
    </SlidingPane>
  );
};
