import { FormControlLabel, Checkbox } from "@material-ui/core";
import CSS from "csstype";
import _ from "lodash";
import React from "react";
import ReactTable, { Column } from "react-table";

interface TableProps {
  columns: Column<unknown>[];
  isLoading: boolean;
  data: any;
  params?: {
    overflowAndScroll?: boolean;
    style?: CSS.Properties;
    archive?: {
      showArchived: boolean;
      onClick: () => void;
    };
  };
}

export const TableView: React.FunctionComponent<TableProps> = ({
  columns,
  isLoading,
  data,
  params,
}) => {
  let style: CSS.Properties = (params && params.style) || {};
  if (_.has(params, "overflowAndScroll")) {
    style = {
      ...style,
      height: "calc(100vh - 200px)",
    };
  }

  const archive = _.get(params, "archive", null);
  return (
    <React.Fragment>
      {archive ? (
        <div className="layout-row layout-align-space-between-center">
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{
                  name: "show-archived",
                  id: `item-show-archived`,
                }}
                checked={archive.showArchived}
                onChange={archive.onClick}
                value={archive.showArchived}
                color="primary"
              />
            }
            label={`Show Archived`}
          />
        </div>
      ) : null}
      <ReactTable
        filterable
        className="-striped -highlight"
        style={style}
        showPagination={false}
        showPageSizeOptions={false}
        minRows={0}
        columns={columns}
        data={data}
        loading={isLoading}
      />
    </React.Fragment>
  );
};
