import { Tooltip, Fab } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React from "react";

interface HeaderProps {
  title: string;
  toolTip?: {
    onClick: () => void;
  };
}

export const Header: React.FunctionComponent<HeaderProps> = ({
  title,
  toolTip,
}) => {
  return (
    <div className="layout-row layout-align-space-between-center">
      <h1>{title}</h1>
      {toolTip ? (
        <div className={`layout-row layout-align-end-center`}>
          <Tooltip title="Create" placement="left">
            <Fab color="secondary" size="small" onClick={toolTip.onClick}>
              <Add />
            </Fab>
          </Tooltip>
        </div>
      ) : null}
    </div>
  );
};
