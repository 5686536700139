import { useEffect, useState } from "react";
import { Entity } from "../interfaces";
import { vpApiInstance } from "../../components/dataManager/vpApiInstance";

interface LoadEntityHookParams<T> {
  showArchived: boolean;
  path: string;
  currentItemId: string | undefined;
  setIsPaneOpen: (isOpen: boolean) => void;
  initialEntityState: T;
}

interface LoadEntityHook<T extends Entity> {
  currentEntity: T;
  setCurrentEntity: any;
  list: T[];
  isLoading: boolean;
}

export const loadEntityHook = <T extends Entity>(
  params: LoadEntityHookParams<T>
): LoadEntityHook<T> => {
  const {
    showArchived,
    path,
    currentItemId,
    setIsPaneOpen,
    initialEntityState,
  } = params;

  const [list, setList] = useState<T[]>([]);
  const [currentEntity, setCurrentEntity] = useState<T>(initialEntityState);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const loadEntity = async (): Promise<void> => {
      setIsLoading(true);
      try {
        const vpApi = await vpApiInstance();
        if (!vpApi) {
          return;
        }
        const response = await vpApi.getEntity<T>(path, { showArchived });
        if (response.success && response.data) {
          setList(response.data);
          // if we have a currentItemId, open the panel and load it
          if (currentItemId) {
            const item = response.data.find((i: any) => i.id === currentItemId);
            if (item) {
              setCurrentEntity(item);
              setIsPaneOpen(true);
            }
          }
        }
        setIsLoading(false);
      } catch (error) {
        console.error("error loading Custom Feed", error);
        setIsLoading(false);
      }
    };
    loadEntity();
  }, [showArchived]);

  return {
    currentEntity,
    setCurrentEntity,
    list,
    isLoading,
  };
};
