import React, {useState} from 'react';
import {MenuItem, TextField} from '@material-ui/core';
import {CellInfo, Column} from 'react-table';
import {
  AdCampaignCreative,
  AdCampaignState,
  AdCampaignTypes,
  FormColumn,
  PaneCardActionName,
  SnackBarStateParams,
  SnackbarType,
  AdFormat,
} from '../../core/interfaces';
import {SlidingPanel} from '../../components/slidingPanel/slidingPanel';
import {capitalizeCell} from '../../components/table/cellFormatter';
import {SnackBarAlert} from '../../components/alerts/snackBarAlert';
import {VpForm} from '../../components/form/vpForm';
import {panelTableGenerator} from '../../components/slidingPanel/panelTable';
import {submitForm} from '../../core/form/submitForm';
import {updateAdFormatFromTag} from '../../utils/tag/tagHandler';
import {ADFORMAT_KEY, TAG_KEY} from '../../core/constants';

interface AdCampaignPaneProps {
  onRequestClose: () => void;
  isPaneOpen: boolean;
  originalItem: AdCampaignState;
  setIsPaneOpen: (val: boolean) => void;
  setOriginalItem: any;
}

export const AdCampaignPane: React.FunctionComponent<AdCampaignPaneProps> = ({
  onRequestClose,
  isPaneOpen,
  originalItem,
  setIsPaneOpen,
  setOriginalItem,
}) => {
  const [snackBarState, setSnackBarState] = useState<SnackBarStateParams>({
    isSnackBarOpen: false,
    snackBarContent: '',
    snackBarType: SnackbarType.success,
  });
  const [submitTracker, setSubmitTracker] = useState<number>(0);

  const tableColumns: Array<Column<unknown>> = [
    {
      Header: 'Name',
      id: 'name',
      accessor: 'name',
      Cell: capitalizeCell('original.name'),
    },
    {
      Header: 'Size',
      id: 'size',
      Cell: (row: CellInfo): string => {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return `${row.original.width} x ${row.original.height}`;
      },
    },
    {
      Header: 'Ad Format',
      id: 'adFormat',
      accessor: 'adFormat',
    },
    {
      Header: 'Capping',
      id: 'frequencyCapping',
      accessor: 'frequencyCapping',
    },
    {
      Header: 'Type',
      id: 'type',
      accessor: 'campaignType',
      Cell: capitalizeCell('original.campaignType'),
    },
  ];

  const dialogFormColumns: FormColumn[][] = [
    [
      {
        inputProps: {
          key: 'name',
          label: 'name',
          required: true,
        },
      },
      {
        inputProps: {
          key: 'width',
          label: 'width',
          required: true,
          type: 'number',
        },
      },
      {
        inputProps: {
          key: 'height',
          label: 'height',
          required: true,
          type: 'number',
        },
      },
      {
        inputProps: {
          key: 'frequencyCapping',
          label: 'frequency capping',
          required: true,
          type: 'number',
        },
      },
    ],
    [
      {
        dropdownOptions: Object.values(AdFormat),
        inputProps: {
          key: ADFORMAT_KEY,
          label: 'Ad Format',
          required: true,
          type: 'dropdown',
        },
      },
      {
        inputProps: {
          key: TAG_KEY,
          required: true,
          multiline: true,
          rows: 13,
        },
      },
    ],
  ];

  const panelTable = panelTableGenerator({
    entity: originalItem,
    setEntity: setOriginalItem,
    listKey: 'creatives',
    editKey: 'id',
    columns: tableColumns,
    title: 'Creatives',
    dialog: {
      formColumns: dialogFormColumns,
      title: 'Creative',
      subtitle: 'Add a Creative to the Ad Campaign',
      formId: 'adCampaignDialog',
      validation: {
        button: {
          text: 'Check Tag',
        },
        functions: [
          (
            item: AdCampaignCreative | undefined,
            params: {
              setEntity: React.Dispatch<
                React.SetStateAction<AdCampaignCreative | undefined>
              >;
            },
          ): void => {
            const {setEntity} = params;
            updateAdFormatFromTag(item, setEntity);
          },
        ],
      },
    },
    cardActions: [{name: PaneCardActionName.ADD}],
    customListItem: (listItem: AdCampaignCreative) => {
      return {...listItem, adFormat: listItem.adFormat};
    },
  });

  const updateState = (e: React.FocusEvent<HTMLInputElement>): void => {
    setOriginalItem({...originalItem, [e.target.name]: e.target.value});
  };

  const validateCreatives = (data: AdCampaignState): boolean => {
    return !(data.creatives == null) && !(data.creatives.length === 0);
  };

  const saveChanges = async (
    adCampaignState: AdCampaignState,
  ): Promise<void> => {
    const hasCreatives = validateCreatives(adCampaignState);
    setSnackBarState({
      isSnackBarOpen: !hasCreatives,
      snackBarContent: hasCreatives
        ? ''
        : 'Unable to save Ad Campaign without creatives',
      snackBarType: hasCreatives ? SnackbarType.success : SnackbarType.error,
    });

    const submitTrackerValue = hasCreatives ? submitTracker + 1 : 0;
    return await Promise.resolve(setSubmitTracker(submitTrackerValue));
  };

  const slidingPanelContent = (): React.ReactNode => {
    return (
      <React.Fragment>
        <VpForm
          id="adCampaign"
          item={originalItem}
          submitTracker={submitTracker}
          onSubmit={submitForm({
            entity: originalItem,
            entityName: 'adCampaign',
            redirect: 'adCampaigns',
            setSubmitTracker,
            setIsPaneOpen,
            setSnackBarState,
          })}
          content={[
            [
              <TextField
                variant="outlined"
                margin="normal"
                key="name"
                required
                label="name"
                defaultValue={originalItem?.name ?? ''}
                onBlur={updateState}
                name="name"
              />,
            ],
            [
              <TextField
                variant="outlined"
                margin="normal"
                key="campaignType"
                select
                required
                label="campaignType"
                value={originalItem?.campaignType ?? ''}
                onChange={updateState}
                name="campaignType"
              >
                {[AdCampaignTypes.MOBILE, AdCampaignTypes.DESKTOP].map(
                  (option: string): React.ReactNode => {
                    return (
                      <MenuItem key={option} value={option}>
                        {`${option}`}
                      </MenuItem>
                    );
                  },
                )}
              </TextField>,
            ],
          ]}
        />
        {panelTable.generate()}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <SlidingPanel
        isOpen={isPaneOpen}
        onRequestClose={onRequestClose}
        content={slidingPanelContent()}
        navigation={{
          onSave: async (): Promise<void> => {
            return await saveChanges(originalItem);
          },
          onArchive: async (): Promise<void> => {
            // update the archive state
            setOriginalItem({
              ...originalItem,
              archived: !(originalItem.archived ?? true),
            });
            return await saveChanges(originalItem);
          },
          isArchived: originalItem?.archived ?? false,
        }}
      />
      <SnackBarAlert
        onClose={(): void =>
          setSnackBarState({...snackBarState, isSnackBarOpen: false})
        }
        open={snackBarState.isSnackBarOpen}
        contentProps={{
          content: snackBarState.snackBarContent,
          className: snackBarState.snackBarType,
        }}
      />
    </React.Fragment>
  );
};
