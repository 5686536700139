import _ from 'lodash';
import React, {useState} from 'react';
import {CellInfo, Column} from 'react-table';
import {BottomNavigationAction, MenuItem, TextField} from '@material-ui/core';
import {Check, Clear, ListAlt} from '@material-ui/icons';
import {
  AdCampaignTypes,
  CardActionUploadCsv,
  CustomAction,
  CustomFeedState,
  FormColumn,
  PaneCardActionName,
  SnackBarStateParams,
  SnackbarType,
} from '../../core/interfaces';
import {VpForm} from '../../components/form/vpForm';
import {SlidingPanel} from '../../components/slidingPanel/slidingPanel';
import {capitalizeCell} from '../../components/table/cellFormatter';
import {panelTableGenerator} from '../../components/slidingPanel/panelTable';
import {SnackBarAlert} from '../../components/alerts/snackBarAlert';
import {submitForm} from '../../core/form/submitForm';
import {mobileWebsiteUrl} from '../../../config';
import {FEED_ITEM_CATEGORIES} from '../../core/constants';
import {capitalizeFirstLetter} from '../../utils/utlis';

const CSV_HEADERS = ['url', 'name', 'category', 'image URL'];
interface AdCampaignPaneProps {
  onRequestClose: () => void;
  isPaneOpen: boolean;
  originalItem: CustomFeedState;
  setIsPaneOpen: (val: boolean) => void;
  setOriginalItem: any;
}

export const FeedPane: React.FunctionComponent<AdCampaignPaneProps> = ({
  onRequestClose,
  isPaneOpen,
  originalItem,
  setIsPaneOpen,
  setOriginalItem,
}) => {
  const [snackBarState, setSnackBarState] = useState<SnackBarStateParams>({
    isSnackBarOpen: false,
    snackBarContent: '',
    snackBarType: SnackbarType.success,
  });
  const [submitTracker, setSubmitTracker] = useState<number>(0);

  const tableColumns: Column<unknown>[] = [
    {
      Header: 'Url',
      id: 'url',
      accessor: 'url',
    },
    {
      Header: 'Name',
      id: 'name',
      accessor: 'name',
      Cell: capitalizeCell('original.name'),
    },
    {
      Header: 'Category',
      id: 'category',
      accessor: 'category',
      Cell: capitalizeCell('original.category'),
    },
    {
      Header: 'Image URL',
      id: 'logo',
      accessor: 'logo',
      Cell: (row: CellInfo): JSX.Element => {
        return row.original.logo ? <Check /> : <Clear color="error" />;
      },
    },
  ];

  const dialogFormColumns: FormColumn[][] = [
    [
      {
        inputProps: {
          key: 'url',
          label: 'url',
          required: true,
        },
      },
      {
        inputProps: {
          key: 'name',
          label: 'name',
          required: true,
        },
      },
      {
        dropdownOptions: FEED_ITEM_CATEGORIES,
        inputProps: {
          key: 'category',
          label: 'Category',
          required: false,
          type: 'dropdown',
        },
      },
      {
        inputProps: {
          key: 'logo',
          label: 'Image URL',
          required: false,
        },
      },
    ],
  ];

  const panelTable = panelTableGenerator({
    entity: originalItem,
    setEntity: setOriginalItem,
    listKey: 'items',
    editKey: 'url',
    columns: tableColumns,
    title: 'Feed Items',
    dialog: {
      formColumns: dialogFormColumns,
      title: 'Custom Feed Item',
      subtitle: 'Add an Item to the Custom Feed',
      formId: 'customFeedDialog',
    },
    cardActions: [
      {name: PaneCardActionName.ADD},
      {
        name: PaneCardActionName.UPLOAD_CSV,
        errorHandling: () => {
          setSnackBarState({
            isSnackBarOpen: true,
            snackBarContent: 'Invalid Headers',
            snackBarType: SnackbarType.error,
          });
        },
        mapping: (
          data: any,
        ): {success: boolean; error?: string; data?: any} => {
          const headers = _.get(data && data[0], 'data');

          if (!_.isEqual(headers, CSV_HEADERS)) {
            return {
              success: false,
              error: 'Invalid Headers',
            };
          }

          // remove the first element of the array (the headers)
          data.shift();

          return {
            success: true,
            data: data
              .map((i: any) => {
                const [url, name, category, logo] = i.data;

                if (!url || !name) {
                  return null;
                }

                // need to validate that the category is a within the array we have
                if (category) {
                  if (
                    !FEED_ITEM_CATEGORIES.map((cat: string) =>
                      cat.toLocaleLowerCase(),
                    ).includes(category.toLocaleLowerCase())
                  ) {
                    return null;
                  }
                }

                return {
                  url,
                  name,
                  category: category ? capitalizeFirstLetter(category) : null,
                  logo: logo || null,
                };
              })
              .filter((i: any) => !!i),
          };
        },
      } as CardActionUploadCsv,
    ],
  });

  const updateState = (e: React.FocusEvent<HTMLInputElement>): void => {
    setOriginalItem({...originalItem, [e.target.name]: e.target.value});
  };

  const slidingPanelContent = () => {
    return (
      <React.Fragment>
        <VpForm
          id="customFeed"
          item={originalItem}
          submitTracker={submitTracker}
          onSubmit={submitForm({
            entity: originalItem,
            entityName: 'feed',
            redirect: 'feeds',
            setSubmitTracker,
            setIsPaneOpen,
            setSnackBarState,
          })}
          content={[
            [
              <TextField
                variant="outlined"
                margin="normal"
                key="name"
                required
                label="name"
                defaultValue={(originalItem && originalItem.name) || ''}
                onBlur={updateState}
                name="name"
              />,
            ],
            [
              <TextField
                variant="outlined"
                margin="normal"
                key="feedType"
                select
                required
                label="feed type"
                value={(originalItem && originalItem.feedType) || ''}
                onChange={updateState}
                name="feedType"
              >
                {[AdCampaignTypes.MOBILE, AdCampaignTypes.DESKTOP].map(
                  (option: string): React.ReactNode => {
                    return (
                      <MenuItem key={option} value={option}>
                        {`${option}`}
                      </MenuItem>
                    );
                  },
                )}
              </TextField>,
            ],
          ]}
        />
        {panelTable.generate()}
      </React.Fragment>
    );
  };

  // add the preview custom action if we are editing the item.
  // cannot display on create because the url for the mobile site needs the feed id
  const customActions: CustomAction[] = originalItem.id
    ? [
        {
          value: 'preview',
          action: () => {
            // for now we always display the 'mobile' feed, even if it is desktop. This is because the
            // desktop feed don't have a preview page yet. We think this is fine as the desktop feed don't use logos and image yet.
            const url = `${mobileWebsiteUrl}/ios/browser-home?id=${originalItem.id}&type=data-collection`;
            window.open(url, '_blank');
          },
          component: (props: {value: string}) => (
            <BottomNavigationAction
              {...props}
              showLabel={true}
              label="Preview"
              icon={<ListAlt />}
            />
          ),
        },
      ]
    : [];

  return (
    <React.Fragment>
      <SlidingPanel
        isOpen={isPaneOpen}
        onRequestClose={() => {
          onRequestClose();
        }}
        content={slidingPanelContent()}
        navigation={{
          onSave: (): Promise<void> => {
            return Promise.resolve(setSubmitTracker(submitTracker + 1));
          },
          onArchive: (): Promise<void> => {
            setOriginalItem({
              ...originalItem,
              archived: !originalItem.archived,
            });
            return Promise.resolve(setSubmitTracker(submitTracker + 1));
          },
          isArchived: (originalItem && originalItem.archived) || false,
          customActions: customActions,
        }}
      />
      <SnackBarAlert
        onClose={(): void =>
          setSnackBarState({...snackBarState, isSnackBarOpen: false})
        }
        open={snackBarState.isSnackBarOpen}
        contentProps={{
          content: snackBarState.snackBarContent,
          className: snackBarState.snackBarType,
        }}
      />
    </React.Fragment>
  );
};
