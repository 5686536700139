import React, { Fragment } from "react";
import {
  Grid,
  BottomNavigationAction,
  ListItem,
  ListItemIcon,
  ListSubheader,
  ListItemText,
  List,
  Fab,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import SlidingPane from "react-sliding-pane";
import { LibraryBooks, ListAlt, Refresh } from "@material-ui/icons";
import { Column, CellInfo } from "react-table";
import { cloudFunctionBase } from "../../../config";
import { DataManager } from "../../components/dataManager/dataManager.react";
import {
  RouteProps,
  FeedConfig,
  DataManagerMethods,
  iValidity,
  Study,
  FeedItem,
  Schedule,
  Continents,
} from "../../core/interfaces";
import { builder } from "../../components/formBuilder/formBuilder.react";
import { dashToCamel } from "../../utils/dashToCamel";
import { Firestore } from "../../components/dataManager/firestore";
import { RefreshFeedDialog } from "./refreshFeedDialog.react";
import { RunAllActiveSchedulesDialog } from "./runAllActiveSchedulesDialog.react";
import styles from "./feedConfigs.scss";
import { Loader } from "../../components/loader/loader.react";
import { PreviewCard } from "./feedConfigPreviewCard.react";
import { getIdToken } from "../../utils/idToken";
import { FeedConfigCloudReport } from "./feedConfigCloudReport";
import { FeedValidator } from "./feedValidator.react";

const firestore = new Firestore();

interface PublicMethods {
  refreshList(): void;
}

interface iState {
  triggeringFeedSchedules: boolean;
  isLoading: boolean;
  loadingPreviewData: boolean;
  previewFeedData: FeedItem[];
  itemToFreshFeed?: FeedConfig | null;
  currentConfigs: FeedConfig[];
  refreshingSchedules: {
    [id: string]: string;
  };
  manualRefresh: boolean;
  continent: string;
  previewFeedId?: string;
}

function caseInsensitiveSearch(value: string, target: string): boolean {
  return value && target
    ? target.toLowerCase().includes(value.toLowerCase())
    : false;
}

function caseInsensitiveSort(a: string, b: string): number {
  // push the deleted ones at the bottom,
  // this is a bit hacky but not sure why the 'name' (such as feedConfigName or platformName) is set to DELETED
  // and I don't really want to change that logic.
  // On the UI a red crossed circle is displayed for this type of items
  if (a === "DELETED") {
    return 1;
  }
  if (b === "DELETED") {
    return -1;
  }
  return a.toLowerCase().localeCompare(b.toLowerCase());
}

export class FeedConfigs extends React.Component<RouteProps, iState> {
  private whitelistField: React.RefObject<HTMLInputElement>;
  private blacklistField: React.RefObject<HTMLInputElement>;
  private columns: Column[];
  private studies: Study[] = [];
  private dataManagerMethods: PublicMethods;
  private continents: string[] = Object.values(Continents);

  constructor(props: RouteProps) {
    super(props);
    this.whitelistField = React.createRef();
    this.blacklistField = React.createRef();
    this.state = {
      isLoading: true,
      triggeringFeedSchedules: false,
      itemToFreshFeed: null,
      previewFeedData: [],
      manualRefresh: false,
      loadingPreviewData: false,
      currentConfigs: [],
      refreshingSchedules: {},
      continent: "Oceania",
      previewFeedId: "",
    };
    // the columns to display, this is updated automatically with an action column at the end
    this.columns = [
      {
        Header: "Name",
        accessor: "name",
        minWidth: 300,
      },
      {
        Header: "Length",
        accessor: "length",
        maxWidth: 100,
        filterable: false,
        sortable: false,
      },
      {
        Header: "Custom Task Frequency", // Required because our accessor is not a string
        accessor: "customTaskFrequency",
        maxWidth: 100,
        filterable: false,
        sortable: false,
      },
      {
        Header: "Max Share", // Required because our accessor is not a string
        accessor: "maxShare",
        maxWidth: 100,
        filterable: false,
        sortable: false,
        Cell: ({ value }): string => String(value),
      },
      {
        Header: "Last Refreshed",
        minWidth: 250,
        sortable: false,
        filterable: false,
        Cell: (row: CellInfo): React.ReactNode => {
          return (
            <FeedConfigCloudReport
              methods={{
                createSchedule: this.createSchedule.bind(this),
                refreshList: this.dataManagerMethods.refreshList.bind(this),
              }}
              feedConfig={row.original}
            />
          );
        },
      },
      {
        Header: "Id",
        accessor: "id",
      },
    ];
    this.fetchStudies();
  }

  /**
   * @function
   * @name fetchStudies
   * @description Will grab all the current studies, so that we can use the config ids and tell if
   * a particular config has a relationship to a study.
   * @returns {Promise<void>} no return value
   */
  async fetchStudies(): Promise<void> {
    const name = dashToCamel("studies");
    // only ever show unarchved studies here
    const response = await (firestore.getList(
      name,
      true
    ) as unknown as Promise<{
      success: boolean;
      data: Study[];
    }>);
    if (response.success) {
      this.studies = response.data;
    }
    this.setState({
      isLoading: false,
    });
  }

  /**
   * @function
   * @name checkValidity
   * @description - Updates the UI to show the field in an error state when the field is invalid
   * @param { iValidity } - validatorObject - Contains all information relating to the field, and how we can validate it
   * @returns { boolean } - if the field is valid or not
   */
  checkValidity(validatorObject: iValidity): boolean {
    const { value, field } = validatorObject;
    let isValid = true;
    switch (field) {
      case "name":
        isValid = String(value).length > 0;
        break;
      case "maxShare":
      case "length":
      case "customTaskFrequency":
        isValid =
          !isNaN(parseFloat(String(value))) &&
          typeof parseFloat(String(value)) === "number";
        break;
      default:
        isValid = true;
        break;
    }
    return isValid;
  }

  /**
   * @function
   * @name getInUseStudiesTemplate
   * @param {FeedConfig} clone - the clone of the feedConfig object, we use this to lookup
   * against the studies to see if there is a relationship between this config and any other study
   * @description - draws a little list of studies (or none) that are in use with the current config
   * We wont be able to archive a config if it's currently in use.
   * @returns {React.ReactNode} - the template to draw.
   */
  getInUseStudiesTemplate(clone: FeedConfig): React.ReactNode {
    const inUseStudies = this.getStudiesUsedByThisConfig(clone) || [];
    return (
      <Fragment>
        <List
          className={styles.studyList}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              {inUseStudies.length
                ? "This config is used by:"
                : "No study using this config."}
            </ListSubheader>
          }
        >
          {inUseStudies.map((study: Study): React.ReactNode => {
            return (
              <ListItem key={study.id} button>
                <ListItemIcon>
                  <LibraryBooks />
                </ListItemIcon>
                <NavLink to={`/studies/${study.id}`}>
                  <ListItemText primary={study.name} />
                </NavLink>
              </ListItem>
            );
          })}
        </List>
      </Fragment>
    );
  }

  /**
   * @function
   * @name getStudiesUsedByThisConfig
   * @param {FeedConfig} clone - the clone of the feedConfig object, we use this to lookup
   * against the studies to see if there is a relationship between this config and any other study
   * @description - Finds studies related to this config.
   * @returns {React.ReactNode} - the template to draw.
   */
  getStudiesUsedByThisConfig(clone: FeedConfig): Study[] {
    return this.studies.filter((study) => study.feedId === clone.id);
  }

  /**
   * @function
   * @name getPanelContent
   * @description - The jsx template to render in the sidebar panel
   * @param { FeedConfig } - clone - the current field clone object
   * @param { DataManagerMethods } - methods - the methods available to use on the fields
   * @returns { React.ReactNode }
   */
  getPanelContent(
    clone: FeedConfig,
    methods: DataManagerMethods
  ): React.ReactNode {
    return (
      <Fragment>
        <Grid
          container
          spacing={2}
          className="layout-row layout-align-space-between-center"
        >
          <Grid
            item
            xs={6}
            className="layout-column layout-align-start-center layout-fill children-fill-width"
          >
            {builder(
              [
                {
                  required: true,
                  key: "name",
                  label: "Name",
                  helperText: "The name of this feed config.",
                  type: "text",
                },
                {
                  multiline: true,
                  key: "whitelist",
                  label: "Whitelist",
                  helperText:
                    "Which tasks are allowed in the feed, comma separate globs or paste from rows in spreadsheet",
                  type: "chip",
                  ref: (ref: React.RefObject<HTMLInputElement>): void => {
                    this.whitelistField = ref;
                  },
                },
                {
                  multiline: true,
                  key: "blacklist",
                  label: "Blacklist",
                  helperText:
                    "Which tasks are not allowed in the feed, comma separate globs or paste from rows in spreadsheet",
                  type: "chip",
                  ref: (ref: React.RefObject<HTMLInputElement>): void => {
                    this.blacklistField = ref;
                  },
                },
              ],
              clone,
              methods
            )}
            {this.getInUseStudiesTemplate(clone)}
          </Grid>
          <Grid
            item
            xs={6}
            className="field-column layout-column layout-align-start-center layout-fill children-fill-width"
          >
            {builder(
              [
                {
                  required: true,
                  key: "length",
                  label: "length",
                  helperText:
                    "The amount of items that will appear in the feed.",
                  type: "number",
                },
                {
                  required: true,
                  key: "customTaskFrequency",
                  label: "Custom Task Frequency",
                  helperText:
                    "How often a custom task should appear in the feed, eg: 2 will include one every 2nd task",
                  type: "number",
                },
                {
                  required: true,
                  key: "maxShare",
                  label: "Max share value",
                  helperText:
                    "What's the maximum percentage a domain can appear in the feed",
                  type: "number",
                  min: 0.1,
                  max: 1,
                  step: 0.01,
                },
                {
                  key: "shuffle",
                  label: "Shuffle",
                  type: "checkbox",
                },
                {
                  key: "includeMeta",
                  label: "Include meta data",
                  type: "checkbox",
                },
                {
                  key: "prefixTaskTitles",
                  label: "Prefix task titles",
                  type: "checkbox",
                },
              ],
              clone,
              methods
            )}
            <FeedValidator config={clone} />
          </Grid>
        </Grid>
      </Fragment>
    );
  }

  /**
   * @function
   * @async
   * @name showPreviewWindow
   * @description - uses the feed config to retrieve the current data saved in the cache
   * to preview the data in a small scrollable panel.
   * @param { FeedConfig } clone - the current clone item
   * @returns {Promise<void>} no resolved value
   */
  async showPreviewWindow(clone: FeedConfig): Promise<void> {
    const { id } = clone;
    this.setState({
      loadingPreviewData: true,
      previewFeedId: id,
    });
    const response = await fetch(
      `${cloudFunctionBase}/article-feed-cache-get?id=${id}&continent=${this.state.continent}`
    );
    const data: FeedItem[] = await response.json();
    this.setState({
      loadingPreviewData: false,
      previewFeedData: data,
    });
  }

  async handleContinentChange(event: any): Promise<void> {
    const { value } = event.target;
    this.setState({
      loadingPreviewData: true,
      continent: value,
    });
    const response = await fetch(
      `${cloudFunctionBase}/article-feed-cache-get?id=${this.state.previewFeedId}&continent=${value}`
    );
    const data: FeedItem[] = await response.json();
    this.setState({
      loadingPreviewData: false,
      previewFeedData: data,
    });
  }

  /**
   * @function
   * @async
   * @name getSchedule
   * @description - Will attempt to get the schedule for the current feedconfig and return it.
   * @param { FeedConfig } clone - the current clone item
   * @returns {Promise<Schedule|null>} either null if not found, or the schedule item
   */
  async getSchedule(
    clone: FeedConfig,
    idToken: string | null
  ): Promise<Schedule | null> {
    if (idToken === null) {
      return null;
    }
    const { id } = clone;
    try {
      const response = await fetch(
        `${cloudFunctionBase}/get-feed-refresh-schedule?id=${id}&idToken=${idToken}`
      );
      return await response.json();
    } catch (e) {
      // no need to throw this every time, sometimes this is expected behavior to hit this catch
      // console.error(`Schedule doesn't exist.`);
    }
    return null;
  }

  /**
   * @function
   * @async
   * @name createSchedule
   * @description - Will attempt to create the schedule for the current cloned feed config
   * @param { FeedConfig } clone - the current clone item
   * @returns {Promise<boolean>} true if successful, false if not
   */
  async createSchedule(clone: FeedConfig): Promise<boolean> {
    const idToken = await getIdToken();
    if (idToken === null) {
      return false;
    }
    const { id } = clone;
    try {
      await fetch(
        `${cloudFunctionBase}/create-feed-refresh-schedule?id=${id}&idToken=${idToken}`
      );
      return true;
    } catch (e) {
      console.error(`Schedule couldn't be created.`);
    }
    return false;
  }

  /**
   * @function
   * @async
   * @name deleteSchedule
   * @description - Will attempt to delete the schedule for the current cloned feed config
   * @param { FeedConfig } clone - the current clone item
   * @returns {Promise<boolean>} true if successful, false if not
   */
  async deleteSchedule(clone: FeedConfig): Promise<boolean> {
    const idToken = await getIdToken();
    if (idToken === null) {
      return false;
    }
    const { id } = clone;
    try {
      await fetch(
        `${cloudFunctionBase}/delete-feed-refresh-schedule?id=${id}&idToken=${idToken}`
      );
      return true;
    } catch (e) {
      console.error(`Schedule couldn't be created.`);
    }
    return false;
  }

  /**
   * @description Responsible for creating and removing schedules for a feedconfig
   * Schedules are stored by the same id as the feedConfig id, so it's easy to retrieve and remove.
   * @param {FeedConfig} clone - the currrent clone object of the feed config
   * @param {boolean} shouldRemove - if true, it will attempt to remove the schedule
   * @returns {Promise<void>}
   */
  async manageSchedule(
    clone: FeedConfig,
    shouldRemove: boolean
  ): Promise<void> {
    // attempt to get the schedule
    const idToken = await getIdToken();
    const schedule = await this.getSchedule(clone, idToken);
    // if we're asking to remove schedule, and it exists, attempt to remove it.
    if (shouldRemove && schedule !== null) {
      const didRemove = await this.deleteSchedule(clone);
      console.log(
        didRemove
          ? `Removed schedule successfully`
          : "Failed to remove schedule"
      );
    }
    // if the schedule doesn't exist, and we aren't trying to remove it, create one.
    if (!shouldRemove && !schedule) {
      const didCreate = await this.createSchedule(clone);
      console.log(
        didCreate
          ? `Created schedule successfully`
          : "Failed to create schedule"
      );
    }
  }

  /**
   * @function
   * @name onRefreshDone
   * @description - will reset the item once we've successfully or unsuccessfully updated the
   * feed
   * @returns {void} - no return value
   */
  onRefreshDone(): void {
    this.setState({
      itemToFreshFeed: null,
    });
  }

  /**
   * @function
   * @name onSchedulesRun
   * @description - Once all schedules have been run
   * @returns {void} - no return value
   */
  async onSchedulesRun(): Promise<void> {
    this.setState({
      triggeringFeedSchedules: false,
    });
    // trigger the list refresh so we can see new state updates.
    await this.dataManagerMethods.refreshList();
  }

  renderFeedPreview(): React.ReactNode {
    if (this.state.loadingPreviewData) {
      return "Please wait...";
    }

    if (this.state.previewFeedData.length > 0) {
      return this.state.previewFeedData.map((item, i) => (
        <PreviewCard key={i} item={item} />
      ));
    }

    return `There are no articles in the feed for ${this.state.continent}`;
  }

  /**
   * @function
   * @name render
   * @description - standard react render method
   * @returns {React.ReactNode} - returns jsx markup
   */
  render(): React.ReactNode {
    if (this.state.isLoading) {
      return <Loader />;
    }
    // these are the defaults for the feed config
    const defaultItem: FeedConfig = {
      length: 50,
      name: "",
      shuffle: true,
      whitelist: [],
      blacklist: [],
      includeAdSelectors: false,
      includeMeta: true,
      prefixTaskTitles: false,
      customTaskFrequency: 0,
      maxShare: 0.25,
    };
    const props = {
      module: "feed-configs",
      name: "Feed Config",
      plural: `Feed Configs`,
      match: this.props.match,
      history: this.props.history,
      columns: this.columns,
      defaultFilterMethod: caseInsensitiveSearch,
      defaultSortMethod: caseInsensitiveSort,
      checkValidity: this.checkValidity.bind(this),
      defaultItem,
      getPanelContent: this.getPanelContent.bind(this),
      // expose the methods we want the "parent" component to call
      // for example, if we want to refresh the list, call this.dataManagerMethods.refreshList();
      getMethods: (methods: PublicMethods): void => {
        this.dataManagerMethods = methods;
      },
      actionButtons: (
        <Tooltip title="Refresh All Feed Configs" placement="left">
          <div>
            <Fab
              disabled={!this.state.currentConfigs.length}
              color="secondary"
              size="small"
              onClick={(): void => {
                this.setState({
                  triggeringFeedSchedules: true,
                });
              }}
            >
              <Refresh />
            </Fab>
          </div>
        </Tooltip>
      ),
      attachButtonToEditingWindow: (newItem: FeedConfig): React.ReactNode => {
        return [
          <BottomNavigationAction
            key="refresh-btn"
            onClick={(): void => {
              // update the refreshed item to trigger the refresh dialog
              this.setState({
                itemToFreshFeed: newItem,
                manualRefresh: true,
              });
            }}
            className="action-button-refresh"
            showLabel={true}
            label="Refresh Feed"
            icon={<Refresh />}
          />,
          <BottomNavigationAction
            key="preview-btn"
            onClick={this.showPreviewWindow.bind(this, newItem)}
            className="action-button-preview"
            showLabel={true}
            label={
              this.state.loadingPreviewData ? "Please wait..." : "Preview Feed"
            }
            value="disabled"
            icon={<ListAlt />}
          />,
        ];
      },
      transformEditingItem: (feedConfig: FeedConfig): FeedConfig => {
        feedConfig.isUsed = this.studies.some(
          (study) => study.feedConfigId === feedConfig.id
        );
        console.log("feed", feedConfig);
        return feedConfig;
      },
      // provides us the ability to manipulate the data before it's sent to the table
      // in this case, we get or attempt to get the schedule for each config, so we can attach it
      // to the row item and provide new functionality in the list.
      transformData: async (data: FeedConfig[]): Promise<FeedConfig[]> => {
        const idToken = await getIdToken();
        const requests = data.map((item): Promise<Schedule | null> | null => {
          // attempt to get the schedule
          return item.archived ? null : this.getSchedule(item, idToken);
        });
        const schedules = await Promise.all(requests);
        const currentConfigs = data.map((item, i) => {
          return {
            ...item,
            schedule: schedules[i],
          };
        });
        return currentConfigs;
      },
      // called when the item is archived or unarchived
      onArchive: (clone: FeedConfig, shouldArchive: boolean): void => {
        this.manageSchedule(clone, shouldArchive);
        // if we archived, no need to keep slider open
        if (shouldArchive) {
          this.setState({
            itemToFreshFeed: null,
          });
        }
      },
      // called when we are updating a feedConfig item
      onUpdate: async (
        newItem: FeedConfig,
        oldItem: FeedConfig
      ): Promise<void> => {
        const ignoreKeys = ["name", "updatedAt", "createdAt"];
        const valuesChanged = Object.keys(newItem)
          .filter((key) => !ignoreKeys.includes(key))
          .filter((key: string): boolean => {
            if (JSON.stringify(newItem[key]) === JSON.stringify(oldItem[key])) {
              return false;
            }
            return true;
          });
        // if this array has length, values that can effect the feed
        // will need to refresh the feed, so we prompt the user and ask them to
        // refresh
        if (valuesChanged.length) {
          this.setState({
            itemToFreshFeed: newItem,
          });
        } else {
          this.setState({
            itemToFreshFeed: null,
          });
        }
      },
      // called when we're creating a new config item
      onCreate: async (newItem: FeedConfig): Promise<void> => {
        // create the schedule if it doesn't exists
        this.manageSchedule(newItem, false);
        // update the refreshed item to trigger the refresh dialog
        this.setState({
          itemToFreshFeed: newItem,
        });
      },
    };
    return (
      <Fragment>
        <DataManager id={styles.feedConfigs} {...props}></DataManager>
        {
          <RefreshFeedDialog
            type={this.state.manualRefresh ? "manual" : "automatic"}
            onClose={this.onRefreshDone.bind(this)}
            isOpen={this.state.itemToFreshFeed !== null}
            config={this.state.itemToFreshFeed}
          />
        }
        {this.state.previewFeedData && (
          <SlidingPane
            title={`Previewing Feed Data`}
            width="400px"
            onRequestClose={(): void => {
              this.setState({
                previewFeedId: "",
              });
            }}
            ariaHideApp={false}
            isOpen={this.state.previewFeedId !== ""}
          >
            <div
              className="layout-column layout-fill layout-align-start-center"
              style={{
                height: "100%",
                overflow: "auto",
              }}
            >
              <FormControl>
                <InputLabel>Continent</InputLabel>
                <Select
                  value={this.state.continent}
                  onChange={(event) => this.handleContinentChange(event)}
                >
                  {this.continents.map((continent) => {
                    return (
                      <MenuItem key={continent} value={continent}>
                        {continent}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {this.renderFeedPreview()}
            </div>
          </SlidingPane>
        )}
        {
          <RunAllActiveSchedulesDialog
            configs={this.state.currentConfigs}
            onClose={this.onSchedulesRun.bind(this)}
            isOpen={this.state.triggeringFeedSchedules}
          />
        }
      </Fragment>
    );
  }
}
