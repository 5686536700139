/* eslint-disable @typescript-eslint/strict-boolean-expressions */
export const validateFrom = (
  name: string | null,
  platformId: string | null,
  d: number | null,
  surveyUrl: string | null,
  feedId: string | null,
  addError: (error: {id: string; helperText: string}) => void,
): boolean => {
  let hasErrors = false;
  if (!name) {
    addError({
      id: 'name',
      helperText: 'Invalid Name',
    });
    hasErrors = true;
  }
  if (!platformId) {
    addError({id: 'platformId', helperText: 'Invalid Platform Id'});
    hasErrors = true;
  }
  if (!d) {
    addError({id: 'd', helperText: 'Invalid Duration'});
    hasErrors = true;
  }
  if (!surveyUrl) {
    addError({
      id: 'surveyUrl',
      helperText: 'Invalid Survey URL',
    });
    hasErrors = true;
  }

  if (!feedId) {
    addError({
      id: 'feedId',
      helperText: 'Invalid Feed Config',
    });
    hasErrors = true;
  }
  return hasErrors;
};
