import * as React from "react";
import { Grid, FormControl, TextField, MenuItem } from "@material-ui/core";
import { DropdownOption } from "../../core/interfaces";

export const studyFilters = (field: any): React.ReactNode => {
  return (
    <Grid
      key={field.id}
      item
      xs={4}
      className="layout-column layout-align-start-center layout-fill children-fill-width"
    >
      <FormControl>
        <TextField
          select
          label={field.label}
          variant="outlined"
          margin="normal"
          error={field.error}
          helperText={field.helperText}
          onChange={field.onChange}
          InputLabelProps={{ shrink: true }}
          value={field.value}
          name={field.name}
        >
          {(field.options || []).map(
            (option: DropdownOption): React.ReactNode => {
              return (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            }
          )}
        </TextField>
      </FormControl>
    </Grid>
  );
};
