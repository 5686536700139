import React, {
  Fragment,
  useState,
  useEffect,
  ReactNode,
  ReactElement,
} from "react";
import ReactTable, { CellInfo } from "react-table";
import { Check, Clear } from "@material-ui/icons";
import { Tooltip, Typography, Divider, Box } from "@material-ui/core";
import { cloudFunctionBase } from "../../../config";
import {
  FeedItem,
  FeedConfig,
  Continents,
  DomainBreakdown,
  FeedReport,
} from "../../core/interfaces";
import { getFeedReport } from "./getFeedReport";
import { Loader } from "../../components/loader/loader.react";
import styles from "./feedConfigs.scss";

interface FeedValidatorProps {
  config: FeedConfig;
}

export const FeedValidator: React.FunctionComponent<FeedValidatorProps> = ({
  config,
}) => {
  const [feed, setFeed] = useState<Array<FeedItem[]>>([]);
  // an array of continents by string, should be the same order as the cloud functions
  // TODO: once cloud functions are merged with vp-admin repo, make sure both the cloud
  // TODO: functions and this array values come from the same source.
  const continents: string[] = Object.values(Continents);
  // the columns for the table.
  const columns = [
    {
      Header: "Continent",
      accessor: "continent",
      Cell: (row: CellInfo): ReactNode => {
        return (
          <Tooltip
            title={row.original.perDomainBreakdown.map(
              (breakdown: DomainBreakdown) => {
                return (
                  <Fragment key={breakdown.domain}>
                    <Typography variant="caption">
                      {breakdown.domain} ({breakdown.count} articles) -{" "}
                      {breakdown.percentage.toFixed(2)}% of feed.
                    </Typography>
                  </Fragment>
                );
              }
            )}
          >
            <Typography>{row.original.continent}</Typography>
          </Tooltip>
        );
      },
    },
    {
      Header: "All Rules Pass",
      Cell: (row: CellInfo): ReactNode => {
        return row.original.isValid ? <Check /> : <Clear />;
      },
    },
    {
      Header: "Max Share",
      Cell: (row: CellInfo): ReactNode => {
        return (
          <Tooltip
            title={`Assumed max share: ${row.original.assumedMaxShare}, original max share: ${config.maxShare}`}
          >
            {row.original.validity.isMaxShareValid ? <Check /> : <Clear />}
          </Tooltip>
        );
      },
    },
    {
      Header: "Length",
      Cell: (row: CellInfo): ReactNode => {
        return (
          <Tooltip title={`Actual length: ${row.original.feedLength}`}>
            {row.original.validity.isValidLength ? <Check /> : <Clear />}
          </Tooltip>
        );
      },
    },
    {
      Header: "Largest Max Share",
      accessor: "largestMaxSharePercentage",
      Cell: (row: CellInfo): ReactNode => {
        return (
          <Typography>
            {row.original.largestMaxSharePercentage.toFixed(2)}
          </Typography>
        );
      },
    },
  ];

  /**
   * @see https://reactjs.org/docs/hooks-effect.html
   * @description We use this react hook which tells the function component
   * that we're going to be applying something after render which allows
   * us to call and use 'useState' methods asynchronously, this will grab all feeds available
   * for this current feedConfig for all the saved continents and store them on the instance once ready.
   */
  useEffect(() => {
    (async function (): Promise<void> {
      const data: Array<FeedItem[]> = [];
      for (const continent of continents) {
        const response = await fetch(
          `${cloudFunctionBase}/article-feed-cache-get?id=${config.id}&continent=${continent}`
        );
        const json: FeedItem[] = await response.json();
        data.push(json);
      }
      setFeed(data);
    })();
  }, []);

  /**
   * @function
   * @name getReports
   * @description simply produces an object per continent in an array so we can spit
   * it out into a table later on.
   * @returns {FeedReport[]} - an array of reports
   */
  const getReports = (): FeedReport[] =>
    continents.map(
      (continent: string, index: number): FeedReport => ({
        continent,
        ...getFeedReport(feed[index], config),
      })
    );

  /**
   * @function
   * @name buildReportTable
   * @description Displays the reports in a readable simple UI
   * @returns {ReactNode} - an array of reports
   */
  const buildReportTable = (): ReactElement => {
    const reports = getReports();
    return (
      <Fragment>
        <Box my={2}>
          <Typography variant="h5">Feed Validator</Typography>
        </Box>
        <Divider light />
        <Box my={2}>
          <ReactTable
            className={`-striped -highlight ${styles.reportTable}`}
            data={reports}
            showPagination={false}
            showPageSizeOptions={false}
            pageSize={(reports || []).length}
            minRows={0}
            columns={columns}
          />
        </Box>
      </Fragment>
    );
  };
  // whilst there's no feed, show a loading indicator
  return !feed.length ? (
    <Box my={2}>
      <Loader />
    </Box>
  ) : (
    buildReportTable()
  );
};
