import * as React from "react";
import { Grid, FormControl, TextField } from "@material-ui/core";

export const AttentionTimeFilters: React.FunctionComponent<any> = ({
  selectedAttentionTimes,
  onChangeFrom,
  onChangeTo,
  error,
  helperText,
}) => {
  return (
    <Grid
      key="dates"
      item
      xs={3}
      className="layout-column layout-align-start-center layout-fill children-fill-width"
    >
      <h3>Attention Time</h3>
      <FormControl>
        <TextField
          id="attentionTimeFrom"
          label="From"
          type="number"
          variant="outlined"
          margin="normal"
          error={error}
          helperText={helperText}
          defaultValue={selectedAttentionTimes.from}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={onChangeFrom}
        />
      </FormControl>
      <FormControl>
        <TextField
          id="attentionTimeTo"
          label="To"
          type="number"
          variant="outlined"
          margin="normal"
          error={error}
          helperText={helperText}
          defaultValue={selectedAttentionTimes.to}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={onChangeTo}
        />
      </FormControl>
    </Grid>
  );
};
