/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/*
Set of functions for fromatting table cells
*/

import {Tooltip, Fab} from '@material-ui/core';
import {Edit} from '@material-ui/icons';
import _ from 'lodash';
import React from 'react';
import {CellInfo} from 'react-table';
import {capitalizeFirstLetter} from '../../utils/utlis';

export const capitalizeCell = (field: string): ((row: CellInfo) => string) => {
  return (row: CellInfo): string => {
    const string = _.get(row, field, '');
    return capitalizeFirstLetter(string || '');
  };
};
// return the count of items for an array stored within the row object
export const countItems = (field: string): ((row: CellInfo) => number) => {
  return (row: CellInfo): number => {
    const items = _.get(row, field, []);
    return (items && items.length) || 0;
  };
};

interface ActionCellProps {
  viewEdit?: {
    onClick: (item: any) => void;
  };
}

export const actionCell = (
  props: ActionCellProps,
): ((row: CellInfo) => React.ReactNode) => {
  return (row: CellInfo): React.ReactNode => {
    const {original} = row;
    const {viewEdit} = props;
    return (
      <React.Fragment>
        {viewEdit ? (
          <Tooltip title="View / Edit" placement="left">
            <Fab size="small" onClick={(): void => viewEdit.onClick(original)}>
              <Edit />
            </Fab>
          </Tooltip>
        ) : null}
      </React.Fragment>
    );
  };
};
