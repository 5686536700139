import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import { Unarchive, Archive, Save } from "@material-ui/icons";
import React from "react";
import { CustomAction } from "../../core/interfaces";
import styles from "../dataManager/dataManager.scss";

const Pusher = (): JSX.Element => {
  return <div style={{ width: "100%" }} />;
};

enum NavigationAction {
  SAVE = "save",
  ARCHIVE = "archive",
}
interface SlidingPanelNavigationProps {
  onSave: () => void;
  onArchive: () => void;
  isArchived: boolean;
  customActions?: CustomAction[];
}

export const SlidingPanelNavigation: React.FunctionComponent<
  SlidingPanelNavigationProps
> = ({ onSave, onArchive, isArchived, customActions }) => {
  return (
    <BottomNavigation
      id={styles.BottomNavigation}
      style={{ position: "fixed", bottom: 0, width: "95%" }}
      className="layout-row layout-align-space-between-center"
      onChange={(_, newValue): void => {
        switch (newValue) {
          case NavigationAction.SAVE:
            onSave();
            break;
          case NavigationAction.ARCHIVE:
            onArchive();
            break;
          default:
            // if it's none of the defined NavigationAction we check for actions
            // passed within the customActions props
            const customAction: CustomAction | undefined = (
              customActions || []
            ).find((item) => item.value == newValue);
            if (customAction) {
              customAction.action();
            }
        }
      }}
    >
      <BottomNavigationAction
        className="action-button-archive"
        showLabel={true}
        label={isArchived ? "Unarchive" : "Archive"}
        value={NavigationAction.ARCHIVE}
        icon={isArchived ? <Unarchive /> : <Archive />}
      />
      {customActions &&
        customActions.map((customAction: CustomAction, index: number) => {
          const Component = customAction.component;
          return <Component key={index} value={customAction.value} />;
        })}
      <Pusher />
      <BottomNavigationAction
        className="action-button-update"
        showLabel={true}
        label="Save & Exit"
        value={NavigationAction.SAVE}
        icon={<Save />}
      />
    </BottomNavigation>
  );
};
