import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { MenuItem } from "../../core/interfaces";
import { Reporting } from "./reporting.react";

interface ReportingContainerProps extends RouteComponentProps<{ id: string }> {
  menuItem: MenuItem;
}

export const ReportingContainer: React.FunctionComponent<
  ReportingContainerProps
> = () => {
  return <Reporting />;
};
