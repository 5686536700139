import * as React from 'react';
import crypto from 'crypto';
import {Grid, Typography} from '@material-ui/core';
import {Column, CellInfo} from 'react-table';
import {
  HighlightOff,
  Assignment,
  CheckCircle,
  Language,
} from '@material-ui/icons';
import {DataManager} from '../../components/dataManager/dataManager.react';
import {Loader} from '../../components/loader/loader.react';
import {builder} from '../../components/formBuilder/formBuilder.react';
import styles from './studies.scss';
import {
  Status,
  RouteProps,
  StudyGroupByBrand,
  Platform,
  Study,
  DataManagerMethods,
  iValidity,
  StudyGroupReportItem,
  StudyGroupDashboardItem,
  StudyGroupType,
  StudyGroup,
  AdCampaignState,
  DropdownOption,
  AdCampaignTypes,
  StudyType,
} from '../../core/interfaces';
import {Firestore} from '../../components/dataManager/firestore';
import {GenerationPanel} from './studiesUrlGenerator.react';
import {getIdToken} from '../../utils/idToken';
import {studyGroupDataTransformer} from '../../utils/studyGroupTransformer';
interface iState {
  isLoading: boolean;
  idToken: string | null;
}
const {Fragment} = React;
const firestore = new Firestore();

/**
 * @function
 * @name hashString
 * @description this is just a duplicate function of the one in cloud-functions:
 * cloud-functions/lib/helpers/redeemCodes/redeemCodes.js
 * we put it here too so we don't need to hit the endpoint to retrieve it (and we don't need to save it in the back end)
 * @param { str }
 * @param { number }
 * @returns { string } the hashed string
 */
function hashString(str: string, length: number): string {
  // add three chars here
  const hashed = crypto.createHash('md5').update(str).digest('hex');
  // we pick the last 6 char
  const reducedHash = hashed.substring(hashed.length - length);
  return reducedHash;
}

function caseInsensitiveSearch(value: string, target: string): boolean {
  return value && target
    ? target.toLowerCase().includes(value.toLowerCase())
    : false;
}

function caseInsensitiveSort(a: string, b: string): number {
  // push the deleted ones at the bottom,
  // this is a bit hacky but not sure why the 'name' (such as feedConfigName or platformName) is set to DELETED
  // and I don't really want to change that logic.
  // On the UI a red crossed circle is displayed for this type of items
  if (a === 'DELETED') {
    return 1;
  }
  if (b === 'DELETED') {
    return -1;
  }

  return a.toLowerCase().localeCompare(b.toLowerCase());
}

function getIdToUse(item: Study): string | undefined {
  return item.type === StudyGroupType.BRAND_STUDY ? item.studyGroupId : item.id;
}

function getCompletionCode(item: Study): string {
  const idToUse = getIdToUse(item);

  if (idToUse !== undefined) {
    return hashString(idToUse, 10);
  }
  return '';
}

export class Studies extends React.Component<RouteProps, iState> {
  private columns: Column[];
  private studyGroupByBrands: StudyGroupByBrand[];
  private selectedBrand: string;
  private selectedStudyGroup: StudyGroupByBrand | {};
  private adCampaigns: DropdownOption[];
  private studyGroups: StudyGroup[];
  private platforms: Platform[];

  constructor(props) {
    super(props);

    this.studyGroupByBrands = [];
    this.selectedBrand = '';
    this.selectedStudyGroup = {};
    this.studyGroups = [];
    this.platforms = [];
    this.adCampaigns = [];
    this.state = {
      isLoading: true,
      idToken: null,
    };
    // columns for table, action column added automatically
    // the Archived and Actions columns are added in data-manager
    this.columns = [
      {
        Header: 'Study Group',
        id: 'studyGroupName',
        accessor: 'studyGroupName',
      },
      {
        Header: 'Id',
        id: 'id',
        accessor: 'id',
      },
      {
        Header: 'Study',
        id: 'name',
        accessor: 'name',
      },
      {
        Header: 'Platform',
        id: 'platform',
        accessor: 'platformName',
        Cell: (row: CellInfo): string => {
          return row.original.hasErrors && row.original.hasErrors.platformId
            ? '--'
            : row.original.platformName;
        },
        filterMethod: (
          filter: {value: any; id: React.ReactText},
          row: {[x: string]: any},
        ): boolean => {
          return caseInsensitiveSearch(
            filter.value,
            row._original.platformName,
          );
        },
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: 'status', // Required because our accessor is not a string
        Cell: (row: CellInfo): React.ReactNode => {
          let icon = <Fragment></Fragment>;
          switch (row.original.status) {
            default:
            case 'demo':
              icon = (
                <Fragment>
                  Demo
                  <Language />
                </Fragment>
              );
              break;
            case 'draft':
              icon = (
                <Fragment>
                  Draft
                  <Assignment />
                </Fragment>
              );
              break;
            case 'live':
              icon = (
                <Fragment>
                  Live
                  <Language />
                </Fragment>
              );
              break;
            case 'finished':
              icon = (
                <Fragment>
                  Finished
                  <CheckCircle />
                </Fragment>
              );
              break;
          }
          return (
            <Typography
              className={`layout-row layout-align-center-center layout-fill is-${row.original.status} ${styles.status}`}
            >
              {icon}
            </Typography>
          );
        },
        filterMethod: (
          filter: {value: any; id: React.ReactText},
          row: {[x: string]: any},
        ): boolean => {
          switch (filter.value) {
            default:
            case 'all':
              return true;
            case Status.DEMO:
            case Status.DRAFT:
            case Status.LIVE:
            case Status.FINISHED:
              return row[filter.id] === filter.value;
          }
        },
        Filter: ({filter, onChange}): JSX.Element => (
          <select
            onChange={(event: React.ChangeEvent<HTMLSelectElement>): void =>
              onChange(event.target.value)
            }
            style={{width: '100%'}}
            value={filter ? filter.value : 'all'}
          >
            <option value="all">Show All</option>
            <option value={Status.DEMO}>Demo</option>
            <option value={Status.DRAFT}>Draft</option>
            <option value={Status.LIVE}>Live</option>
            <option value={Status.FINISHED}>Finished</option>
          </select>
        ),
      },
      {
        Header: 'Feed',
        id: 'feeds',
        accessor: 'feeds',
        Cell: (row: CellInfo): string => {
          return row.original.hasErrors && row.original.hasErrors.feedId ? (
            <div className="layout-fill layout-row layout-align-center-center">
              <HighlightOff className="has-errors" />
            </div>
          ) : (
            row.original.feedName
          );
        },
        filterMethod: (
          filter: {value: any; id: React.ReactText},
          row: {[x: string]: any},
        ): boolean => {
          return caseInsensitiveSearch(filter.value, row._original.feedName);
        },
      },
      {
        Header: 'Completion Code',
        id: 'completionCode',
        accessor: row => {
          return getCompletionCode(row as Study);
        },
        Cell: (row: CellInfo): string => {
          return getCompletionCode(row.original as Study);
        },
        filterMethod: (
          filter: {value: any; id: React.ReactText},
          row: {[x: string]: any},
        ): boolean => {
          const idToUse = getIdToUse(row._original as Study);
          if (idToUse !== undefined) {
            return caseInsensitiveSearch(filter.value, hashString(idToUse, 10));
          }
          return false;
        },
      },
    ];
  }

  async componentDidMount(): Promise<void> {
    try {
      const studyGroupByBrandResponse = await firestore.getStudyGroupByBrands();
      if (!studyGroupByBrandResponse.success) {
        throw new Error(studyGroupByBrandResponse.error);
      }
      this.studyGroupByBrands = studyGroupByBrandResponse.data;
    } catch (e) {
      throw new Error(`Error retrieving  study group by brand list`);
    }

    try {
      const platformResponse = await firestore.getList('platforms');
      if (!platformResponse.success) {
        throw new Error(platformResponse.error);
      }

      this.platforms = platformResponse.data.sort((a: Platform, b: Platform) =>
        a.name.localeCompare(b.name),
      );
    } catch (e) {
      console.error(`Error retrieving platform list: ${e}`);
    }

    try {
      const adCampaignResponse = await firestore.getList('adCampaigns');
      if (!adCampaignResponse.success) {
        throw new Error(adCampaignResponse.error);
      }

      this.adCampaigns = (adCampaignResponse.data || [])
        .reduce(
          (acc: DropdownOption[], d: AdCampaignState): DropdownOption[] => {
            const id = d.id;
            if (
              !(d.archived || false) &&
              (d.campaignType || '') === AdCampaignTypes.MOBILE &&
              id &&
              d.name
            ) {
              acc.push({
                value: id,
                label: d.name,
              });
            }
            return acc;
          },
          [],
        )
        .sort((a: AdCampaignState, b: AdCampaignState) =>
          (a.name || '').localeCompare(b.name || ''),
        );

      // add default options
      this.adCampaigns.unshift({value: '', label: 'None'});
    } catch (e) {
      console.error(`Error retrieving platform list: ${e}`);
    }

    const idToken = await getIdToken();
    this.setState({
      isLoading: false,
      idToken,
    });
  }

  /**
   * @function
   * @name checkValidity
   * @description - Updates the UI to show the field in an error state when the field is invalid
   * Note: This function will only run if the default html5 validation passes
   * @param { iValidity } - validatorObject - Contains all information relating to the field, and how we can validate it
   * @returns { boolean } - if the field is valid or not
   */
  checkValidity(validatorObject: iValidity): boolean {
    const {value, field} = validatorObject;
    let isValid = true;
    switch (field) {
      case 'name':
        isValid = String(value).length > 0;
        break;
      case 'conversionUrl':
        // should start with http or https
        isValid =
          String(value) === ''
            ? true
            : String(value).match(/^https?:\/\/.+/) !== null;
        break;
      case 'duration':
        isValid =
          !isNaN(parseFloat(String(value))) &&
          typeof parseFloat(String(value)) === 'number';
        break;
      default:
        isValid = true;
        break;
    }
    return isValid;
  }

  /**
   * @function
   * @name getPanelContent
   * @description - The jsx template to render in the sidebar panel
   * @param { Study } - clone - the current field clone object
   * @param { DataManagerMethods } - methods - the methods available to use on the fields
   * @returns { React.ReactNode }
   */
  getPanelContent(clone: Study, methods: DataManagerMethods): React.ReactNode {
    if (methods.isEditing && clone.brand) {
      this.selectedBrand = clone.brand;
      this.studyGroups = this.studyGroupByBrands[this.selectedBrand] || [];
      this.selectedStudyGroup =
        this.studyGroups.find(
          studyGroup => studyGroup.id === clone.studyGroupId,
        ) || {};
    }
    return (
      <Grid
        container
        spacing={2}
        className="layout-row layout-align-space-between-center"
      >
        <Grid
          item
          xs={6}
          className="layout-column layout-align-start-center layout-fill children-fill-width"
        >
          {builder(
            [
              {
                required: true,
                key: 'brand',
                label: 'Brand',
                isDisabled: methods.isEditing,
                helperText: 'The brand associated with this study.',
                onChangeEvent: event => {
                  this.selectedBrand = event.target.value;
                  this.selectedStudyGroup = '';
                  this.studyGroups =
                    this.studyGroupByBrands[this.selectedBrand];
                  return {
                    studyGroup: {
                      difficulty: '',
                      duration: '',
                      studyGroupId: '',
                      pxyzCampaignId: '',
                      feedId: '',
                    },
                    studyGroupEvent: true,
                  };
                },
                type: 'select',
                options: Object.keys(this.studyGroupByBrands).map(brand => {
                  return {
                    value: brand,
                    label: brand,
                  };
                }),
              },
              {
                required: true,
                key: 'studyGroupId',
                label: 'Name',
                isDisabled: methods.isEditing,
                helperText: 'The name of this study group.',
                type: 'select',
                options: this.studyGroups.map((campaign: campaign) => {
                  return {
                    value: campaign.id,
                    label: campaign.name,
                  };
                }),
                onChangeEvent: event => {
                  const studyGroupId = event.target.value;
                  this.selectedStudyGroup = this.studyGroups.find(
                    studyGroup => studyGroup.id === studyGroupId,
                  );

                  return {
                    studyGroup: {
                      difficulty: this.selectedStudyGroup.difficulty,
                      duration: this.selectedStudyGroup.duration,
                      pxyzCampaignId: this.selectedStudyGroup.pxyzCampaignId,
                      feedId: this.selectedStudyGroup.feedId,
                    },
                    studyGroupEvent: true,
                  };
                },
              },
              {
                required: true,
                key: 'name',
                label: 'Study Name',
                helperText: 'The name of this study.',
                type: 'text',
              },
              {
                required: false,
                key: 'surveyUrl',
                label: 'Survey URL',
                helperText:
                  'Update the Survey url, should start with http or https',
                type: 'text',
              },
              {
                required: false,
                key: 'conversionUrl',
                label: 'Conversion URL',
                helperText:
                  'Update the conversion url, should start with http or https',
                type: 'text',
              },
            ],
            clone,
            methods,
          )}
        </Grid>
        <Grid
          item
          xs={6}
          className="layout-column layout-align-start-center layout-fill children-fill-width"
        >
          {builder(
            [
              {
                required: true,
                isDisabled: methods.isEditing,
                key: 'platformId',
                label: 'Platform',
                helperText:
                  'Select the plafrom from the dropdown menu to apply to this study.',
                type: 'select',
                options: this.platforms.map(
                  (platformConfig: platformConfig) => ({
                    value: platformConfig.id,
                    label: platformConfig.name,
                  }),
                ),
                onChangeEvent: event => {
                  const pConfig = this.platforms.find(
                    p => p.id === event.target.value,
                  );
                  const conversionUrl = pConfig.conversionUrl
                    ? pConfig.conversionUrl
                    : '';
                  return {
                    value: conversionUrl,
                    target: 'conversionUrl',
                  };
                },
              },
              {
                required: false,
                key: 'adCampaignId',
                label: 'ad campaign',
                helperText: 'Select the ad campaign from the dropdown menu.',
                type: 'select',
                options: this.adCampaigns,
              },
              {
                type:
                  this.selectedBrand === StudyGroupType.DATA_COLLECTION
                    ? 'none'
                    : 'creativeSelector',
                multiline: true,
                key: 'creatives',
                label: 'Creative Build',
                campaignId:
                  this.selectedStudyGroup && clone.studyGroupId
                    ? this.selectedStudyGroup.pxyzCampaignId
                    : '',
                creatives: clone.creatives ? clone.creatives : [],
                ref: '',
                isEditing: methods.isEditing(clone),
              },
              {
                required: true,
                key: 'difficulty',
                label: 'Difficulty',
                readOnly: true,
                isDisabled: true,
                helperText: 'The mode for the application calibration.',
                type: 'text',
              },
              {
                required: true,
                key: 'status',
                label: 'Status',
                helperText: 'The status of this study.',
                type: 'select',
                options: Object.values(Status).map((statusProp: string) => ({
                  value: statusProp.toLowerCase(),
                  label: statusProp,
                })),
              },
              {
                required: true,
                key: 'duration',
                readOnly: true,
                isDisabled: true,
                label: 'Task Duration',
                helperText:
                  'This duration represents how long the task is in the app.',
                type: 'text',
              },
            ],
            clone,
            methods,
          )}
        </Grid>
      </Grid>
    );
  }

  /**
   * @function
   * @name render
   * @description - standard react render method
   * @returns {React.ReactNode} - returns jsx markup
   */
  render(): React.ReactNode {
    const defaultItem: Study = {
      name: '',
      conversionUrl: '',
      status: Status.DRAFT,
      surveyUrl: '',
      difficulty: '',
      duration: '',
      adCampaignId: '',
      feedId: '',
      pxyzCampaignId: '',
      studyGroupId: '',
      creatives: [],
      studyType: StudyType.DISPLAY,
    };
    const props = {
      module: 'studies',
      listName: 'studyGroups',
      name: 'Study',
      plural: `Studies`,
      idToken: this.state.idToken,
      match: this.props.match,
      history: this.props.history,
      columns: this.columns,
      defaultFilterMethod: caseInsensitiveSearch,
      defaultSortMethod: caseInsensitiveSort,
      checkValidity: this.checkValidity.bind(this),
      defaultItem,
      validateItemsById: [
        {
          key: 'feedId',
          mapTo: 'feedName',
          module: 'feeds',
          valueMapper(resolvedResult: Study | boolean): string {
            if (typeof resolvedResult === 'boolean') {
              return resolvedResult === false ? `DELETED` : `UNKOWN`;
            }
            return resolvedResult.name;
          },
        },
        {
          key: 'platformId',
          mapTo: 'platformName',
          module: 'platforms',
          valueMapper(resolvedResult: Study | boolean): string {
            if (typeof resolvedResult === 'boolean') {
              return resolvedResult === false ? `DELETED` : `UNKOWN`;
            }
            return resolvedResult.name;
          },
        },
      ],
      getPanelContent: this.getPanelContent.bind(this),
      transfromDataBeforeRender: (
        data: any,
        showArchived: boolean,
        extra?: StudyGroupReportItem[],
      ): StudyGroupDashboardItem[] => {
        const newKeys = {
          id: 'studyGroupId',
          name: 'studyGroupName',
          archived: 'studyGroupArchived',
        };
        return studyGroupDataTransformer(
          data,
          newKeys,
          {
            showArchived,
            caller: 'study',
          },
          extra,
        );
      },
      attachRowActionButton: (clone: Study): React.ReactNode => {
        if (clone.status !== 'demo') {
          return <GenerationPanel study={clone} />;
        }
        return <div className="empty-action"></div>;
      },
    };

    return this.state.isLoading ? (
      <Loader />
    ) : (
      <DataManager id={styles.studies} {...props}></DataManager>
    );
  }
}
