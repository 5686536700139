/**
 * @function
 * @name dashToCamel
 * @description - Convert hyphens to camel case
 * @param {string} txt - the dash string to convert
 * @returns {string} - the camelcase string
 */
export const dashToCamel = (txt: string): string => {
  return txt.replace(/-([a-z])/g, (g) => {
    return g[1].toUpperCase();
  });
};
