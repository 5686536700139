import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TableHead from "@material-ui/core/TableHead";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as React from "react";
import { TransitionProps } from "@material-ui/core/transitions";
import {
  Box,
  Slide,
  FormControl,
  TextField,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  Tooltip,
} from "@material-ui/core";
import { Theme, pxyzStudioBase, minSDK } from "../../../config";
import { Study } from "../../interfaces";
import compareVersions from "compare-versions";

const { Fragment, useState } = React;

interface GenerationPanelProps {
  children?: React.ReactNode;
  study: Study;
  campaignId: string;
  methods: any;
  isEditing: boolean;
}

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

export function CreativeBuildSelector(
  props: GenerationPanelProps
): React.ReactNode {
  const { study, campaignId, methods, isEditing } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // simple helper method for the dialog
  const onClose = (): void => setIsOpen(false);
  const [textFieldValue, setTextFieldValue] = React.useState<string>("");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [creativesData, setCreativesData] = React.useState<string[]>([]);
  const [loadStudyData, setLoadStudyData] = React.useState<boolean>(false);
  const [currentCampaignId, setCurrentCampaignId] = React.useState<string>("");
  const [errorMsg, setErrorMsg] = React.useState<string>("");

  const { getCreatives } = methods;

  /**
   * Format a build Id name based on a creative source
   * Return
   *
   * @param {array} creative source
   * @returns {string} true build id
   */
  const formatBuildId = (source) => {
    let buildId = "";
    if (source.data) {
      const adId = source.data.playgroundAdId;
      const adRev = source.data.playgroundAdRevision;
      buildId = `${adId}/${adRev}`;
    }
    return buildId;
  };

  /**
   * Get an array of creatives selected by the user
   * Return
   *
   * @param {id} creative id
   * @returns {array} creativeIds
   */
  const getSelectedCreatives = (id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    return newSelected;
  };

  /**
   * Format creative name for input field
   * Return
   *
   * @param {array} creatives
   * @returns {string} a list of creative name (comma separated)
   */
  const formatCreativeName = (selectedCreatives: any[]): string => {
    let creativeNames = "";
    selectedCreatives.forEach((sc, idx, array) => {
      creativeNames += sc.name;
      // Add comma if it is not the last item
      if (idx !== array.length - 1) {
        creativeNames += ", ";
      }
    });
    return creativeNames;
  };

  const initialLoad = (() => {
    if (isEditing && !loadStudyData && study && study.creatives) {
      setLoadStudyData(true);
      setTextFieldValue(formatCreativeName(study.creatives));
      setSelected(study.creatives.map((c) => c.id));
    }
  })();

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedCreativeIds = getSelectedCreatives(id);
    setSelected(selectedCreativeIds);

    const selectedCreatives = selectedCreativeIds.map((creativeId) => {
      const c = creativesData.find((row) => row.id === creativeId);
      return {
        id: creativeId,
        name: `${c.name}(${formatBuildId(c.source)})`,
        format: c.format,
        thumbnail: c.thumbnail,
        sdk: c.sdk,
      };
    });

    setTextFieldValue(formatCreativeName(selectedCreatives));

    // add selected creatives to study obj
    study.creatives = selectedCreatives;
  };

  /**
   * Return a table headt
   * Return
   *
   * @returns {TableHead} return a table head
   */
  const renderTableHeader = () => {
    const headers = ["Creative Name", "Build ID", "Format", "SDK Version"];

    return (
      <TableHead>
        <TableRow>
          <TableCell align="left" padding="none"></TableCell>
          {headers.map((header) => {
            return (
              <TableCell align="left" padding="none" key={header}>
                {header}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  };

  const renderFormatIcon = (row) => {
    const thumbnail = row.thumbnail ? (
      <img className="creative-thumbnail-tooltip" src={row.thumbnail} />
    ) : (
      ""
    );

    return (
      <Tooltip
        key={`tooltip-${row.id}`}
        title={thumbnail}
        placement={"bottom-end"}
        enterDelay={300}
      >
        <div className="format-icon-container">
          <div className={`format-icon ${row.format}`}></div>
        </div>
      </Tooltip>
    );
  };

  const loadTableData = () => {
    if (!isOpen && currentCampaignId !== campaignId) {
      setCurrentCampaignId(campaignId);
      // clean values
      setTextFieldValue("");
      setLoadStudyData(false);
      setSelected([]);
      setIsLoading(true);
      return;
    }

    if (!isOpen && creativesData.length > 0) {
      setCreativesData([]);
      setIsLoading(true);
      return;
    }

    if (isOpen && creativesData.length === 0) {
      getCreatives(campaignId).then((response) => {
        setIsLoading(false);
        if (response.success) {
          setCreativesData(response.data);
          return;
        }
        setErrorMsg(response.error);
      });
    }
  };

  const renderErrorMsg = () => {
    if (errorMsg === "") {
      return null;
    }

    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={5} align="center">
            {errorMsg}
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  const renderLoading = () => {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={5} align="center">
            <CircularProgress />
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  const renderTableBody = () => {
    loadTableData();

    if (isLoading) {
      return renderLoading();
    }

    const isSelected = (id: string) => {
      return selected.indexOf(id) !== -1;
    };

    return (
      <TableBody>
        {renderErrorMsg()}
        {creativesData.map((row: any) => {
          const isItemSelected = isSelected(row.id);
          const pxyzStudioUrl = `${pxyzStudioBase}${formatBuildId(row.source)}`;
          const isSDKSupported = compareVersions(row.sdk, minSDK);

          return (
            <TableRow
              onClick={(event) => handleClick(event, row.id)}
              hover
              selected={isItemSelected}
              key={row.id}
            >
              <TableCell padding="checkbox">
                {isSDKSupported >= 0 ? (
                  <Checkbox checked={isItemSelected} />
                ) : null}
              </TableCell>
              <TableCell align="left">
                <a
                  href={pxyzStudioUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {row.name}
                </a>
              </TableCell>
              <TableCell align="left">{formatBuildId(row.source)}</TableCell>
              <TableCell align="left">{renderFormatIcon(row)}</TableCell>
              <TableCell align="left">{row.sdk}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  // we do not display the table header while loading data
  const renderTable = () => {
    return (
      <Table>
        {isLoading ? null : renderTableHeader()}
        {renderTableBody()}
      </Table>
    );
  };

  const renderDialog = () => {
    return (
      <Dialog fullWidth={true} TransitionComponent={Transition} open={isOpen}>
        <DialogContent dividers={true}>
          <Box py={5}>{renderTable()}</Box>
        </DialogContent>
        <DialogActions>
          <Theme>
            <Button color="primary" autoFocus onClick={onClose}>
              ok
            </Button>
          </Theme>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Fragment key="creativeIds">
      <FormControl key="form-creativeId">
        <TextField
          key="text-input-cretive-ids"
          InputLabelProps={{ shrink: true }}
          inputProps={{
            name: "creativeIds",
            id: `creativeIds`,
            readOnly: true,
          }}
          label="Creative (build ID)"
          variant="outlined"
          value={textFieldValue}
          margin="normal"
          onClick={(): void => {
            if (campaignId) {
              setIsOpen(!isOpen);
            }
          }}
        />
      </FormControl>
      {renderDialog()}
    </Fragment>
  );
}
