import _ from "lodash";
import React, { Fragment, useState } from "react";
import { Grid, Button, Snackbar, SnackbarContent } from "@material-ui/core";
import {
  StudyGroup,
  StudyFromStudyGroup,
  CreativeFromStudy,
  CreativesPageSample,
  CreativesModelledAttention,
  AttentionBenchmark,
  StudyReportStudyItem,
  StudyReportItem,
} from "~src/core/interfaces";
import { dateFormats } from "../../../config";
import styles from "./reporting.scss";
import { ReportingFilters } from "../../components/filters/reportingFilters.react";
import { ReportingTable } from "./reportingTable.react";
import { Timestamp } from "../../components/timestamp/timestamp.react";
import { studyColumns, creativesColumns } from "./columns.react";
import VpApi from "../../components/dataManager/vpApi";

interface CreativesForTable {
  format: string;
  id: string;
  name: string;
  sdk: string;
  studyId: string;
  creativeId: string;
  totalFixation: number;
  totalImps: number;
  modelledAttention: number;
  time: number;
}

const getErrorMessage = (result: any): string => {
  const errorMsg = _.get(result, "error.message");
  return errorMsg ? `Error: ${errorMsg}` : "";
};

function getCreativesForTable(
  creativesForTable: CreativesForTable[],
  study: StudyFromStudyGroup,
  studyReportData: StudyReportItem | undefined,
  benchmarkReportData: any
) {
  creativesForTable = study.creatives.map((sc: CreativeFromStudy) => {
    const creativePageSample =
      studyReportData &&
      studyReportData.creativesPageSample &&
      studyReportData.creativesPageSample.length
        ? studyReportData.creativesPageSample.find(
            (cps: CreativesPageSample) => cps.creativeId === sc.id
          )
        : null;
    const creativesModelledAttention =
      studyReportData &&
      studyReportData.creativesModdledAttention &&
      studyReportData.creativesModdledAttention.length
        ? studyReportData.creativesModdledAttention.find(
            (cma: CreativesModelledAttention) => cma.creativeId === sc.id
          )
        : null;
    const benchMark =
      benchmarkReportData.attentionBenchmark &&
      benchmarkReportData.attentionBenchmark.length
        ? benchmarkReportData.attentionBenchmark.find(
            (bm: AttentionBenchmark) => bm.format === sc.format
          )
        : null;

    return {
      ...sc,
      ...creativePageSample,
      ...creativesModelledAttention,
      ...benchMark,
    };
  });
  return creativesForTable;
}
export const Reporting: React.FunctionComponent = () => {
  // state management
  const [studyReport, setStudyReport] = useState<{
    study: StudyReportStudyItem | null;
    creatives: CreativesForTable[];
    lastUpdated: number | null;
  }>({
    study: null,
    creatives: [],
    lastUpdated: null,
  });

  const [notification, setNotification] = useState({
    msg: "",
    type: "",
    show: false,
  });

  // helper functions
  const clearSnackBar = (): void => {
    setNotification({
      msg: "",
      type: "",
      show: false,
    });
  };

  return (
    <Fragment>
      <div id={styles.reporting}>
        <div className="layout-row layout-align-space-between-center">
          <h1>Reporting</h1>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ReportingFilters
              filters={["studyGroup", "study"]}
              className={"layout-row layout-row-spaced"}
              clearSnackBar={clearSnackBar}
              button={{
                text: "Run Report",
                validateInputs: ({
                  studyGroup,
                  study,
                }: {
                  studyGroup: StudyGroup;
                  study: StudyFromStudyGroup;
                }): { type: string; helperText: string } | null => {
                  if (!studyGroup || !study) {
                    return {
                      type: "study",
                      helperText: "Invalid filter selection",
                    };
                  }
                  return null;
                },
                onClick: async ({
                  studyGroup,
                  study,
                  idToken,
                }: {
                  studyGroup: StudyGroup;
                  study: StudyFromStudyGroup;
                  idToken: string;
                }): Promise<void> => {
                  setStudyReport({
                    study: null,
                    creatives: [],
                    lastUpdated: null,
                  });

                  if (!study) {
                    return;
                  }
                  let creativeIds = "";
                  let adFormats = "";

                  if (study.creatives && study.creatives.length) {
                    creativeIds = study.creatives
                      .map((c: CreativeFromStudy) => c.id)
                      .filter((c: string | null) => !!c)
                      .join(",");

                    adFormats = [
                      ...new Set(
                        study.creatives
                          .map((i: CreativeFromStudy) => i.format)
                          .filter((d) => !!d)
                      ),
                    ].join(",");
                  }

                  // hit vp-api endpoint
                  const vpApi = new VpApi(idToken);

                  await Promise.all([
                    vpApi.getStudyReport({
                      studyId: study.id,
                      creativeIds,
                      campaignId: studyGroup.campaignId,
                    }),
                    vpApi.getBenchamrkReport(adFormats),
                  ])
                    .then(([studyReportResult, benchmarkReportResult]) => {
                      if (
                        !studyReportResult.success ||
                        !benchmarkReportResult.success
                      ) {
                        setNotification({
                          msg: `Couldn't generate the report. ${getErrorMessage(
                            studyReportResult
                          )} ${getErrorMessage(benchmarkReportResult)}`,
                          type: "error",
                          show: true,
                        });
                        return;
                      }

                      const studyReportData = studyReportResult.data;
                      const benchmarkReportData = benchmarkReportResult.data;

                      // need to build the array of data for the creative table
                      let creativesForTable: CreativesForTable[] = [];
                      creativesForTable = getCreativesForTable(
                        creativesForTable,
                        study,
                        studyReportData,
                        benchmarkReportData
                      );

                      setStudyReport({
                        study: studyReportData ? studyReportData.study : null,
                        creatives: creativesForTable,
                        lastUpdated: studyReportData
                          ? studyReportData.lastUpdated
                          : null,
                      });
                    })
                    .catch((err) => {
                      console.error(err);
                      setNotification({
                        msg: `Couldn't generate the report`,
                        type: "error",
                        show: true,
                      });
                    });
                },
              }}
            />
          </Grid>
        </Grid>
        {studyReport.study ? (
          <ReportingTable
            title="Study"
            data={[studyReport.study]}
            columns={studyColumns}
          />
        ) : null}
        {studyReport.creatives.length ? (
          <ReportingTable
            title="Creatives"
            data={studyReport.creatives}
            columns={creativesColumns}
          />
        ) : null}
        {studyReport.lastUpdated ? (
          <Timestamp
            text={`Report last updated at`}
            timestamp={studyReport.lastUpdated}
            format={dateFormats.footer}
          />
        ) : null}
        <Snackbar
          className="snackbar"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={notification.show}
          autoHideDuration={5000}
        >
          <SnackbarContent
            className={notification.type}
            message={
              <span id="client-snackbar">
                {notification.msg}
                <Button
                  style={{ color: "white" }}
                  size="small"
                  variant="text"
                  onClick={(): void => {
                    // hide the snackbar
                    setNotification({
                      msg: "",
                      type: "",
                      show: false,
                    });
                  }}
                >
                  X
                </Button>
              </span>
            }
          />
        </Snackbar>
      </div>
    </Fragment>
  );
};
