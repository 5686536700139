/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react';
import {Theme, desktopWebsiteUrl, landingPage} from '../../../config';
import {
  TextField,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  IconButton,
} from '@material-ui/core';
import {Firestore} from '../../components/dataManager/firestore';
import {
  DeviceType,
  Platform,
  SnackBarStateParams,
  SnackbarType,
  StudyData,
  StudyType,
  DifficultyType,
  Status,
} from '../../core/interfaces';
import {vpApiInstance} from '../../components/dataManager/vpApiInstance';
import {SnackBarAlert} from '../../components/alerts/snackBarAlert';
import {CheckCircle} from '@material-ui/icons';
import Copy from './copy.png';
import {downloadStudyURL} from '../../utils/studyUrlDownload';
import {DURATION_CODES} from '../../core/constants';
import {generateCompletionCode} from '../../utils/completionCode';

const firestore = new Firestore();

interface ErrorMessage {
  msg: string;
  show: boolean;
}

export const StudiesVideo: React.FunctionComponent<{}> = () => {
  const [type, setType] = useState<DeviceType>(DeviceType.DESKTOP);
  const [name, setName] = useState<string | null>(null);
  const [desktopFeeds, setDesktopFeeds] = useState<any[]>([]);
  const [mobileFeeds, setMobileFeeds] = useState<any[]>([]);
  const [duration, setDuration] = useState<number | null>(null);
  const [feedId, setFeedId] = useState<string | null>(null);
  const [platformId, setPlatformId] = useState<string | null>(null);
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [surveyUrl, setSurveyUrl] = useState<string | null>(null);
  const [conversionUrl, setConversionUrl] = useState<string | null>(null);
  const [studyId, setStudyId] = useState<string | null>(null);
  const [studyUrl, setStudyUrl] = useState<string | null>(null);
  const [completionCode, setCompletionCode] = useState<string | null>(null);
  const [urlAmount, setUrlAmount] = useState<number>(10);
  // create the state managers for the identifier field
  const [amountError, setAmountError] = useState<ErrorMessage>({
    show: false,
    msg: '',
  });
  const [snackBarState, setSnackBarState] = useState<SnackBarStateParams>({
    isSnackBarOpen: false,
    snackBarContent: '',
    snackBarType: SnackbarType.success,
  });
  const studyURLRef = useRef<null | HTMLElement>(null);
  const optionalfields = {
    [DeviceType.DESKTOP]: ['surveyComplete'],
    [DeviceType.MOBILE]: [],
    all: ['conversionUrl'],
  };

  const scrollToBottom = (): void => {
    studyURLRef.current?.scrollIntoView({behavior: 'smooth'});
  };

  const onChangeHandler = (
    setStateAction?: Dispatch<SetStateAction<string | number | null>>,
  ): ((e: any) => void) => {
    return (e: any): void => {
      if (setStateAction != null) {
        setStateAction(e.target.value);
      }
    };
  };

  const handleCopy = (copy: string): void => {
    void navigator.clipboard.writeText(copy);
    setSnackBarState({
      isSnackBarOpen: true,
      snackBarContent: 'Successfully copied to clipboard!',
      snackBarType: SnackbarType.success,
    });
  };

  const generateStudyUrl = (id: string): void => {
    if (type === DeviceType.DESKTOP) {
      const url = `${desktopWebsiteUrl}?studyId=${id}`;
      setStudyUrl(url);
    } else {
      const url = `${landingPage}${id}`;
      setStudyUrl(url);
    }
  };

  const handleChangeStudyType = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const target = event.target.value as DeviceType;
    setType(target);
  };

  const getPlatforms = async (): Promise<void> => {
    try {
      const platformResponse = await firestore.getList('platforms');
      if (!platformResponse.success) {
        throw new Error(platformResponse.error);
      }

      const result = platformResponse.data.sort((a: Platform, b: Platform) =>
        a.name.localeCompare(b.name),
      );
      setPlatforms(result);
    } catch (e) {
      console.error(`Error retrieving platform list: ${e}`);
    }
  };

  const getFeeds = (): void => {
    firestore
      .getList('feeds', false)
      .then((res: any) => {
        const desktopData = (res.data || []).reduce((acc: any, d: any) => {
          if (!d.archived && d.feedType === DeviceType.DESKTOP) {
            acc.push({
              id: d.id,
              name: d.name,
            });
          }
          return acc;
        }, []);
        setDesktopFeeds(desktopData);
        const mobileData = (res.data || []).reduce((acc: any, d: any) => {
          if (!d.archived && d.feedType === DeviceType.MOBILE) {
            acc.push({
              id: d.id,
              name: d.name,
            });
          }
          return acc;
        }, []);
        setMobileFeeds(mobileData);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const isOptionalField = (field: string): boolean => {
    // Get the optional fields for the specified device type
    // plus the optional fields for all devices
    const deviceOptionalFields = [
      ...optionalfields[type],
      ...optionalfields.all,
    ];

    // if no optional fields are found for a device
    // then all the fields are mandatory
    if (!deviceOptionalFields) {
      return false;
    }

    // Check if the given 'field' exists in the optional fields config
    const isFieldOptional = deviceOptionalFields.some(f => {
      if (typeof f === 'string') {
        return field === f;
      }

      if (typeof f === 'object') {
        const keys = Object.keys(f);
        if (!keys.includes(field)) {
          return false;
        }
        return keys.some(k => {
          const val = f[field];
          return !val;
        });
      }
      return false;
    });
    return isFieldOptional;
  };

  const isFormValid = (study: StudyData): boolean => {
    const errorFieldName = Object.keys(study).find(field => {
      const isFieldOption = isOptionalField(field);
      if (isFieldOption) {
        return false;
      }
      // check if the value is empty
      return !study[field];
    });

    if (errorFieldName) {
      setSnackBarState({
        isSnackBarOpen: true,
        snackBarContent: `The field '${errorFieldName}' is required`,
        snackBarType: SnackbarType.warning,
      });
    }
    return !errorFieldName;
  };

  const clearForm = (): void => {
    setName('');
    setDuration(null);
    setFeedId(null);
    setPlatformId(null);
    setSurveyUrl('');
    setConversionUrl('');
    setStudyUrl(null);
    setCompletionCode(null);
  };

  const createStudy = async (): Promise<void> => {
    const study: StudyData = {
      name: name ?? '',
      deviceType: type,
      duration: duration ?? null,
      feedId: feedId ?? '',
      platformId: platformId ?? '',
      studyType: StudyType.OLV,
      surveyUrl: surveyUrl ?? '',
      conversionUrl: conversionUrl ?? '',
      difficulty: DifficultyType.HARD,
      status: Status.LIVE,
      brand: 'Data Collection',
    };
    const result = isFormValid(study);
    if (!result) {
      return;
    }
    const vpApi = await vpApiInstance();
    if (!vpApi) {
      setSnackBarState({
        isSnackBarOpen: true,
        snackBarContent: 'Cannot connect vp api',
        snackBarType: SnackbarType.error,
      });
      return;
    }
    const response = await vpApi.saveStudy('study', study);
    // display snack bar according to the result
    if (!response.success) {
      setSnackBarState({
        isSnackBarOpen: true,
        snackBarContent: response.errors
          .map((item: any) => {
            return item.message || 'An error occurred';
          })
          .join(','),
        snackBarType: SnackbarType.error,
      });
      return;
    }
    setStudyId(response.id);
    generateStudyUrl(response.id);
    generateCompletionCode(
      {feedId: feedId ?? '', duration: duration ?? 0},
      setCompletionCode,
    );
    scrollToBottom();
  };

  useEffect(() => {
    void getPlatforms();
    getFeeds();
  }, []);

  return (
    <React.Fragment>
      <Theme>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '30px',
          }}
        >
          <h1>SETUP NEW STUDY</h1>
          <h3>SELECT DEVICE TYPE * </h3>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={type}
            onChange={handleChangeStudyType}
          >
            <FormControlLabel
              value={DeviceType.DESKTOP}
              control={<Radio color="primary" />}
              label="DESKTOP"
            />
            <FormControlLabel
              value={DeviceType.MOBILE}
              control={<Radio color="primary" />}
              label="MOBILE"
            />
          </RadioGroup>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '600px',
            }}
          >
            <h3>STUDY NAME * </h3>
            <TextField
              label={'NEW STUDY NAME'}
              variant="outlined"
              margin="normal"
              defaultValue={name}
              value={name}
              name="name"
              onChange={onChangeHandler(setName)}
              inputProps={{
                autoComplete: 'off',
              }}
            />
            <h3>SELECT FEED * </h3>
            <TextField
              select
              label={'FEED'}
              variant="outlined"
              margin="normal"
              required
              value={feedId}
              error={false}
              helperText={''}
              onChange={onChangeHandler(setFeedId)}
              name="env"
            >
              {type === DeviceType.DESKTOP
                ? desktopFeeds.map((option: any): React.ReactNode => {
                    return (
                      <MenuItem key={option.id} value={option.id}>
                        {`${option.name}`}
                      </MenuItem>
                    );
                  })
                : mobileFeeds.map((option: any): React.ReactNode => {
                    return (
                      <MenuItem key={option.id} value={option.id}>
                        {`${option.name}`}
                      </MenuItem>
                    );
                  })}
            </TextField>
            <h3>SELECT DURATION *</h3>
            <TextField
              select
              label={'DURATION'}
              variant="outlined"
              margin="normal"
              required
              value={duration}
              error={false}
              helperText={''}
              onChange={onChangeHandler(setDuration)}
              name="env"
            >
              {DURATION_CODES.map((option: any): React.ReactNode => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {`${option.name}`}
                  </MenuItem>
                );
              })}
            </TextField>
            <h3>SELECT PLATFORM * </h3>
            <TextField
              select
              label={'PLATFORM'}
              variant="outlined"
              margin="normal"
              required
              value={platformId}
              error={false}
              helperText={''}
              onChange={onChangeHandler(setPlatformId)}
              name="env"
            >
              {platforms.map((option: any): React.ReactNode => {
                return (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                );
              })}
            </TextField>
            <h3>SURVEY URL</h3>
            <TextField
              label={'SURVEY URL'}
              variant="outlined"
              margin="normal"
              value={surveyUrl}
              name="SURVEY URL"
              onChange={onChangeHandler(setSurveyUrl)}
              inputProps={{
                autoComplete: 'off',
              }}
            />
            <React.Fragment>
              <h3>CONVERSION URL</h3>
              <TextField
                label={'HTTPS://'}
                variant="outlined"
                margin="normal"
                value={conversionUrl}
                name="CONVERSION URL"
                onChange={onChangeHandler(setConversionUrl)}
                inputProps={{
                  autoComplete: 'off',
                }}
              />
            </React.Fragment>
          </div>
          <div>
            <Button
              style={{width: '300px', height: '50px', marginTop: '40px'}}
              onClick={() => {
                void createStudy();
              }}
              variant="contained"
              color="primary"
            >
              CREATE STUDY
            </Button>
            <Button
              style={{
                width: '300px',
                height: '50px',
                marginTop: '40px',
                marginLeft: '20px',
              }}
              onClick={() => {
                clearForm();
              }}
              variant="contained"
              color="default"
            >
              CLEAR FORM
            </Button>
          </div>
        </div>
        {studyUrl ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '30px',
              color: 'primary',
              backgroundColor: '#F0F0EE',
              marginBottom: '80px',
            }}
            ref={studyURLRef}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CheckCircle htmlColor="#63C873" />
              <h2
                style={{
                  margin: '0px',
                  marginLeft: '20px',
                }}
              >
                STUDY CREATED
              </h2>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <h3
                style={{
                  margin: '0px',
                }}
              >
                STUDY URL:
              </h3>
              <h3
                style={{
                  margin: '0px',
                  marginLeft: '20px',
                }}
              >
                {studyUrl}
              </h3>
              <IconButton onClick={() => handleCopy(studyUrl ?? '')}>
                <img width="25px" height="25px" src={Copy} />
              </IconButton>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <h3
                style={{
                  margin: '0px',
                }}
              >
                COMPLETION CODE:
              </h3>
              <h3
                style={{
                  margin: '0px',
                  marginLeft: '20px',
                }}
              >
                {completionCode}
              </h3>
              <IconButton onClick={() => handleCopy(completionCode ?? '')}>
                <img width="25px" height="25px" src={Copy} />
              </IconButton>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <TextField
                type="number"
                required
                InputLabelProps={{shrink: true}}
                inputProps={{
                  name: `amount-of-urls`,
                  id: `amount-of-urls`,
                  min: 1,
                  max: 10000,
                  step: 1,
                }}
                error={amountError.show}
                label="Generation amount"
                helperText={
                  amountError.show
                    ? amountError.msg
                    : `The amount of url's to generate`
                }
                value={urlAmount}
                variant="outlined"
                onChange={e => {
                  const value = parseInt(e.target.value, 10);
                  setUrlAmount(value);
                }}
                margin="normal"
              />
              <Button
                color="primary"
                onClick={() => {
                  if (urlAmount > 0) {
                    void downloadStudyURL(type, studyId ?? '', urlAmount);
                  } else {
                    setAmountError({
                      show: true,
                      msg: `Value is invalid`,
                    });
                  }
                }}
              >
                Download
              </Button>
            </div>
          </div>
        ) : null}
      </Theme>
      <SnackBarAlert
        onClose={(): void =>
          setSnackBarState({...snackBarState, isSnackBarOpen: false})
        }
        open={snackBarState.isSnackBarOpen}
        contentProps={{
          content: snackBarState.snackBarContent,
          className: snackBarState.snackBarType,
        }}
      />
    </React.Fragment>
  );
};
