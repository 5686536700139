import React, { Fragment, useState } from "react";
import { Theme } from "../../../config";
import { Header } from "../../components/header/header";
import {
  capitalizeCell,
  countItems,
} from "../../components/table/cellFormatter";
import { actionColumn, archiveColumn } from "../../components/table/columns";
import { TableView } from "../../components/table/table";
import { AdCampaignPane } from "./adCampaignPane";
import { RouteComponentProps } from "react-router";
import { loadEntityHook } from "../../core/hooks/loadEntityHook";
import { AdCampaignState } from "../../core/interfaces";

export const AdCampaigns: React.FunctionComponent<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const initialEntityState: AdCampaignState = {
    archived: false,
    campaignType: "",
    creatives: [],
  };

  const [isPaneOpen, setIsPaneOpen] = useState<boolean>(false);
  const [showArchived, setShowArchived] = useState<boolean>(false);

  // used to check if we are accessing an adCampaign straight from the url
  const currentItemId = props.match.params["id"];

  const { currentEntity, setCurrentEntity, list, isLoading } =
    loadEntityHook<AdCampaignState>({
      showArchived,
      path: "adCampaign",
      currentItemId,
      setIsPaneOpen,
      initialEntityState,
    });

  const columns = [
    {
      Header: "Ad Campaign",
      id: "adCampaign",
      accessor: "name",
      Cell: capitalizeCell("original.name"),
    },
    {
      Header: "Id",
      id: "id",
      accessor: "id",
    },
    {
      Header: "Type",
      id: "type",
      accessor: "campaignType",
      Cell: capitalizeCell("original.campaignType"),
    },
    {
      Header: "Creatives Count",
      id: "creativesCount",
      Cell: countItems("original.creatives"),
      filterable: false,
    },
    { ...archiveColumn },
    {
      ...actionColumn({
        viewEdit: {
          onClick: (item: AdCampaignState): void => {
            // update the url bar
            window.history.pushState(
              {},
              "",
              `${window.location.pathname}/${item.id}`
            );
            setIsPaneOpen(true);
            setCurrentEntity(item);
          },
        },
      }),
    },
  ];

  return (
    <Fragment>
      <Header
        title="AdCampaigns"
        toolTip={{
          onClick: (): void => {
            setCurrentEntity(initialEntityState);
            setIsPaneOpen(true);
          },
        }}
      />
      <TableView
        columns={columns}
        data={list}
        isLoading={isLoading}
        params={{
          overflowAndScroll: true,
          archive: {
            showArchived,
            onClick: (): void => {
              setShowArchived(!showArchived);
            },
          },
        }}
      />
      <Theme>
        <AdCampaignPane
          onRequestClose={(): void => {
            setIsPaneOpen(false);
            setCurrentEntity(initialEntityState);
            window.history.replaceState({}, "", `/adCampaigns`);
          }}
          setIsPaneOpen={setIsPaneOpen}
          isPaneOpen={isPaneOpen}
          originalItem={currentEntity}
          setOriginalItem={setCurrentEntity}
        />
      </Theme>
    </Fragment>
  );
};
