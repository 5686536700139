import * as React from "react";
import { LinearProgress } from "@material-ui/core";
import styles from "./loader.scss";

export function Loader(): React.FunctionComponentElement<React.ReactNode> {
  return (
    <div className={styles.loader}>
      <LinearProgress />
      <br />
      <LinearProgress color="secondary" />
    </div>
  );
}
