import { Button, Card, CardContent, Popover } from "@material-ui/core";
import React from "react";

interface PopoverButtonProps {
  icon: JSX.Element;
  content: string | JSX.Element;
}

export const PopoverButton: React.FunctionComponent<PopoverButtonProps> = ({
  icon,
  content,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div style={{ display: "inline-flex" }}>
      <Button aria-describedby={id} color="primary" onClick={handleClick}>
        {icon}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Card>
          <CardContent>{content}</CardContent>
        </Card>
      </Popover>
    </div>
  );
};
