import { FeedItem, FeedReport, DomainBreakdown } from "../../core/interfaces";

interface Appearances {
  [key: string]: number;
}

/**
 * @description Makes sure the users maxShare value wont impact or create a recursive loop
 * by using the expected feed length as the absolute minimum maxShare we can use
 * @name sanitizeMaxShare
 * @function
 * @param {Float} maxShare - represented as a percentage from 0.1-1, how often should a domain been included in the feed.
 * @param {int} expectedLength - if we know that we want 4 items and the max share is 0.5, we need to sanitize the value
 * so it doesn't end in a recursive loop
 * @returns {Float} the sanitized maxShare value
 */
const sanitizeMaxShare = (maxShare: number, expectedLength: number): number => {
  // ensures the expectedLength >= maxShare
  if (expectedLength < maxShare) {
    expectedLength = Math.ceil(maxShare);
  }
  // update the maxShare if it's not defined correctly
  maxShare = parseFloat(maxShare) || 0.2;
  // we need to update the share value if the expected value will never
  // mathematically work on the feed array, example:
  // if we have a feed length of 4, 4 unique tasks each with a different domain
  // their current share value in that feed is 0.25, if we set the maxShare to 0.2
  // it will always ditch the last item and recursively fail.
  const safeShareValue = 1 / expectedLength;
  // don't update when maxShare is 1, basically turns the mode off
  if (maxShare < safeShareValue && maxShare !== 1) {
    maxShare = safeShareValue;
  }
  // store the maxshare values
  return Math.max(Math.min(maxShare, 1), 0.1);
};

/**
 * @function
 * @name getFeedReport
 * @description Will report and validate certain rules that the feed should be adhering to
 * This is used on the feed config panel to produce a table of the results for product
 * managers.
 * @param {FeedItem[]} feed - the feed you want to produce a report on
 * @param {FeedConfig} config - currently only using maxShare and length from the original feedConfig object
 * @returns {FeedReport} The report object from the input data.
 */
export const getFeedReport = (
  feed: FeedItem[],
  {
    maxShare,
    length,
  }: {
    maxShare: number;
    length: number;
  }
): FeedReport => {
  const assumedMaxShare = sanitizeMaxShare(maxShare, feed.length);
  const all = feed.map((task: FeedItem) => task.domain);
  const appearances: Appearances = all.reduce(
    (data: {}, domain: string) => ({
      ...data,
      [domain]: ++data[domain] || 1,
    }),
    {}
  );
  const perDomainBreakdown: DomainBreakdown[] = Object.entries(appearances).map(
    ([domain, count]: [string, number]) => {
      return {
        count,
        domain,
        percentage: (count / feed.length) * 100,
      };
    },
    []
  );
  // just an array of all the domain percentage appearances
  const percentages: number[] = perDomainBreakdown.map(
    ({ percentage }) => percentage
  );
  // all the percentage values should equal up to 100% if math worked correctly.
  const isValidTotals: boolean =
    Math.round(
      percentages.reduce((total, percentage) => total + percentage, 0)
    ) === 100;
  // no individual domain should breach the maxShare value, so bring in the largest percentage number;
  const largestMaxSharePercentage: number = Math.max(...percentages);
  // for reporting, list the minimum max share value as ell
  const smallestMaxSharePercentage: number = Math.min(...percentages);
  // validate the output length
  const isValidLength: boolean = feed.length === length;
  // make sur the totals are valid and no maxShare breaches the main maxShare value
  const isMaxShareValid: boolean =
    isValidTotals && largestMaxSharePercentage <= assumedMaxShare * 100;
  // create an object containing all the rules so we can read them individually
  const validity = {
    isMaxShareValid,
    isValidLength,
    isValidTotals,
  };
  // export the data for reports or tests
  return {
    // we're valid when every rule has passed
    isValid: Object.values(validity).every((result) => result === true),
    // a reference if we want to check individual rules and what's gone wrong.
    validity,
    // provide this in the report, might be handy to know if the max share
    // has been assumed at all from the passed in config value.
    assumedMaxShare,
    // the actual output of the feed length
    feedLength: feed.length,
    // the largest maxShare value
    largestMaxSharePercentage,
    // the smalled maxShare value
    smallestMaxSharePercentage,
    // shows how many articles are available in the feed, per domain,
    // their maxShare is also available.
    perDomainBreakdown,
  };
};
