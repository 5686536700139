import {DURATION_CODES} from '../core/constants';

export const generateCompletionCode = (
  params: {feedId: string; duration: number},
  callback: any,
): void => {
  const {feedId, duration} = params;

  const durationString = getDuration(duration);
  const code =
    feedId.substring(0, 3) +
    durationString.substring(0, 2) +
    feedId.substring(feedId.length - 4, feedId.length - 1);
  callback(code);
};

const getDuration = (d: number): string => {
  const result = DURATION_CODES.find(a => a.value === d);
  return result?.description ?? '';
};
