import * as React from 'react';
import {TransitionProps} from '@material-ui/core/transitions';
import {
  Snackbar,
  SnackbarContent,
  Box,
  Grid,
  Slide,
  AppBar,
  Toolbar,
  FormControl,
  TextField,
  Typography,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  Tooltip,
  Fab,
} from '@material-ui/core';
import {BlurOn} from '@material-ui/icons';
import {Theme, cloudFunctionBase, landingPage} from '../../../config';
import {Study, RedeemCode} from '../../core/interfaces';
import styles from './studies.scss';
import {getIdToken} from '../../utils/idToken';
const {Fragment, useState, useRef} = React;

interface GenerationPanelProps {
  children?: React.ReactNode;
  study: Study;
}

interface ErrorMessage {
  msg: string;
  show: boolean;
}

interface NotificationType {
  show: boolean;
  msg: string;
  type: string;
}

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  },
);

const landingPageUrl = (studyId: string): string => {
  return `${landingPage}${studyId}`;
};

export function GenerationPanel(props: GenerationPanelProps): React.ReactNode {
  const {study} = props;
  // create a reference link to the identifier field so we can
  // check if it's valid or not
  let urlField: HTMLInputElement | null = null;
  let identifierField: HTMLInputElement | null = null;
  let amountField: HTMLInputElement | null = null;
  // create the state managers for the dialog
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notification, setNotification] = useState<NotificationType>({
    msg: '',
    type: '',
    show: false,
  });
  const defaultRef = useRef(notification);
  const successRef = useRef(notification);
  const errorRef = useRef(notification);
  defaultRef.current = {
    msg: '',
    type: '',
    show: false,
  };
  successRef.current = {
    type: 'success',
    show: true,
    msg: `Updated firestore with study urls.`,
  };
  errorRef.current = {
    type: 'error',
    show: true,
    msg: `Couldn't update firestore with unique urls`,
  };
  // create the state managers for the identifier field
  const [identifier, setIdentifier] = useState<string>('');
  // create the state managers for the identifier field
  const [identifierError, setIdentifierError] = useState<ErrorMessage>({
    show: false,
    msg: '',
  });
  // create the state managers for the identifier field
  const [amountError, setAmountError] = useState<ErrorMessage>({
    show: false,
    msg: '',
  });
  // create the state managers for the amount field
  const [urlAmount, setUrlAmount] = useState<number>(10);
  // simple helper method for the dialog
  const onClose = (): void => setIsOpen(false);

  const copyUrlButton = () => {
    const text = {
      original: 'Copy Url',
      actioned: 'URL Copied',
    };
    const [title, setTitle] = useState(text.original);

    return (
      <Button
        disabled={isLoading}
        color="primary"
        autoFocus
        onClick={(): void => {
          const urlInput = urlField as HTMLInputElement;
          navigator.clipboard.writeText(urlInput.value);
          // update button text
          setTitle(text.actioned);
          setTimeout(() => {
            setTitle(text.original);
          }, 3000);
        }}
      >
        {title}
      </Button>
    );
  };

  /**
   * @function
   * @async
   * @name updateRedeemCodes
   * @description - Insert new records in the RedeemCodes collection in firestore
   * @returns {Promise<Array<RedeemCode>>} array of redeem codes that have been generated
   */
  async function updateRedeemCodes(): Promise<Array<RedeemCode>> {
    let redeemCodes = [];
    const idToken = await getIdToken();
    const headers = new Headers({
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    });
    setIsLoading(true);
    const response = await fetch(
      `${cloudFunctionBase}/redeem-codes/generateRedeemCodes`,
      {
        method: 'post',
        body: JSON.stringify({
          studyId: study.id,
          shouldGenerateCompletionCode: true,
          codesAmount: urlAmount,
        }),
        headers,
      },
    );
    redeemCodes = await response.json();
    setIsLoading(false);
    return redeemCodes;
  }

  /**
   * @function
   * @description - Validates the data before downloading, generates X amount of URL's, one per row of the csv
   * each url will be added to firestore.
   * @param {React.ChangeEvent<HTMLInputElement>} event - the event from the changed event on the field.
   * @returns {void} no return value;
   */
  async function onDownload(): Promise<void> {
    const identifierInput = identifierField as HTMLInputElement;
    const amountInput = amountField as HTMLInputElement;

    setIdentifierError({
      show: !identifierInput.validity.valid,
      msg: identifierInput.validationMessage,
    });

    setAmountError({
      show: !amountInput.validity.valid,
      msg: amountInput.validationMessage,
    });

    // update firestore if input are valid
    if (identifierInput.validity.valid && amountInput.validity.valid) {
      let redeemCodes: Array<RedeemCode> = [];
      try {
        // TODO: with https://playgroundxyz.atlassian.net/browse/VPC-216 probably we don't need redeemcodes anymore
        // leaving it here, just in case, but looking to remove it, possibliy before releasing VPC-216
        // create new redeemCodes
        redeemCodes = await updateRedeemCodes();
      } catch (err) {
        console.error(err);
        setNotification(errorRef.current);
        return;
      }

      const urls: string[] = redeemCodes.map(code => {
        let identifierQuery = 'ext=';
        identifierQuery = identifier
          ? `${identifierQuery}${identifier}${code.token}`
          : `${identifierQuery}${code.token}`;

        // We want to allow automatic redirects for test urls to save time so
        // direct them straight to the universal link and bypass the landing page
        return `${landingPageUrl(study.id)}&${identifierQuery}`;
      });

      // update the notification
      setNotification(successRef.current);
      // start to generate the csv and download the csv
      const csvContent = `data:text/csv;charset=utf-8,${urls.join('\n')}`;
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `vision-project-urls-${Date.now()}.csv`);
      document.body.appendChild(link);
      link.click();
    }
    // close the dialog
    setIsOpen(false);
  }

  /**
   * @function
   * @description - Responsible for updating the state values and validating the input
   * to make sure we have the correct data before we download the csv.
   * @param {React.ChangeEvent<HTMLInputElement>} event - the event from the changed event on the field.
   * @returns {void} no return value;
   */
  function updateIdentifier(event: React.ChangeEvent<HTMLInputElement>): void {
    const input = identifierField as HTMLInputElement;
    // if the field isn't valid, it will show an error
    setIdentifierError({
      show: !input.validity.valid,
      msg: input.validationMessage,
    });
    // update the identifier value
    setIdentifier(event.target.value);
  }

  /**
   * @function
   * @description - Responsible for updating the state values and validating the input
   * to make sure we have the correct data before we download the csv.
   * @param {React.ChangeEvent<HTMLInputElement>} event - the event from the changed event on the field.
   * @returns {void} no return value;
   */
  function updateUrlAmount(event: React.ChangeEvent<HTMLInputElement>): void {
    const input = amountField as HTMLInputElement;
    try {
      const targetValue = event.target.value;
      const value = parseInt(targetValue, 10);
      // if the field isn't valid, it will show an error
      setAmountError({
        show: !input.validity.valid,
        msg: input.validationMessage,
      });
      // update the amount value if valid
      if (input.validity.valid) {
        setUrlAmount(value);
      } else if (targetValue === '') {
        // fixed when user can’t delete all the characters
        setUrlAmount(targetValue);
      }
    } catch (e) {
      // value wasn't a number
      setAmountError({
        show: !input.validity.valid,
        msg: `Value was not a number`,
      });
    }
  }

  return (
    <Fragment>
      <div className={styles.actionButton}>
        <Tooltip title="Generate Landing Page URL" placement="left">
          <Fab
            className="action-button"
            size="small"
            onClick={(): void => {
              setIsOpen(!isOpen);
            }}
          >
            <BlurOn />
          </Fab>
        </Tooltip>
      </div>
      <Dialog
        className={styles.urlGenerator}
        maxWidth="lg"
        fullWidth={true}
        TransitionComponent={Transition}
        open={isOpen}
      >
        <Theme>
          <AppBar position="static">
            <Toolbar className="toolbar">
              <div>
                <Typography variant="h6">Study: {study.name}</Typography>
                <Typography variant="subtitle1" gutterBottom>
                  {`Use this screen to generate landing page URLs for this study.`}
                </Typography>
              </div>
            </Toolbar>
          </AppBar>
        </Theme>
        <DialogContent dividers={true}>
          <Box py={5}>
            <Grid container spacing={2} className="layout-row layout-fill">
              <Grid
                item
                xs={10}
                className="layout-column layout-align-start-center layout-fill children-fill-width"
              >
                <FormControl>
                  <TextField
                    InputLabelProps={{shrink: true}}
                    label="Landing Page URL"
                    helperText="This field isn't editable"
                    value={landingPageUrl(study.id)}
                    variant="outlined"
                    disabled
                    margin="normal"
                    inputProps={{style: {textAlign: 'center'}}}
                    inputRef={(ref: HTMLInputElement): void => {
                      urlField = ref;
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={2}
                className="layout-row layout-align-start-center children-fill-width button-generate-url"
              >
                <Theme>{copyUrlButton()}</Theme>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="layout-row layout-fill">
              <Grid
                item
                xs={6}
                className="layout-column layout-align-start-center layout-fill children-fill-width"
              >
                <FormControl>
                  <TextField
                    error={identifierError.show}
                    InputLabelProps={{shrink: true}}
                    label="External Placeholder"
                    helperText={
                      identifierError.show
                        ? identifierError.msg
                        : "The external placeholder for this list of url's"
                    }
                    value={identifier}
                    variant="outlined"
                    inputProps={{
                      minLength: 2,
                    }}
                    placeholder="An optional external identifier"
                    onChange={updateIdentifier}
                    margin="normal"
                    inputRef={(ref: HTMLInputElement): void => {
                      identifierField = ref;
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={4}
                className="layout-column layout-align-start-center layout-fill children-fill-width"
              >
                <FormControl>
                  <TextField
                    type="number"
                    required
                    InputLabelProps={{shrink: true}}
                    inputProps={{
                      name: `amount-of-urls`,
                      id: `amount-of-urls`,
                      min: 1,
                      max: 10000,
                      step: 1,
                    }}
                    error={amountError.show}
                    label="Generation amount"
                    helperText={
                      amountError.show
                        ? amountError.msg
                        : `The amount of url's to generate`
                    }
                    value={urlAmount}
                    variant="outlined"
                    onChange={updateUrlAmount}
                    margin="normal"
                    inputRef={(ref: HTMLInputElement): void => {
                      amountField = ref;
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={2}
                className="layout-row layout-align-start-center children-fill-width button-generate-url"
              >
                <Theme>
                  <Button
                    disabled={isLoading}
                    color="primary"
                    onClick={onDownload}
                  >
                    Download
                  </Button>
                </Theme>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Theme>
            <Button
              disabled={isLoading}
              color="primary"
              autoFocus
              onClick={onClose}
            >
              Cancel
            </Button>
          </Theme>
        </DialogActions>
      </Dialog>
      <Snackbar
        className="snackbar"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={notification.show}
        autoHideDuration={5000}
      >
        <SnackbarContent
          className={notification.type}
          message={
            <span id="client-snackbar">
              {notification.msg}
              <Button
                style={{
                  color: 'white',
                }}
                size="small"
                variant="text"
                onClick={(): void => setNotification(defaultRef.current)}
              >
                X
              </Button>
            </span>
          }
        />
      </Snackbar>
    </Fragment>
  );
}
