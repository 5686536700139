import * as React from "react";
import { Grid, FormControl, TextField } from "@material-ui/core";

export const DateFilters: React.FunctionComponent<any> = ({
  selectedDates,
  onChangeStart,
  onChangeEnd,
  error,
  helperText,
}) => {
  return (
    <Grid
      key="dates"
      item
      xs={3}
      className="layout-column layout-align-start-center layout-fill children-fill-width"
    >
      <h3>
        Dates <span className="header-description">(UTC Time)</span>
      </h3>
      <FormControl>
        <TextField
          id="startDate"
          label="Start"
          type="date"
          variant="outlined"
          margin="normal"
          error={error}
          helperText={helperText}
          defaultValue={selectedDates.start}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={onChangeStart}
        />
      </FormControl>
      <FormControl>
        <TextField
          id="endDate"
          label="End"
          type="date"
          variant="outlined"
          margin="normal"
          error={error}
          helperText={helperText}
          defaultValue={selectedDates.end}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={onChangeEnd}
        />
      </FormControl>
    </Grid>
  );
};
