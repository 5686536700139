import {
  GlobalBlacklist,
  FeedConfig,
  Study,
  StudyGroup,
  FirestoreResponse,
} from "~src/core/interfaces";
import { firebase } from "../../../firebaseConfig";

const db = firebase.firestore();

class Firestore {
  clone(
    data: StudyGroup | Study | FeedConfig | GlobalBlacklist
  ): StudyGroup | Study | FeedConfig | GlobalBlacklist {
    return {
      ...data,
    };
  }

  getStudyGroupByBrands(): Promise<FirestoreResponse> {
    const response: FirestoreResponse = {
      success: false,
    };

    const ref: firebase.firestore.CollectionReference =
      db.collection("studyGroups");
    return ref
      .get()
      .then((querySnapshot): FirestoreResponse => {
        response.success = true;
        response.data = querySnapshot.docs.reduce((r, doc) => {
          const id = doc.id;
          const data = doc.data();
          let name = data.name;
          if (data.archived) {
            name += " (archived)";
          }
          r[data.brand] = r[data.brand] || [];
          r[data.brand].push({
            id,
            name,
            pxyzCampaignId: data.pxyzCampaignId || "",
            duration: data.duration,
            difficulty: data.difficulty,
            archived: data.archived,
            feedId: data.feedId || "",
          });
          return r;
        }, Object.create(null));

        return response;
      })
      .catch((error) => {
        response.error = error;
        return response;
      });
  }

  getList(name: string, showUnArchivedOnly = true): Promise<FirestoreResponse> {
    const response: FirestoreResponse = {
      success: false,
    };

    let ref: firebase.firestore.CollectionReference | firebase.firestore.Query =
      db.collection(name);
    if (showUnArchivedOnly) {
      ref = ref.where("archived", "==", false);
    }
    return ref
      .get()
      .then((querySnapshot): FirestoreResponse => {
        response.success = true;
        response.data = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          data.id = doc.id;
          return data;
        });
        return response;
      })
      .catch((error) => {
        response.error = error;
        return response;
      });
  }

  get(name: string, id: string): Promise<FirestoreResponse> {
    const response: FirestoreResponse = {
      success: false,
    };
    return db
      .collection(name)
      .doc(id)
      .get()
      .then((doc): FirestoreResponse => {
        if (!doc.exists) {
          response.error = "Document does not exist";
          response.success = false;
        } else {
          const data = doc.data();
          if (data) {
            data.id = id;
          }
          response.data = data;
          response.success = true;
        }
        return response;
      })
      .catch((error): FirestoreResponse => {
        response.error = error;
        return response;
      });
  }

  add(
    name: string,
    data: StudyGroup | Study | FeedConfig | GlobalBlacklist,
    setArchived = true
  ): Promise<FirestoreResponse> {
    const response: FirestoreResponse = {
      success: false,
    };
    // clone so we don't manipulate the original
    const $data = this.clone(data);

    const ref = db.collection(name);
    $data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
    $data.updatedAt = firebase.firestore.FieldValue.serverTimestamp();

    if (setArchived) {
      $data.archived = false;
    }

    return ref
      .add($data)
      .then((doc): FirestoreResponse => {
        response.success = true;
        response.data = {
          id: doc.id,
        };
        return response;
      })
      .catch((error): FirestoreResponse => {
        response.error = error;
        response.success = false;
        return response;
      });
  }

  update(
    name: string,
    id: string,
    data: Study | FeedConfig | GlobalBlacklist
  ): Promise<FirestoreResponse> {
    const response: FirestoreResponse = {
      success: false,
    };
    // clone so we don't manipulate the original
    const $data = this.clone(data);

    $data.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    // CT: we need to separate the id from the $data, before we send it to firestore
    delete $data.id;

    const ref = db.collection(name);
    return ref
      .doc(id)
      .update($data)
      .then((): FirestoreResponse => {
        response.success = true;
        return response;
      })
      .catch((error): FirestoreResponse => {
        response.success = false;
        response.error = error;
        return response;
      });
  }

  delete(name: string, id: string): Promise<FirestoreResponse> {
    const response: FirestoreResponse = {
      success: false,
    };

    return db
      .collection(name)
      .doc(id)
      .delete()
      .then((): FirestoreResponse => {
        response.success = true;
        return response;
      })
      .catch((error): FirestoreResponse => {
        response.error = error;
        response.success = false;
        return response;
      });
  }

  archive(
    name: string,
    id: string,
    data: Study | FeedConfig | GlobalBlacklist,
    shouldArchive = false
  ): Promise<FirestoreResponse> {
    const response: FirestoreResponse = {
      success: false,
    };
    // clone so we don't manipulate the original
    const $data = this.clone(data);

    $data.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    $data.archived = shouldArchive;
    // CT: we need to separate the id from the $data, before we send it to firestore
    delete $data.id;

    const ref = db.collection(name);
    return ref
      .doc(id)
      .update($data)
      .then((): FirestoreResponse => {
        response.success = true;
        return response;
      })
      .catch((error): FirestoreResponse => {
        response.error = error;
        response.success = false;
        return response;
      });
  }
}

export { Firestore };
