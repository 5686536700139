import * as React from "react";
import moment from "moment-timezone";
import { Snackbar, SnackbarContent, Button } from "@material-ui/core";
import styles from "../reporting/reporting.scss";
import {
  RouteProps,
  FieldElement,
  FirestoreResponse,
  StudyGroup,
  StudyFromStudyGroup,
  CreativeFromStudy,
} from "../../core/interfaces";
import { dateFormats } from "../../../config";
import { csvDownload } from "../../utils/csvDownload";
import { ReportingFilters } from "../../components/filters/reportingFilters.react";
import VpApi from "../../components/dataManager/vpApi";
const { Fragment } = React;

interface iNotificationType {
  show: boolean;
  msg: string;
  type: string;
}

interface iState {
  isLoading: boolean;
  notification: iNotificationType;
}

export class Heatmapping extends React.Component<RouteProps, iState> {
  private field: FieldElement = {
    key: "heatmap",
    label: "The Heatmap report",
    type: "text",
    multiline: true,
    helperText: "Heatmap report download for the playback tool",
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      notification: {
        msg: "",
        type: "",
        show: false,
      },
    };
    this.onClickHandler = this.onClickHandler.bind(this);
    this.validateInputs = this.validateInputs.bind(this);
    this.clearSnackBar = this.clearSnackBar.bind(this);
  }

  async onClickHandler({
    idToken,
    studyGroup,
    creative,
    study,
    dates,
    attentionTimes,
  }: {
    idToken: string;
    studyGroup: StudyGroup;
    study: StudyFromStudyGroup;
    creative: CreativeFromStudy;
    dates: { start: string; end: string };
    attentionTimes: { from: number | null; to: number | null };
  }): Promise<void> {
    // clear the snack bar just in case
    this.clearSnackBar();

    const displaySnackBarError = (msg: string) => {
      this.setState({
        notification: {
          type: "error",
          show: true,
          msg,
        },
      });
    };

    // get the data for the report
    try {
      const result = await this.getReport(
        idToken,
        creative,
        study,
        dates,
        attentionTimes
      );
      // download process
      if (result && result.success && !result.data.length) {
        displaySnackBarError("No result for the filters selected");
      } else if (result && result.success) {
        const fileName = `${studyGroup.brand}-${studyGroup.name}-${
          study.name
        }-${creative.name}-${Date.now()}`;

        this.downloadHeatMap(result, fileName);
      } else if (result && !result.success) {
        displaySnackBarError(`Couldn't download report 1`);
      }
    } catch (err) {
      console.error(err);
      displaySnackBarError(`Couldn't download report 2`);
    }
  }

  validateInputs({
    creative,
    study,
    dates,
  }: {
    creative: CreativeFromStudy;
    study: StudyFromStudyGroup;
    dates: { start: string; end: string };
  }) {
    if (!creative || !study || !dates || !dates.start || !dates.end) {
      return {
        type: "study",
        helperText: "Invalid filter selection",
      };
    }
    return null;
  }

  clearSnackBar(): void {
    this.setState({
      notification: {
        msg: "",
        type: "",
        show: false,
      },
    });
  }

  private async getReport(
    idToken: string,
    selectedCreative: any,
    selectedStudy: any,
    selectedDates: { start: string; end: string },
    selectedAttentionTimes: { from: number | null; to: number | null }
  ): Promise<FirestoreResponse> {
    // dates are inclusive, that's why we add one day here
    const dates = {
      start: moment(selectedDates.start).format(dateFormats.submit),
      end: moment(selectedDates.end).add(1, "day").format(dateFormats.submit),
    };

    const vpApi = new VpApi(idToken);
    const body = {
      creativeId: selectedCreative.id,
      studyId: selectedStudy.id,
      dates,
      attentionTimes: { ...selectedAttentionTimes },
    };
    const result = await vpApi.getHeatmapReport(body);
    return result;
  }

  private downloadHeatMap(result: any, fileName: string): void {
    const fields = [
      {
        label: "impression_id",
        value: "impressionId",
      },
      {
        label: "creative_id",
        value: "creativeId",
      },
      {
        label: "page_id",
        value: "pageId",
      },
      {
        label: "device_model_identifier",
        value: "deviceModelIdentifier",
      },
      {
        label: "timestamp_iso",
        value: "timestampIso",
      },
    ];
    csvDownload(result.data, fileName, fields);
  }

  render(): React.ReactNode {
    return (
      <Fragment key={this.field.key}>
        <div id={styles.reporting}>
          <div className="layout-row layout-align-space-between-center">
            <h1>Heatmapping</h1>
          </div>
          <div>
            <ReportingFilters
              filters={[
                "studyGroup",
                "study",
                "creative",
                "dates",
                "attentionTimes",
              ]}
              className={"layout-row"}
              clearSnackBar={this.clearSnackBar}
              button={{
                text: "Generate Heatmap",
                validateInputs: this.validateInputs,
                onClick: this.onClickHandler,
              }}
            />
          </div>
        </div>
        <Snackbar
          className="snackbar"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.state.notification.show}
          autoHideDuration={5000}
        >
          <SnackbarContent
            className={this.state.notification.type}
            message={
              <span id="client-snackbar">
                {this.state.notification.msg}
                <Button
                  style={{ color: "white" }}
                  size="small"
                  variant="text"
                  onClick={(): void => {
                    // hide the snackbar
                    this.setState({
                      notification: {
                        msg: "",
                        type: "",
                        show: false,
                      },
                    });
                  }}
                >
                  X
                </Button>
              </span>
            }
          />
        </Snackbar>
      </Fragment>
    );
  }
}
