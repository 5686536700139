/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import {
  SLOTID_HELPERTEXT,
  SLOTID_KEY,
  TAG_HELPERTEXT,
  TAG_KEY,
} from '../../core/constants';

export const getHelper = (errors: any[], target: string): string => {
  const error = errors.find((item: any) => item.type === target);
  if (error) {
    return error.message;
  }
  if (target === TAG_KEY) {
    return TAG_HELPERTEXT;
  }
  if (target === SLOTID_KEY) {
    return SLOTID_HELPERTEXT;
  }
  return '';
};

export const hasError = (errors: any[], target: string): boolean => {
  return !!errors.find((item: any) => item.type === target) || false;
};

export const removeError = (errors: any[], target: string): any[] => {
  return errors.filter(item => item.type !== target);
};

export const getRequiredInputNames = (formRef: any): string[] => {
  const form = formRef.current;
  if (!form) {
    return [];
  }
  const requiredElementsList = (form as HTMLElement).querySelectorAll(
    '[required]',
  );

  let requiredElementArray: Element[] = [];
  if (requiredElementsList) {
    requiredElementArray = Array.from(requiredElementsList);
  }

  const requiredValues = requiredElementArray.map((item: any) => {
    return item.name;
  });

  return requiredValues;
};

export const checkForErrors = (
  data: any,
  formRef: React.MutableRefObject<null>,
  setErrors: (err: any[]) => void,
): boolean => {
  const requiredValues = getRequiredInputNames(formRef);

  const err = requiredValues
    .map((item: string) => {
      if (!data || !data[item]) {
        return {
          type: item,
          message: `Invalid ${item} value`,
        };
      }
      return null;
    })
    .filter(i => !!i);

  if (err?.length) {
    setErrors(err);
    return true;
  }
  return false;
};
