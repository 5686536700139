import { Chip, Fab, Tooltip } from "@material-ui/core";
import { Edit, Delete } from "@material-ui/icons";
import React from "react";
import { CellInfo, Column } from "react-table";
import { ArchiveStatus } from "../../core/interfaces";

export const archiveColumn: Column = {
  Header: "Archived",
  id: "archived",
  accessor: "archived",
  filterMethod: (
    filter: { value: any; id: React.ReactText },
    row: { [x: string]: any }
  ): boolean => {
    switch (filter.value) {
      default:
      case "all":
        return true;
      case ArchiveStatus.archived:
        return row._original[filter.id] === true;
      case ArchiveStatus.unarchived:
        return row._original[filter.id] === false;
    }
  },
  sortMethod: (a: boolean, b: boolean): number => {
    return b === a ? 0 : a ? -1 : 1;
  },
  Filter: ({
    filter,
    onChange,
  }: {
    filter: any;
    onChange: any;
  }): JSX.Element => (
    <select
      onChange={(event: React.ChangeEvent<HTMLSelectElement>): void =>
        onChange(event.target.value)
      }
      style={{ width: "100%" }}
      value={filter ? filter.value : "all"}
    >
      <option value="all">Show All</option>
      <option value={ArchiveStatus.archived}>Archived</option>
      <option value={ArchiveStatus.unarchived}>Unarchived</option>
    </select>
  ),
  Cell: (tableProps: any): React.ReactNode => {
    const { original } = tableProps;
    return (
      <React.Fragment>
        <Chip
          variant="outlined"
          label={original.archived ? "Archived" : "Unarchived"}
          color={!original.archived ? "primary" : "secondary"}
        />
      </React.Fragment>
    );
  },
};

interface ActionCellProps {
  viewEdit?: {
    onClick: (item: any) => void;
  };
  delete?: {
    onClick: (item: any) => void;
  };
}

export const actionColumn = (props: ActionCellProps): Column => {
  return {
    Header: "Actions",
    id: "actions",
    filterable: false,
    sortable: false,
    Cell: (row: CellInfo): React.ReactNode => {
      const { original } = row;
      const { viewEdit, delete: deleteItem } = props;

      const generateToolTip = (
        title: string,
        onClick: (item: any) => void,
        icon: JSX.Element
      ) => {
        return (
          <Tooltip title={title} style={{ marginLeft: "5%" }}>
            <Fab size="small" onClick={(): void => onClick(original)}>
              {icon}
            </Fab>
          </Tooltip>
        );
      };

      return (
        <React.Fragment>
          {viewEdit
            ? generateToolTip("View / Edit", viewEdit.onClick, <Edit />)
            : null}
          {deleteItem
            ? generateToolTip("Delete", deleteItem.onClick, <Delete />)
            : null}
        </React.Fragment>
      );
    },
  };
};
