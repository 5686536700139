/**
 * @function
 * @name sortAlphabetically
 * @description - Comparison function to help sort function
 * @param {string} a - string to sort
 * @param {string} b - string to sort
 * @returns {int} - 0 if strings are the same, -1 if the 1st string
 * is before the 2nd string alphabetically and 1 for the opposite
 */

const sortAlphabetically = (a: string, b: string) => {
  const lowercaseA = a.toLowerCase().trim();
  const lowercaseB = b.toLowerCase().trim();

  if (lowercaseA < lowercaseB) {
    return -1;
  }

  if (lowercaseA > lowercaseB) {
    return 1;
  }

  return 0;
};

/**
 * @function
 * @name sort
 * @description - Convert hyphens to camel case
 * @param {string[]} array - the array of strings to sort
 * @returns {string[]} -
 */
export const sort = (array: string[]) => {
  return [...array].sort(sortAlphabetically);
};
