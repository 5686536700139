import {Button, TextField} from '@material-ui/core';
import {Help} from '@material-ui/icons';
import React from 'react';
import {CSVReader} from 'react-papaparse';
import {PopoverButton} from '../popOver/popOver';

interface CsvReaderProps {
  handleOnFileLoad: (data: any) => void;
}

export const CsvReader: React.FunctionComponent<CsvReaderProps> = ({
  handleOnFileLoad,
}) => {
  const buttonRef: React.RefObject<any> = React.createRef();

  const handleOpenDialog = (e: React.MouseEvent<HTMLElement>) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  return (
    <CSVReader ref={buttonRef} onFileLoad={handleOnFileLoad} noClick noDrag>
      {({file}: {file: any}) => (
        <div style={{display: 'inline-flex'}}>
          <Button color="primary" variant="text" onClick={handleOpenDialog}>
            Upload CSV
          </Button>
          <PopoverButton
            icon={<Help />}
            content={
              <React.Fragment>
                <p>CSV must have four columns</p>
                <p>
                  Headers of the columns must be: url, name, category and image
                  URL
                </p>
              </React.Fragment>
            }
          />
          {file && file.name ? (
            <TextField
              style={{minWidth: '500px'}}
              variant="outlined"
              margin="normal"
              key="file"
              disabled
              label="file"
              value={file && file.name}
              name="file"
            />
          ) : null}
        </div>
      )}
    </CSVReader>
  );
};
