import {cloudFunctionBase, desktopWebsiteUrl, landingPage} from '../../config';
import {DeviceType, RedeemCode} from '../core/interfaces';
import {getIdToken} from './idToken';

/**
 * @function
 * @async
 * @name updateRedeemCodes
 * @description - Insert new records in the RedeemCodes collection in firestore
 * @returns {Promise<Array<RedeemCode>>} array of redeem codes that have been generated
 */
async function updateRedeemCodes(
  id: string,
  urlAmount: number,
): Promise<RedeemCode[]> {
  let redeemCodes = [];
  const idToken = await getIdToken();
  const headers = new Headers({
    Authorization: `Bearer ${idToken}`,
    'Content-Type': 'application/json',
  });
  const response = await fetch(
    `${cloudFunctionBase}/redeem-codes/generateRedeemCodes`,
    {
      method: 'post',
      body: JSON.stringify({
        studyId: id,
        shouldGenerateCompletionCode: true,
        codesAmount: urlAmount,
      }),
      headers,
    },
  );
  redeemCodes = await response.json();
  return redeemCodes;
}

export async function downloadStudyURL(
  type: string,
  id: string,
  urlAmount: number,
): Promise<void> {
  let redeemCodes: RedeemCode[] = [];
  try {
    // TODO: with https://playgroundxyz.atlassian.net/browse/VPC-216 probably we don't need redeemcodes anymore
    // leaving it here, just in case, but looking to remove it, possibliy before releasing VPC-216
    // create new redeemCodes
    redeemCodes = await updateRedeemCodes(id, urlAmount);
  } catch (err) {
    console.error(err);
    return;
  }

  const urls: string[] = redeemCodes.map(code => {
    if (type === DeviceType.DESKTOP) {
      return `${desktopWebsiteUrl}?studyId=${id}&ext=${code.token}`;
    } else {
      return `${landingPage}${id}&ext=${code.token}`;
    }
  });
  // start to generate the csv and download the csv
  const csvContent = `data:text/csv;charset=utf-8,${urls.join('\n')}`;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  const fileName =
    type === DeviceType.DESKTOP
      ? 'aip-vp-desktop-urls-'
      : 'aip-vp-mobile-urls-';
  link.setAttribute('download', `${fileName}${Date.now()}.csv`);
  document.body.appendChild(link);
  link.click();
}
