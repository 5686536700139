import * as React from "react";
import { TransitionProps } from "@material-ui/core/transitions";
import { Theme, cloudFunctionBase } from "../../../config";
import { FeedConfig } from "../../core/interfaces";
import {
  Box,
  Grid,
  Slide,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { Loader } from "../../components/loader/loader.react";
import styles from "./feedConfigs.scss";
import { getIdToken } from "../../utils/idToken";

const { Fragment } = React;
const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

interface iState {
  error: boolean;
  isOpen: boolean;
  msg: string | React.ReactNode[];
  configs: FeedConfig[];
  isLoading: boolean;
}

interface iProps {
  isOpen: boolean;
  configs: FeedConfig[];
  onClose?(state: boolean): void;
}

const DEFAULT_STATE: iState = {
  isLoading: false,
  error: false,
  isOpen: false,
  msg: ``,
  configs: [],
};

export class RunAllActiveSchedulesDialog extends React.Component<
  iProps,
  iState
> {
  constructor(props: iProps) {
    super(props);
    this.state = {
      ...DEFAULT_STATE,
    };
  }

  /**
   * @function
   * @name resetState
   * @description Helper method to update the state back to it's original form.
   * @returns { void } - no return value
   */
  resetState(): void {
    this.setState({
      ...DEFAULT_STATE,
    });
  }

  /**
   * @function
   * @name onClose
   * @description When this dialog closes, this function will be called.
   * @returns { void } - no return value
   */
  onClose(): void {
    this.resetState();
    if (typeof this.props.onClose === "function") {
      this.props.onClose(true);
    }
  }

  /**
   * @function
   * @async
   * @name runAllScheduledConfigs
   * @description There are some instances where we'll need to update ALL the feed config data at once
   * The nicest way to do this is to trigger each schedule rather than running the refresh manually for each feed config, this way everything runs in sub processes etc and we'll get
   * real time updates in the feed configs panel.
   * @returns {Promise<void>} no return value.
   */
  async runAllScheduledConfigs(): Promise<void> {
    this.setState({
      isLoading: true,
      error: false,
      msg: ``,
    });
    // filter out any configs that don't have a schedule
    const feedsWithSchedules = this.state.configs.filter(
      (config) => config.schedule
    );
    const idToken = await getIdToken();
    if (!idToken) {
      this.setState({
        isLoading: false,
        error: true,
        msg: `Can't authenticate user.`,
      });
      return;
    }
    const requests = feedsWithSchedules.map((config) => {
      return fetch(
        `${cloudFunctionBase}/run-feed-refresh-schedule?id=${config.id}&idToken=${idToken}`
      );
    });
    try {
      const responses = await Promise.all(requests);
      const messages: React.ReactNode[] = [
        <Typography variant="h4" key="title">
          Successfully triggered schedules.
        </Typography>,
        <br key="break" />,
      ];
      for (const response of responses) {
        const data = await response.json();
        messages.push(
          <Typography
            variant="caption"
            key={data.name}
          >{`Schedule: ${data.name} triggered successfully.`}</Typography>
        );
      }
      this.setState({
        isLoading: false,
        error: false,
        msg: messages,
      });
    } catch (e) {
      console.error(e);
      this.setState({
        isLoading: false,
        error: true,
        msg: e.message,
      });
    }
  }

  /**
   * @function
   * @name componentDidMount
   * @description lifecycle hook to update the state once we've loaded it on the page.
   * @returns { void } - no return value
   */
  componentDidMount(): void {
    this.setState({
      isOpen: this.props.isOpen,
    });
  }

  /**
   * @static
   * @note This method is a static method and cannot access the current class context
   * @function
   * @name getDerivedStateFromProps - lifecycle hook to map props to state
   * Here we make sure that the props isOpen value is not equal to the current state, if it isn't
   * we return the object to mutate state.
   * @param {iProps} props.isOpen - the isOpen value from the props attribute
   * @param {iState} currentState - the current state of this component
   * @returns {null|iProps} - null or an object to update state.
   */
  static getDerivedStateFromProps(
    { isOpen = false, configs = [] },
    currentState: iState
  ): null | iProps {
    if (currentState.isOpen !== isOpen) {
      return {
        isOpen,
        configs,
      };
    }
    return null;
  }

  /**
   * @function
   * @name render
   * @description - standard react render method
   * @returns {React.ReactNode} - returns jsx markup
   */
  render(): React.ReactNode {
    if (!this.props.configs) {
      return <Fragment></Fragment>;
    }
    return (
      <Fragment>
        <Dialog
          className={styles.refreshDialog}
          maxWidth="lg"
          fullWidth={true}
          TransitionComponent={Transition}
          open={this.state.isOpen}
        >
          <Theme>
            <AppBar position="static">
              <Toolbar className="toolbar">
                <div>
                  <Typography variant="h6">
                    Schedules to Trigger (
                    {
                      this.props.configs.filter((config) => config.schedule)
                        .length
                    }
                    )
                  </Typography>
                </div>
              </Toolbar>
            </AppBar>
          </Theme>
          <DialogContent dividers={true}>
            <Box py={5}>
              <Grid
                container
                spacing={2}
                className="layout-row layout-align-space-between-center layout-fill"
              >
                <Grid
                  item
                  xs={12}
                  className="layout-column layout-align-start-center layout-fill children-fill-width"
                >
                  {this.state.msg === "" && (
                    <Fragment>
                      <Typography>{`This will trigger the cron / schedule for each config that has one created.`}</Typography>
                      <Typography>{`Are you sure you want to update feeds globally?`}</Typography>
                    </Fragment>
                  )}
                  <br />
                  {this.state.isLoading && <Loader />}
                  {Array.isArray(this.state.msg) &&
                    this.state.msg.length &&
                    this.state.msg}
                  {typeof this.state.msg === "string" &&
                    this.state.msg !== "" && (
                      <Typography
                        className={this.state.error ? `is-error` : `is-success`}
                      >
                        {this.state.msg}
                      </Typography>
                    )}
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Theme>
              <Button
                disabled={this.state.isLoading}
                color="primary"
                autoFocus
                onClick={this.onClose.bind(this)}
              >
                Cancel
              </Button>
              <Button
                disabled={this.state.isLoading}
                color="primary"
                onClick={
                  this.state.msg && !this.state.error
                    ? this.onClose.bind(this)
                    : this.runAllScheduledConfigs.bind(this)
                }
              >
                {this.state.msg && !this.state.error
                  ? `Done`
                  : `Run all schedules`}
              </Button>
            </Theme>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}
