import * as React from 'react';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {
  FeatureSet,
  FeatureConfig,
  Environment,
  Feature,
} from './src/core/interfaces';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#ff3926',
    },
  },
});

interface iThemeProps {
  children?: React.ReactNode;
}

export const isDevelopment = process.env.CONFIG_ENV === 'development';
export const cloudFunctionBase = `https://us-central1-vision-project${
  isDevelopment ? `-dev` : `-pxyz`
}.cloudfunctions.net`;
export const landingPage = `https://${
  isDevelopment ? 'dev.' : ''
}vision-project.co/landing.html?study_id=`;
export const pxyzStudioBase = 'https://create.playground.xyz/';
export const minSDK = '27.23.0';
export const pxyzViewTagUrl =
  'https://the.playground.xyz/campaign/{CAMPAIGN_ID}/tags';
export const vpApiUrl = `https://vision-project-${
  isDevelopment ? 'dev' : 'pxyz'
}.appspot.com/secure`;
// export const vpApiUrl = `http://localhost:3456/secure`;
export const desktopWebsiteUrl = isDevelopment
  ? 'https://desktop-app.dev.vision-project.co/'
  : 'https://desktop-app.vision-project.co/';

export const mobileWebsiteUrl = isDevelopment
  ? `https://vision-project-mobile-website-6d2rhxycaq-uw.a.run.app`
  : `https://vision-project-mobile-website-uc4qfv5mda-uw.a.run.app`;
// Date formats
export const dateFormats = {
  display: 'YYYY-MM-DD',
  submit: 'YYYY-MM-DD 00:00:00',
  footer: 'hh:mm a DD/MM/YYYY z',
};

export const amtUrlConfig = {
  production: 'https://static.playground.xyz/amt/amt.js',
  staging: 'https://static.playground.xyz/amt/amt.staging.js',
  regex: 'amtUrl="(.*?)"',
  queryString: {
    key: 'data-vendor',
    value: 'vision-project',
  },
};

// To add new features they must be added to the Feature enum (in src/interfaces),
// and specified true/false for all Environments below
const featuresConfig: FeatureConfig = {
  [Environment.DEVELOPMENT]: {
    [Feature.REPORTING]: true,
  },
  [Environment.PRODUCTION]: {
    [Feature.REPORTING]: true,
  },
  [Environment.STAGING]: {
    [Feature.REPORTING]: true,
  },
};
export const features: FeatureSet = isDevelopment
  ? featuresConfig[Environment.DEVELOPMENT]
  : featuresConfig[Environment.PRODUCTION];

export function Theme(props: iThemeProps): JSX.Element {
  return <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>;
}

export const STANDARD_CREATIVE_FORMAT = 'standard';
