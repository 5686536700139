import { Parser } from "json2csv";
export const csvDownload = (
  data: any,
  fileName: string,
  fields?: { label: string; value: string }[]
): void => {
  const json2csvParser = fields ? new Parser({ fields }) : new Parser();
  const csv = json2csvParser.parse(data);

  // start to generate the csv and download the csv
  const csvContent = `data:text/csv;charset=utf-8,${csv}`;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${fileName}.csv`);
  document.body.appendChild(link);
  link.click();
};
