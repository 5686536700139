import { PXYZ_PREFIX } from '../../core/constants';
import { AdCampaignCreative } from '../../core/interfaces';
import { getDataCreativeFormat } from './tagHelpers';

const GG_SERVICE_URL = 'https://js.gumgum.com/services.js';
const PXYZ_QUERY_STRING = 'pxyzinject=true';

/**
 * item: AdCampaignCreative State
 * setState: AdCampaignCreative's setState callback
 *
 * During tag validation, retrieve the tag's adformat and update the adCampainCreative's state
 */
export const updateAdFormatFromTag = (
  item: AdCampaignCreative | undefined,
  setState: React.Dispatch<React.SetStateAction<AdCampaignCreative | undefined>>
) => {
  if (!item || !item.tag) {
    return;
  }
  let { tag } = item;
  let { adFormat } = item;

  // check if service.js is included and append the extra query string
  if (tag.includes(GG_SERVICE_URL) && !tag.includes(PXYZ_QUERY_STRING)) {
    tag = tag.replace(GG_SERVICE_URL, `${GG_SERVICE_URL}?${PXYZ_QUERY_STRING}`);
  }

  const newAdFormat = getDataCreativeFormat(tag);
  if (adFormat && !adFormat.startsWith(PXYZ_PREFIX)) {
    adFormat = newAdFormat ? newAdFormat : adFormat;
  } else {
    adFormat = newAdFormat;
  }
  setState({ ...item, adFormat, tag });
};
