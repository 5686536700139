import cloneDeep from "clone-deep";
import {
  StudyGroupReportItem,
  StudyGroupDashboardItem,
  NewKeys,
  StudyGroup,
} from "../core/interfaces";
import { renameKeys } from "./renameKeys";

// prepare the data for display, basically we want one row for each study. Check interfaces for data structure.
export const studyGroupDataTransformer = (
  data: StudyGroup[],
  newKeys: NewKeys,
  params: { showArchived: boolean; caller: string },
  report?: StudyGroupReportItem[]
): StudyGroupDashboardItem[] => {
  const newDataRaw = data.map((originalStudyGroup: StudyGroup) => {
    const { showArchived, caller } = params;
    const studyGroup =
      caller === "study"
        ? renameKeys(originalStudyGroup, newKeys)
        : cloneDeep(originalStudyGroup);
    // if we have studies
    if (studyGroup.studies && studyGroup.studies.length) {
      // now we need to split each study in a separate object
      // with also the keys of the studyGroup and if necessary of the studyGroupReport

      // extract studies and studyGroup object without the study key, so we can re-use it later with the spread operator
      const { studies, ...studyGroupWithNoStudies } = studyGroup;

      return studies.map((originalStudy: any) => {
        // filter out the studies that are archived
        if (!showArchived && originalStudy.archived) {
          return null;
        }

        // rename the keys of the study object otherwise name and id will conflict with name and id of the studyGroup
        const studyId = originalStudy.id;
        const study =
          caller === "studyGroup"
            ? renameKeys(originalStudy, newKeys)
            : cloneDeep(originalStudy);
        // if we have reports
        if (report && report.length) {
          // find the existing report for the give study
          const existingReportItem = report.find(
            (extraItem: StudyGroupReportItem) => extraItem.id === studyId
          );

          if (existingReportItem) {
            // add study completion count
            const studyCompletion = existingReportItem.studyCompletion;
            const smStudyCompletion = existingReportItem.smStudyCompletion;
            // calculate total imps per study
            if (
              study.creatives &&
              study.creatives.length &&
              existingReportItem.creatives
            ) {
              // extract the creative ids associated to the study
              const creativeIds = study.creatives.map((c: any) => c.id);
              // calculate the total imps for the creative associated to the study
              const totalImps = existingReportItem.creatives.reduce(
                (sum, item) => {
                  if (creativeIds.includes(item.creativeId)) {
                    sum += item.totalImps;
                    return sum;
                  }
                  return sum;
                },
                0
              );

              const uniqueSmCompletedImps =
                existingReportItem.uniqueSmCompletedImps !== "undefined"
                  ? existingReportItem.uniqueSmCompletedImps
                  : "n/a";

              return {
                ...study,
                ...studyGroupWithNoStudies,
                studyCompletion,
                smStudyCompletion,
                totalImps: `${uniqueSmCompletedImps} / ${totalImps}`,
              };
            }
          }
        }

        const noReport = {
          studyCompletion: 0,
          smStudyCompletion: 0,
          totalImps: "0/0",
        };
        // no report
        return {
          ...study,
          ...studyGroup,
          ...noReport,
        };
      });
    }
    // no studies
    return caller === "study"
      ? null
      : {
          ...studyGroup,
          status: "draft",
          studyName: "",
        };
  });
  // flatten the array
  const newData = ([] as StudyGroupDashboardItem[])
    .concat(...newDataRaw)
    .filter((i) => !!i);
  return newData;
};
