import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import _ from "lodash";

import React, { useEffect, useState } from "react";
import {
  CardAction,
  Entity,
  PanelTableGeneratorParams,
  TListItem,
  PaneCardActionName,
  CardActionUploadCsv,
} from "../../core/interfaces";
import { FormDialog } from "../dialog/formDialog";
import { CsvReader } from "../fileUpload/csvReader";
import { actionColumn } from "../table/columns";
import { TableView } from "../table/table";

enum Action {
  ADD = "ADD",
  EDIT = "EDIT",
}

export const panelTableGenerator: <T extends Entity>(
  params: PanelTableGeneratorParams<T, CardAction>
) => { generate: () => JSX.Element } = <T extends Entity>(
  params: PanelTableGeneratorParams<T, CardAction>
) => {
  const [listItem, setListItem] = useState<TListItem | undefined>(undefined);
  const [listItemIndex, setListItemIndex] = useState<number>(-1);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [action, setAction] = useState<Action>(Action.ADD);
  const {
    entity,
    setEntity,
    listKey,
    editKey,
    title,
    dialog,
    cardActions,
    customListItem,
  } = params;
  let { columns } = params;

  columns = [
    ...columns,
    {
      ...actionColumn({
        viewEdit: {
          onClick: (item: TListItem): void => {
            setListItem(item);
            const index = entity[listKey].findIndex((e: any) => {
              return e[editKey] == item[editKey];
            });
            setListItemIndex(index);
            setIsDialogOpen(true);
            setAction(Action.EDIT);
          },
        },
        delete: {
          onClick: (item: TListItem): void => {
            let items = entity[listKey].filter((el: TListItem) => {
              return el[editKey] != item[editKey];
            });
            setListItemIndex(-1);
            setEntity({ ...entity, [listKey]: items });
          },
        },
      }),
    },
  ];

  useEffect((): void => {
    if (!listItem) {
      return;
    }
    let items: TListItem[] = (entity && entity[listKey]) || [];
    switch (action) {
      case Action.ADD:
        if (customListItem) {
          items.push(customListItem(listItem));
        } else {
          items.push(listItem);
        }
        break;
      case Action.EDIT:
        items = items.map((c: TListItem, index: number) => {
          // if we have an listItemIndex means we are editing,
          if (index === listItemIndex) {
            return (customListItem && customListItem(listItem)) || listItem;
          }
          return c;
        });
        break;
    }
    setEntity({ ...entity, [listKey]: items });
  }, [listItem]);

  const generate = (): JSX.Element => {
    return (
      <React.Fragment>
        <h2>{title}</h2>
        <Card>
          <CardContent>
            {entity && entity[listKey] && entity[listKey].length ? (
              <TableView
                columns={columns}
                data={entity && entity[listKey]}
                isLoading={false}
                params={{
                  overflowAndScroll: true,
                  style: {
                    maxHeight: "calc(70vh - 200px)",
                  },
                }}
              />
            ) : (
              <div>no records avaialable</div>
            )}
          </CardContent>
          <CardActions>
            {cardActions &&
              cardActions.map((cardAction: CardAction): JSX.Element | null => {
                switch (cardAction.name) {
                  case PaneCardActionName.ADD:
                    return (
                      <Button
                        key="add"
                        color="primary"
                        variant="text"
                        onClick={(): void => {
                          setIsDialogOpen(true);
                          setAction(Action.ADD);
                          setListItemIndex(-1);
                        }}
                      >
                        Add
                      </Button>
                    );
                  case PaneCardActionName.UPLOAD_CSV:
                    const handleOnFileLoad = (data: any[]) => {
                      setListItemIndex(-1);
                      const action = cardAction as CardActionUploadCsv;
                      const items = action.mapping(data) || [];

                      if (!items.success) {
                        action.errorHandling();
                        return;
                      }

                      setEntity({ ...entity, [listKey]: items.data });
                    };
                    return (
                      <CsvReader
                        key="uploadCsv"
                        handleOnFileLoad={handleOnFileLoad}
                      />
                    );
                  default:
                    return null;
                }
              })}
          </CardActions>
          <FormDialog
            dialog={{
              isDialogOpen,
              setIsDialogOpen,
              title: dialog.title,
              subtitle: dialog.subtitle,
              validation: dialog.validation,
            }}
            original={{
              originalEntity: listItem,
              setOriginalEntity: setListItem,
              listKey,
            }}
            form={{
              columns: dialog.formColumns,
              id: dialog.formId,
            }}
          />
        </Card>
      </React.Fragment>
    );
  };

  return {
    generate,
  };
};
