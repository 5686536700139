import {vpApiInstance} from '../../components/dataManager/vpApiInstance';
import {Entity, SnackBarStateParams, SnackbarType} from '../interfaces';

interface SubmitFormParams<T extends Entity> {
  entity: T;
  entityName: string;
  redirect: string;
  setSubmitTracker: React.Dispatch<React.SetStateAction<number>>;
  setSnackBarState: React.Dispatch<React.SetStateAction<SnackBarStateParams>>;
  setIsPaneOpen: (val: boolean) => void;
}

export const submitForm = <T extends Entity>(params: SubmitFormParams<T>) => {
  const {
    entity,
    entityName,
    redirect,
    setSubmitTracker,
    setIsPaneOpen,
    setSnackBarState,
  } = params;
  return async (hasErrors: boolean): Promise<void> => {
    const body = {...entity};
    if (hasErrors) {
      setSubmitTracker(0);
      return;
    }
    if (body.id) {
      delete body.id;
    }

    const vpApi = await vpApiInstance();
    if (!vpApi) {
      setSubmitTracker(0);
      return;
    }
    const itemId = (entity && entity.id) || undefined;
    const response = await vpApi.saveEntity(entityName, body, itemId);
    setSubmitTracker(0);
    // display snack bar according to the result
    if (!response.success) {
      setSnackBarState({
        isSnackBarOpen: true,
        snackBarContent: response.errors
          .map((item: any) => {
            return item.message || 'An error occurred';
          })
          .join(','),
        snackBarType: SnackbarType.error,
      });
      return;
    }
    setSnackBarState({
      isSnackBarOpen: true,
      snackBarContent: 'Operation terminated successfully',
      snackBarType: SnackbarType.success,
    });
    // close the panel if it's all good
    setTimeout(() => {
      setIsPaneOpen(false);
      window.location.href = `${window.location.origin}/${redirect}`;
    }, 2000);
  };
};
