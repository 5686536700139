import React from "react";
import moment from "moment-timezone";

interface TimestampProps {
  text: string;
  format: string;
  timestamp: number;
}

export const Timestamp: React.FunctionComponent<TimestampProps> = ({
  text,
  format,
  timestamp,
}: TimestampProps) => {
  const date = moment(timestamp).format(format);
  return (
    <p>
      {text} {date}
    </p>
  );
};
