import { Snackbar, SnackbarContent } from "@material-ui/core";
import React from "react";

interface SnackBarAlertProps {
  open: boolean;
  onClose: () => void;
  contentProps: {
    content: string;
    className: string;
  };
}

export const SnackBarAlert: React.FunctionComponent<SnackBarAlertProps> = ({
  open,
  onClose,
  contentProps,
}) => {
  return (
    <Snackbar
      className="snackbar"
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={onClose}
      open={open}
      autoHideDuration={contentProps.className == "error" ? 50000 : 2000}
    >
      <SnackbarContent
        className={contentProps.className}
        message={<span id="client-snackbar">{contentProps.content}</span>}
      />
    </Snackbar>
  );
};
