import * as React from 'react';
import SlidingPane from 'react-sliding-pane';
import {
  Chip,
  Button,
  FormControlLabel,
  Checkbox,
  Breadcrumbs,
  Tooltip,
  Fab,
  Snackbar,
  Typography,
  SnackbarContent,
  BottomNavigation,
  BottomNavigationAction,
} from '@material-ui/core';
import {
  Save,
  LocalOffer,
  Assignment,
  Archive,
  Unarchive,
  Add,
  Edit,
  SyncDisabled,
} from '@material-ui/icons';
import ReactTable, {Column} from 'react-table';
import deepEqual from 'deep-equal';
import cloneDeep from 'clone-deep';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {TransitionProps} from '@material-ui/core/transitions';
import {
  FirestoreResponse,
  Study,
  iValidity,
  RouteRequestParams,
  FeedConfig,
  DataManagerMethods,
  ArchiveStatus,
  StudyGroup,
  StudyGroupReportItem,
  StudyGroupDashboardItem,
  StudyGroupType,
} from '../../core/interfaces';
import styles from './dataManager.scss';
import {dashToCamel} from '../../utils/dashToCamel';
import mturkTemplate from '../../controllers/studies/platformContent/mturkTaskTemplate.js';
import {Firestore} from './firestore';
import {getIdToken} from '../../utils/idToken';
import {isStudyGroup} from '../../utils/determineType';
import {vpApiUrl, pxyzViewTagUrl} from '../../../config';
import VpApi from './vpApi';

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  },
);

const fetchDataDelay = 2000;

const firestore = new Firestore();
interface iChangeEventFunction {
  (event: React.ChangeEvent<HTMLInputElement>): void;
}

enum SnackbarType {
  error = 'error',
  info = 'info',
  success = 'success',
  warning = 'warning',
}

interface iState {
  isLoading: boolean;
  data: FeedConfig[] | Study[];
  currentItem?: FeedConfig | Study;
  clone?: FeedConfig | Study;
  isEditing: boolean;
  pages: number;
  snackbarOpen: boolean;
  snackbarType: SnackbarType;
  snackbarText: string;
  showConfirmArchive: boolean;
  showConfirmGenerateTaskcontent: boolean;
  columns: Column[];
  panelOpen: boolean;
  showArchived: boolean;
}

interface PublicMethods {
  refreshList(): void;
}

interface iItemValidator {
  key: string;
  mapTo: string;
  module?: string;
  valueMapper(resolvedValue: Study | FeedConfig | boolean): string;
}

interface iProps {
  defaultItem: FeedConfig | Study | StudyGroupDashboardItem;
  module: string;
  listName?: string;
  match: RouteRequestParams;
  history: History;
  columns: Column[];
  checkValidity(validatorObject: iValidity): boolean;
  getPanelContent(clone: FeedConfig | Study, methods: DataManagerMethods): void;
  name: string;
  plural: string;
  id?: string;
  getExtraData?(data: any): Promise<any>;
  validateItemsById?: iItemValidator[];
  actionButtons?: React.ReactNode;
  transformData?(data: Study[] | FeedConfig[]): Promise<Study[] | FeedConfig[]>;
  transfromDataBeforeRender(
    data: any,
    showArchived: boolean,
    extra?: StudyGroupReportItem[] | any,
  ): Promise<any>;
  transformEditingItem?(data: Study | FeedConfig): Study | FeedConfig;
  attachRowActionButton?(clone: FeedConfig | Study): void;
  onUpdate?(newItem: FeedConfig | Study, oldItem: FeedConfig | Study): void;
  onCreate?(newItem: FeedConfig | Study): void;
  onArchive?(newItem: FeedConfig | Study, shouldArchive: boolean): void;
  attachButtonToEditingWindow?(clone: FeedConfig | Study): React.ReactNode;
  getMethods?(methods: PublicMethods): void;
  defaultFilterMethod(value: string, target: string): boolean;
  defaultSortMethod(a: string, b: string): number;
}

export class DataManager extends React.Component<iProps, iState> {
  private table: React.RefObject<iDataManagerReferenceProps>;
  private currentForm: React.RefObject<HTMLFormElement>;
  private methods: DataManagerMethods;
  private platforms: Platform[];

  constructor(props: iProps) {
    super(props);
    this.platforms = [];

    // make sure methods that need correct context are bound
    this.handleChange = this.handleChange.bind(this);
    this.handleDeleteChip = this.handleDeleteChip.bind(this);
    this.addToArray = this.addToArray.bind(this);
    this.deleteArray = this.deleteArray.bind(this);
    this.getCampaign = this.getCampaign.bind(this);
    this.getXYZCreavites = this.getCreatives.bind(this);
    // ref to data table
    this.table = React.createRef();
    this.currentForm = React.createRef();
    // Setting initial state
    this.state = {
      showArchived: false,
      // add the Archived and Action columns to the table
      columns: [
        ...props.columns,
        {
          Header: 'Archived',
          id: 'archived',
          accessor: 'archived',
          filterMethod: (
            filter: {value: any; id: React.ReactText},
            row: {[x: string]: any},
          ): boolean => {
            switch (filter.value) {
              default:
              case 'all':
                return true;
              case ArchiveStatus.archived:
                return row._original[filter.id] === true;
              case ArchiveStatus.unarchived:
                return row._original[filter.id] === false;
            }
          },
          sortMethod: (a: boolean, b: boolean) => {
            return b === a ? 0 : a ? -1 : 1;
          },
          Filter: ({filter, onChange}): JSX.Element => (
            <select
              onChange={(event: React.ChangeEvent<HTMLSelectElement>): void =>
                onChange(event.target.value)
              }
              style={{width: '100%'}}
              value={filter ? filter.value : 'all'}
            >
              <option value="all">Show All</option>
              <option value={ArchiveStatus.archived}>Archived</option>
              <option value={ArchiveStatus.unarchived}>Unarchived</option>
            </select>
          ),
          Cell: (tableProps): React.ReactNode => {
            const {original} = tableProps;
            return (
              <React.Fragment>
                <Chip
                  variant="outlined"
                  label={original.archived ? 'Archived' : 'Unarchived'}
                  color={!original.archived ? 'primary' : 'secondary'}
                />
              </React.Fragment>
            );
          },
        },
        {
          Header: 'Actions',
          id: 'actions',
          filterable: false,
          sortable: false,
          Cell: (tableProps): React.ReactNode => {
            const {original} = tableProps;
            return (
              <React.Fragment>
                <Tooltip title="View / Edit" placement="left">
                  <Fab
                    size="small"
                    onClick={(): void => {
                      this.openInPanel(original.id);
                    }}
                  >
                    <Edit />
                  </Fab>
                </Tooltip>
                {props.attachRowActionButton &&
                  props.attachRowActionButton(original)}
              </React.Fragment>
            );
          },
        },
      ],
      snackbarOpen: false,
      snackbarText: '',
      snackbarType: SnackbarType.success,
      data: [],
      isLoading: true,
      currentItem: undefined,
      clone: undefined,
      isEditing: false,
      panelOpen: false,
      pages: -1,
      showConfirmArchive: false,
      showConfirmGenerateTaskcontent: false,
    };
    // exposes these methods to the panel
    this.methods = {
      isFieldValid: this.isFieldValid.bind(this),
      isEditing: this.isEditing.bind(this),
      addToArray: this.addToArray.bind(this),
      deleteArray: this.deleteArray.bind(this),
      handleDeleteChip: this.handleDeleteChip.bind(this),
      handleChange: this.handleChange.bind(this),
      getCampaign: this.getCampaign.bind(this),
      getCreatives: this.getCreatives.bind(this),
    };
    if (typeof this.props.getMethods === 'function') {
      this.props.getMethods({
        refreshList: this.refreshList.bind(this),
      });
    }
  }

  /**
   * @name componentDidMount
   * @function
   * @description standard react lifecycle hook
   * @returns {void}
   */
  async componentDidMount(): Promise<void> {
    const {params} = this.props.match;
    const {id} = params;
    if (typeof id !== 'undefined') {
      this.openInPanel(id);
    }
    await this.fetchData();
  }

  /**
   * @function
   * @name throwErrorNotification
   * @param {string} msg - the message to send to the error notification
   * @description - simply updates the state to launch a new snackbar notification
   */
  throwErrorNotification(msg: string): void {
    // update the state
    this.setState({
      currentItem: undefined,
      clone: undefined,
      isEditing: false,
      isLoading: false,
      snackbarOpen: true,
      snackbarType: SnackbarType.error,
      snackbarText: msg,
    });
  }

  /**
   * @function
   * @name openInPanel
   * @description - opens the panel with the provided fields / content
   * @param {string} id - the id of the item to open in the panel, if no id, it will
   * assume we're creating a new item for the current module
   * @returns {Promise<void>}
   */
  async openInPanel(id?: string): Promise<void> {
    this.setState({
      isEditing: true,
    });
    if (typeof id === 'string' || typeof id === 'number') {
      try {
        const name = dashToCamel(this.props.module);

        const response = await firestore.get(name, id);
        if (!response.success) {
          throw new Error(response.error);
        }

        const platformResponse = await firestore.getList('platforms');
        if (!platformResponse.success) {
          throw new Error(platformResponse.error);
        }
        this.platforms = platformResponse.data;

        let currentItem = response.data;
        if (typeof this.props.transformEditingItem === 'function') {
          currentItem = this.props.transformEditingItem(currentItem);
        }
        // update the url bar
        this.props.history.replace(`/${this.props.module}/${id}`);
        // update the state
        this.setState({
          currentItem: cloneDeep(currentItem),
          clone: cloneDeep(currentItem),
          isEditing: false,
          isLoading: false,
        });
      } catch (e) {
        this.props.history.replace(`/${this.props.module}`);
        this.throwErrorNotification(`Couldn't find item`);
      }
    } else {
      // update the state
      this.setState({
        currentItem: cloneDeep(this.props.defaultItem),
        clone: cloneDeep(this.props.defaultItem),
        isEditing: false,
        isLoading: false,
      });
    }
  }

  /**
   * @function
   * @name isFieldValid
   * @description wraps the checkValidity method on the props, to validate input data before we send to the external
   * validator
   * @param {string} field - the key of the field on the cloned object
   * @param {string} value? - the current value to check if its valid
   * @returns {boolean}
   */
  isFieldValid(field: string, value?: any): boolean {
    const clone = this.state.clone as Study | FeedConfig;

    const {current} = this.currentForm;
    if (current) {
      const {elements} = current;

      const currentField = Array.from(elements).find(
        (el: HTMLInputElement) => el.name === field,
      ) as HTMLInputElement;
      if (currentField && !currentField.validity.valid) {
        return false;
      }
      if (typeof clone[field] !== 'undefined' && typeof value === 'undefined') {
        value = clone[field];
      }
      if (typeof value === 'undefined') {
        return false;
      }

      if (
        currentField &&
        currentField.required &&
        currentField.type === 'text' &&
        value === ''
      ) {
        return false;
      }

      return this.props.checkValidity({
        field,
        value,
        clone,
        currentField,
      });
    }
    return false;
  }

  /**
   * @function
   * @name isFormValid
   * @description - loops over each field in the form and checks it's valid
   * @returns {boolean}
   */
  isFormValid(showNotification = true): boolean {
    const clone = this.state.clone as Study | FeedConfig;
    let isValid = true;
    for (const [field, value] of Object.entries(clone)) {
      // whilst still true, continue checking remaining fields
      const result = this.isFieldValid(field, value);
      if (isValid && !result) {
        isValid = result;
      }
    }

    if (!isValid && showNotification) {
      this.setState({
        snackbarType: SnackbarType.warning,
        snackbarOpen: true,
        snackbarText: 'Please make sure you fill in all options correctly.',
      });
    }
    return isValid;
  }

  /**
   * @function
   * @name handleChange
   * @description - Performs the change event for each field automatically and updates state
   * @param {string} field - the field by key on the clone object we want to access
   * @returns {iChangeEventFunction} - a function bound to each individual field
   */
  handleChange(field: string, onChange: Any): iChangeEventFunction {
    const clone = this.state.clone as Study | FeedConfig | StudyGroup;
    return (event: React.ChangeEvent<HTMLInputElement>): void => {
      let value: any = event.target.value;

      if (onChange) {
        const onChangeObject = onChange(event);
        if (onChangeObject.target) {
          clone[onChangeObject.target] = onChangeObject.value;
          // this is a horrible hack but problable the only way to handle extra events
          // when the onchange event triggers.
        } else if (onChangeObject.studyGroupEvent && onChangeObject) {
          // this will update some fields (such as duration) in the study form
          // based on  which study group the user seletecs
          const studyGroup = onChangeObject.studyGroup;
          Object.keys(studyGroup).forEach(key => {
            clone[key] = studyGroup[key];
          });
        } else if (onChangeObject.studyGroupTypeEvent && onChangeObject) {
          // this will clean some field in the studygroup from when the
          // study type is not empty
          const eventTypeName = event.target.name;
          if (clone[eventTypeName]) {
            const studyGroup = onChangeObject.studyGroup;
            Object.keys(studyGroup).forEach(key => {
              clone[key] = '';
            });
          }
          // Hack: when the study group type is 'data collection'
          // we set the brand stydy to 'data collection'
          if (value === StudyGroupType.DATA_COLLECTION) {
            clone['brand'] = StudyGroupType.DATA_COLLECTION;
          }
        }
      }
      // if field type is number, and value, cast to the correct type
      // if value % 1 === 0, we're an integer value, parse int, else float.
      if (event.target.type === 'number') {
        value = value % 1 === 0 ? parseInt(value, 10) : parseFloat(value);
      }
      const update = {
        clone: {
          ...clone,
          [field]:
            event.target.type === 'checkbox' ? event.target.checked : value,
        },
      };
      this.setState(update);
      this.isFieldValid(field, value);
    };
  }

  /**
   * Retrieve TP campaign give its id. It also update the state with the new details.
   * Return
   *
   * @param {string} campaignId
   * @returns {Promise<boolean>} true if request is successful | false if not
   * @memberof DataManager
   */
  async getCampaign(campaignId: string): Promise<boolean> {
    const clone = this.state.clone as StudyGroup | Study | FeedConfig;

    if (!clone || !isStudyGroup(clone) || !campaignId) {
      return false;
    }
    try {
      // TODO: ideally this should be in its own api helpers/service file
      const idToken = await getIdToken();
      const headers = new Headers({
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json',
      });

      const response = await fetch(
        `${vpApiUrl}/coreApi/campaign/${campaignId}`,
        {
          method: 'get',
          headers,
        },
      );
      const campaignObj = await response.json();
      // / TODO till here

      const update = {
        clone: {
          ...clone,
          name: campaignObj.name,
          pxyzCampaignId: campaignObj.id,
          brand: campaignObj.brand.name,
        },
      };
      this.setState(update);
      return true;
    } catch (err) {
      return false;
    }
  }

  /**
   * Retrieve TP creative given a campaignId.
   * Return
   *
   * @param {string} campaignId
   * @returns {Promise<FirestoreResponse>}
   * @memberof DataManager
   */
  async getCreatives(campaignId: string): Promise<FirestoreResponse> {
    const response: FirestoreResponse = {
      success: false,
    };

    if (!campaignId) {
      response.error = 'CampaignId not found';
      return response;
    }

    try {
      // TODO: ideally this should be in its own api helpers/service file
      const idToken = await getIdToken();
      const headers = new Headers({
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json',
      });

      const responseObj = await fetch(
        `${vpApiUrl}/coreApi/campaign/${campaignId}`,
        {
          method: 'get',
          headers,
        },
      );

      const campaignObj = await responseObj.json();
      const creativesObj = campaignObj && campaignObj.creatives;
      if (!campaignObj || !creativesObj) {
        response.error = `Data for campaign id '${campaignId}' not found`;
        return response;
      }

      const creatives = creativesObj.map(c => {
        return {
          id: c.id,
          name: c.name,
          format: c.format,
          sdk: c.sdk,
          source: c.source,
          thumbnail: c.thumbnail,
        };
      });

      response.success = true;
      response.data = creatives;
      return response;
    } catch (error) {
      response.error = error;
      return response;
    }
  }

  /**
   * @function
   * @name addToArray
   * @description - Will add the value to the array value if it doesn't already exist
   * @param {string} field - the key of the array field on the cloned object
   * @param {React.RefObject<HTMLInputElement>} reference - the reference to the input field to capture the value
   * @returns {void}
   */
  addToArray(
    field: string,
    reference: React.RefObject<HTMLInputElement> | HTMLInputElement,
  ): void {
    const clone = this.state.clone as Study | FeedConfig;
    if (!clone) {
      return;
    }
    const existing: string[] = [];
    // split by new lines and comma separated values
    const current = (reference as React.RefObject<HTMLInputElement>).current
      ? (reference as React.RefObject<HTMLInputElement>).current.value
      : '';
    const values = (
      (reference as HTMLInputElement).value
        ? (reference as HTMLInputElement).value
        : current
    ).split(/[\n|,]/);
    values.forEach((value: string) => {
      const doesExist = clone[field].includes(value);
      if (!doesExist) {
        clone[field].push(value);
      } else {
        existing.push(value);
      }
    });

    if (existing.length) {
      this.setState({
        clone: {
          ...clone,
          [field]: clone[field],
        },
        snackbarType: SnackbarType.warning,
        snackbarOpen: true,
        snackbarText: `The value(s) "${existing.join(
          ',',
        )}" were already in this list.`,
      });
    } else {
      this.setState({
        clone: {
          ...clone,
          [field]: clone[field],
        },
      });
    }
  }

  /**
   * @function
   * @name deleteArray
   * @description - Will delete all value of the array
   * @param {string} field - the key of the array field on the cloned object
   * @param {React.RefObject<HTMLInputElement>} reference - the reference to the input field to capture the value
   * @returns {void}
   */
  deleteArray(field: string): void {
    const clone = this.state.clone as Study | FeedConfig;
    clone[field] = [];
    this.setState({
      clone: {
        ...clone,
        [field]: clone[field],
      },
    });
  }

  /**
   * @function
   * @name handleDeleteChip
   * @description handles logic to remove chips from a field value
   * @param {string} field - the key of the array field on the cloned object
   * @param {string} value - the current value to check if it exists, then to remove it from the array
   * @returns {void}
   */
  handleDeleteChip(field: string, value: string): void {
    const clone = this.state.clone as Study | FeedConfig;
    if (!clone) {
      return;
    }
    if (!Array.isArray(clone[field])) {
      this.setState({
        snackbarType: SnackbarType.error,
        snackbarOpen: true,
        snackbarText: `Field name "${field}" is not an array.`,
      });
    } else {
      const doesExist = clone[field].includes(value);
      if (doesExist) {
        this.setState({
          clone: {
            ...clone,
            [field]: clone[field].filter((str: string) => str !== value),
          },
        });
      } else {
        this.setState({
          snackbarType: SnackbarType.warning,
          snackbarOpen: true,
          snackbarText: `Couldn't locate value.`,
        });
      }
    }
  }

  /**
   * @function
   * @name isEditing
   * @description - Returns true if the data contains an id property with a defined value
   * @param {FeedConfig | Study} data - the data to check if it has an id prop
   * @returns { boolean }
   */
  isEditing(data?: FeedConfig | Study): boolean {
    if (!data) {
      return false;
    }
    const {id} = data;
    return typeof id !== 'undefined';
  }

  /**
   * @function
   * @name onPanelClose
   * @description - When the slider closes, this method will be called, should be responsible for cleanup
   * or making requests such as save/delete buttons in the footer
   * @param {React.ChangeEvent<HTMLInputElement>} event - the event object from the panel
   * @param {string} actionType - sent from the buttons in the footer
   * @returns {Promise<void>}
   */
  async onPanelClose(
    event: React.ChangeEvent<HTMLInputElement>,
    actionType: string,
  ): Promise<void> {
    const clone = this.state.clone as Study | StudyGroup | FeedConfig;
    const currentItem = this.state.currentItem as Study | FeedConfig;
    if (!clone || actionType === 'disabled') {
      return;
    }
    if (actionType === 'archive' || actionType === 'unarchive') {
      this.setArchiveDialog(true);
      return;
    }
    if (actionType === 'generateTaskContent') {
      this.setGenerateTaskContentDialog(true, clone.id);
      return;
    }

    if (actionType === 'viewTag') {
      const url = pxyzViewTagUrl.replace('{CAMPAIGN_ID}', clone.campaignId);
      window.open(url, '_blank');
      return;
    }

    const isEditing = this.isEditing(clone);
    const hasChanged = !deepEqual(clone, currentItem);
    const isFormValid = this.isFormValid();
    if (!isFormValid) {
      return;
    }
    if (isEditing && hasChanged) {
      // update existing
      this.update(clone);
    } else if (hasChanged && !isEditing) {
      // if not the same as the defaults, and we're not editing,
      // create new item
      this.create(clone);
    } else if (isEditing && !hasChanged) {
      // editing existing, but data didn't change, no need to update
      // close and do nothing
      this.setState({
        currentItem: undefined,
        clone: undefined,
        snackbarOpen: true,
        snackbarType: SnackbarType.info,
        snackbarText: 'Item did not update as it did not change',
      });
    } else {
      // close and do nothing
      this.setState({
        currentItem: undefined,
        clone: undefined,
      });
    }
    this.props.history.replace(`/${this.props.module}`);
  }

  /**
   * @description - will either update an existing item or create one depending
   * if the input object contains an id already
   * @param { Study | FeedConfig } clone - the cloned item
   * @returns {Promise<void>}
   */
  async create(clone: Study | FeedConfig): Promise<void> {
    const {name} = clone;
    // these props shouldn't ever be saved on the items
    const blacklistKeys = ['isUsed', 'refreshStartedAt'];
    blacklistKeys.forEach(key => {
      delete clone[key];
    });

    try {
      const response = await firestore.add(
        dashToCamel(this.props.module),
        clone,
      );
      // update object with new id from firestore
      clone.id = response.data.id;
      // run onCreate if available.
      if (typeof this.props.onCreate === 'function') {
        await this.props.onCreate(clone);
      }

      if (!response.success) {
        throw new Error('Error saving item');
      }

      // refresh the data in the table
      // don't fetch straight away becasue we have triggers in firestore that need to run
      // this is a bit hacky but we are short of time
      setTimeout(() => {
        this.setState({
          currentItem: undefined,
          clone: undefined,
          snackbarOpen: true,
          snackbarText: `Created "${name}" successfully.`,
          snackbarType: SnackbarType.success,
        });
        this.fetchData();
      }, fetchDataDelay);
    } catch (e) {
      this.setState({
        currentItem: undefined,
        clone: undefined,
        snackbarOpen: true,
        snackbarText: `Error saving item "${name}".`,
        snackbarType: SnackbarType.error,
      });
    }
  }

  /**
   * @description - will either update an existing item or create one depending
   * if the input object contains an id already
   * @param { Study | FeedConfig } clone - the cloned item
   * @returns {Promise<void>}
   */
  async update(clone: Study | FeedConfig): Promise<void> {
    const {id = '', name} = clone;
    const currentItem = this.state.currentItem as Study | FeedConfig;
    // these props shouldn't ever be saved on the items
    const blacklistKeys = ['isUsed', 'refreshStartedAt'];
    blacklistKeys.forEach(key => {
      delete clone[key];
    });

    try {
      const response = await firestore.update(
        dashToCamel(this.props.module),
        id,
        clone,
      );

      if (typeof this.props.onUpdate === 'function') {
        await this.props.onUpdate(clone, currentItem);
      }

      if (!response.success) {
        throw new Error('Error saving item');
      }
      setTimeout(() => {
        this.setState({
          currentItem: undefined,
          clone: undefined,
          snackbarOpen: true,
          snackbarText: `'Updated "${name}" successfully.`,
          snackbarType: SnackbarType.success,
        });
        // refresh the data in the table
        this.fetchData();
      }, fetchDataDelay);
    } catch (e) {
      this.setState({
        currentItem: undefined,
        clone: undefined,
        snackbarOpen: true,
        snackbarText: `Error saving item "${name}".`,
        snackbarType: SnackbarType.error,
      });
    }
  }

  /**
   * @function
   * @name fetchData
   * @description - fetch data from firestore
   * @returns {Promise<void>}
   */
  async fetchData(): Promise<void> {
    // fetch your data
    // show the loading overlay
    this.setState({isLoading: true});
    // attempt to get the data
    try {
      const name = this.props.listName
        ? this.props.listName
        : dashToCamel(this.props.module);
      const response = await firestore.getList(name, !this.state.showArchived);
      if (!response.success) {
        throw new Error(response.error);
      }
      let data = response.data;

      let extra;
      // query for extra data if we need to (so far it is designed for retrieving app completes and imps)
      if (typeof this.props.getExtraData === 'function') {
        extra = await this.props.getExtraData(data);
      }

      // transform data before calling the setState, means the data will be maniputlate before
      // render is called
      if (typeof this.props.transfromDataBeforeRender === 'function') {
        data = this.props.transfromDataBeforeRender(
          data,
          this.state.showArchived,
          extra && extra.data,
        );
      }

      for (const checker of this.props.validateItemsById || []) {
        let moduleData = [];
        try {
          const firestoreResponse = await firestore.getList(
            dashToCamel(checker.module || this.props.module),
          );
          moduleData = firestoreResponse.data;
        } catch (e) {
          throw new Error(e);
        }
        for (const item of data) {
          // valueMapper expects Study | FeedConfig | boolean
          const foundItem =
            moduleData.find((i: {id: string}) => i.id === item[checker.key]) ||
            false;
          // can be used to format the row/cell information based on the original object
          if (!foundItem) {
            // append the error for the specific key
            item.hasErrors = item.hasErrors || {};
            item.hasErrors[checker.key] = true;
          }
          item[checker.mapTo] = checker.valueMapper(foundItem);
        }
      }
      // Update react-table as soon as the parent module data is available and
      // validated always one page, not going to bother with actual pagination
      this.setState({
        data,
        pages: 1,
        isLoading: false,
      });

      // provide the parent component the ability to manipulate the data before we attach it to the state.
      if (typeof this.props.transformData === 'function') {
        data = await this.props.transformData(data);
      }
      // Update react-table, always one page, not going to bother
      // with actual pagination
      this.setState({
        data,
        pages: 1,
        isLoading: false,
      });
    } catch (e) {
      this.setState({
        data: [],
        isLoading: false,
        snackbarOpen: true,
        snackbarText: `Error retrieving items.`,
        snackbarType: SnackbarType.error,
      });
    }
  }

  /**
   * @function
   * @name onConfirmArchive
   * @description will perform the archive on this item as the dialog has been
   * given permission to archive it
   * @returns {Promise<void>} no actual return value
   */
  async onConfirmArchive(): Promise<void> {
    const clone = this.state.clone as Study | FeedConfig;
    const shouldArchive = clone ? !clone.archived : true;
    const name = dashToCamel(this.props.module);

    const blacklistKeys = ['isUsed'];
    blacklistKeys.forEach(key => {
      delete clone[key];
    });

    const response = await firestore.archive(
      name,
      clone.id || '',
      clone,
      shouldArchive,
    );
    // close the archive dialog
    // we wait a bit to let the trigger google function do its job
    // see other setTimeouts
    setTimeout(async () => {
      if (!response.success) {
        this.setState({
          data: [],
          currentItem: undefined,
          clone: undefined,
          snackbarOpen: true,
          snackbarText: `Error archiving item "${clone.name}".`,
          snackbarType: SnackbarType.error,
        });
        throw new Error(`Firebase: Error archiving item "${clone.name}".`);
      } else {
        this.setState({
          currentItem: undefined,
          clone: undefined,
          snackbarOpen: true,
          snackbarText: `${shouldArchive ? `Archived` : `Unarchived`} item "${
            clone.name
          }" successfully".`,
          snackbarType: SnackbarType.success,
        });
        // if needed, parent component can receive a callback and do
        // something with the new cloned object before we refresh the list
        if (typeof this.props.onArchive === 'function') {
          await this.props.onArchive(clone, shouldArchive);
        }
      }

      this.setArchiveDialog(false);
      // trigger the table to fetch the main dataset again as it may have updated
      this.fetchData();
    }, fetchDataDelay);
  }

  /**
   * @description - updates the state to show the dialog
   * @param {boolean} shouldShow - if the dialog should appear
   * @returns {void} no return value
   */
  setArchiveDialog(shouldShow = false): void {
    this.setState({
      showConfirmArchive: shouldShow,
    });
  }

  /**
   * @description shouldDisplayTagButton
   * @param {string} name of the current view
   * @returns {boolen}
   */
  shouldDisplayTagButton(name: string): boolean {
    return name === 'Study Group';
  }

  /**
   * @description - shoulddisplaytaskcontent
   * @param {string} name of the current view
   * @param {string} platformid
   * @returns {boolen}
   */
  shouldDisplayTaskContent(name: string, platformId: string): boolean {
    // At the moment, only MTurk platform has at template
    const platformsWithTaskContent = [
      'twRSJgOd47mGMaGWPDqO', // MTurk
    ];

    if (name !== 'Study') {
      return false;
    }

    const platform = this.platforms.find(p => {
      return p.id === platformId;
    });

    return platform ? platformsWithTaskContent.includes(platform.id) : false;
  }

  /**
   * @description - updates the state to show the confirm dialog and copy template to
   *               clipboard
   * @param {boolean} shouldShow - if the dialog should appear
   * @returns {void} no return value
   */
  setGenerateTaskContentDialog(shouldShow = false, studyId: string): void {
    if (shouldShow) {
      const template = mturkTemplate.replace('{{STUDY-ID}}', studyId);
      navigator.clipboard.writeText(template);
    }
    this.setState({
      showConfirmGenerateTaskcontent: shouldShow,
    });
  }

  /**
   * @function
   * @name getGenerateTaskConfirmationDialog
   * @description This just returns the jsx markup for the deletion confirmation
   * dialog
   * @returns {React.ReactNode}
   */
  getGenerateTaskConfirmationDialog(): React.ReactNode {
    return (
      <Dialog
        TransitionComponent={Transition}
        open={this.state.showConfirmGenerateTaskcontent}
      >
        <DialogTitle>{'Message'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {'The task content has been copied to your clipboard'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.setGenerateTaskContentDialog.bind(this, false)}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  /**
   * @function
   * @name getArchiveConfirmationDialog
   * @description This just returns the jsx markup for the deletion confirmation
   * dialog
   * @returns {React.ReactNode}
   */
  getArchiveConfirmationDialog(): React.ReactNode {
    const clone = this.state.clone as Study | FeedConfig;
    return (
      <Dialog
        TransitionComponent={Transition}
        open={this.state.showConfirmArchive}
      >
        <DialogTitle>{'Please confirm action'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to ${
              clone && clone.archived ? 'un' : ''
            }archive this item?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.setArchiveDialog.bind(this, false)}
            color="primary"
            autoFocus
          >
            No
          </Button>
          <Button onClick={this.onConfirmArchive.bind(this)} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  /**
   * @function
   * @name onArchiveToggle
   * @description - When updated, it will refresh the list and include or not include the archived items
   * if there is any
   * @returns {void} - no return value
   */
  onArchiveToggle(): void {
    const showArchived = !this.state.showArchived;
    this.setState(
      {
        showArchived,
      },
      async (): Promise<void> => await this.fetchData(),
    );
  }

  /**
   * @function
   * @name refreshList
   * @description - Simple recalls database to get new content.
   * @returns {void} - no return value
   */
  refreshList(): void {
    // refresh the data in the table
    this.fetchData();
  }

  /**
   * @function
   * @name render
   * @description - standard react render method
   * @returns {React.ReactNode} - returns jsx markup
   */

  render(): React.ReactNode {
    const currentItem = this.state.currentItem as
      | Study
      | FeedConfig
      | StudyGroup;
    const clone = this.state.clone as Study | FeedConfig | StudyGroup;
    const {name, module, history, getPanelContent, plural} = this.props;
    const Pusher = (): React.ReactNode => {
      return <div style={{width: '100%'}} />;
    };
    return (
      <div id={this.props.id}>
        <div className="layout-row layout-align-space-between-center">
          <h1>{plural}</h1>
          <div
            className={`${styles.actionBar} layout-row layout-align-end-center`}
          >
            <Tooltip title="Create" placement="left">
              <Fab
                color="secondary"
                size="small"
                onClick={this.openInPanel.bind(this)}
              >
                <Add />
              </Fab>
            </Tooltip>
            {this.props.actionButtons && this.props.actionButtons}
          </div>
        </div>
        <div className="layout-row layout-align-space-between-center">
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{
                  name: 'show-archived',
                  id: `item-show-archived`,
                }}
                checked={this.state.showArchived === true}
                onChange={this.onArchiveToggle.bind(this)}
                value={this.state.showArchived}
                color="primary"
              />
            }
            label={`Show Archived`}
          />
        </div>
        <ReactTable
          filterable
          className="-striped -highlight"
          pages={this.state.pages}
          ref={this.table}
          style={{
            height: 'calc(100vh - 200px)', // This will force the table body to overflow and scroll, since there is not enough room
          }}
          showPagination={false}
          showPageSizeOptions={false}
          minRows={0}
          pageSize={(this.state.data || []).length}
          loading={this.state.isLoading}
          data={this.state.data}
          defaultFilterMethod={(filter, row): boolean =>
            this.props.defaultFilterMethod(filter.value, row[filter.id])
          }
          defaultSortMethod={this.props.defaultSortMethod}
          columns={this.state.columns}
        />
        <SlidingPane
          title={this.isEditing(clone) ? `Editing ${name}` : `Creating ${name}`}
          ariaHideApp={false}
          isOpen={typeof currentItem !== 'undefined'}
          onAfterOpen={(): void => {
            this.setState({
              panelOpen: true,
            });
          }}
          className={
            currentItem && currentItem.id ? `is-existing-item` : `is-new-item`
          }
          onRequestClose={(): void => {
            // triggered on "<" on left top click or on outside click
            this.setState({
              currentItem: undefined,
              clone: undefined,
              panelOpen: false,
            });
            history.replace(`/${module}`);
          }}
        >
          <div className="layout-column layout-fill layout-align-space-between-start">
            {currentItem ? (
              <form
                ref={this.currentForm}
                id={styles.form}
                className={`layout-column layour-align-start-stretch`}
                autoComplete="off"
              >
                <Breadcrumbs>
                  <Typography>{plural}</Typography>
                  <Typography>
                    {clone && clone.name ? clone.name : 'Unknown'}
                  </Typography>
                </Breadcrumbs>
                {getPanelContent(clone, this.methods)}
                {this.isFormValid(false)}
              </form>
            ) : (
              'loading'
            )}
            <BottomNavigation
              id={styles.BottomNavigation}
              className="layout-row layout-align-space-between-center"
              onChange={this.onPanelClose.bind(this)}
            >
              {clone && clone.isUsed === true ? (
                <BottomNavigationAction
                  className="action-button-general"
                  showLabel={true}
                  label="Item cannot be archived"
                  value="disabled"
                  icon={<SyncDisabled />}
                />
              ) : (
                <BottomNavigationAction
                  className="action-button-archive"
                  showLabel={true}
                  label={clone && clone.archived ? 'Unarchive' : 'Archive'}
                  value={clone && clone.archived ? 'unarchive' : 'archive'}
                  icon={clone && clone.archived ? <Unarchive /> : <Archive />}
                />
              )}
              {this.props.attachButtonToEditingWindow &&
                this.props.attachButtonToEditingWindow(clone)}
              <Pusher />
              {this.isEditing(clone) === true &&
              this.shouldDisplayTaskContent(name, clone.platformId) ? (
                <BottomNavigationAction
                  className="action-button-update"
                  showLabel={true}
                  label="Generate Task Content"
                  value="generateTaskContent"
                  icon={<Assignment />}
                />
              ) : (
                ''
              )}
              {this.isEditing(clone) === true &&
              this.shouldDisplayTagButton(name) ? (
                <BottomNavigationAction
                  className="action-button-update"
                  showLabel={true}
                  label="View Tags"
                  value="viewTag"
                  icon={<LocalOffer />}
                />
              ) : (
                ''
              )}
              <BottomNavigationAction
                className="action-button-update"
                showLabel={true}
                label="Save & Exit"
                value="save"
                icon={<Save />}
              />
            </BottomNavigation>
          </div>
        </SlidingPane>
        <Snackbar
          className="snackbar"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={(): void => {
            this.setState({
              snackbarOpen: false,
            });
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={5000}
        >
          <SnackbarContent
            className={this.state.snackbarType}
            message={
              <span id="client-snackbar">{this.state.snackbarText}</span>
            }
          />
        </Snackbar>
        {this.getArchiveConfirmationDialog.apply(this)}
        {this.getGenerateTaskConfirmationDialog.apply(this)}
      </div>
    );
  }
}
