import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import {
  CardActionArea,
  Avatar,
  CardHeader,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
} from "@material-ui/core";
import { FeedItem } from "~src/core/interfaces";

const useStyles = makeStyles({
  card: {
    width: "80%",
    flexShrink: 0,
    marginBottom: "18px",
  },
  media: {
    height: 140,
  },
  avatar: {
    backgroundColor: "#eeeeee",
  },
});

interface iProps {
  item: FeedItem;
}

export function PreviewCard(props: iProps): React.ReactNode {
  const { item } = props;
  const classes = useStyles();
  const { title = "No title" } = item;
  const { description = "No description" } = item;
  const { logo = "https://via.placeholder.com/80" } = item;
  const { image = "https://via.placeholder.com/300x200" } = item;
  const { domain = "" } = item;
  const { referer = "" } = item;
  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardHeader
          avatar={
            <Avatar
              aria-label="recipe"
              className={classes.avatar}
              style={{
                backgroundImage: `url(${logo})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            ></Avatar>
          }
          title={domain}
        />
        <CardMedia className={classes.media} image={image} title={title} />
        <CardContent>
          <Typography gutterBottom variant="h6" component="h6">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" href={referer}>
          Go to article
        </Button>
      </CardActions>
    </Card>
  );
}
